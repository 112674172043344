import React from "react";
import { ProgressBar } from "../../../ProgressBar/ProgressBar";
import classNames from "classnames";

import "./ProductReturnRate.scss";

interface IProductReturnRateProps {
  percent: number;
  name: string;
  unitAmount?: number;
  currency?: string;
  imageUrl?: string;
  whiteProgressBarBackground?: boolean;
}

export const ProductReturnRate = ({
  percent,
  name,
  unitAmount,
  imageUrl,
  currency = "USD",
  whiteProgressBarBackground,
}: IProductReturnRateProps) => {
  return (
    <div className="product-return-rate">
      {imageUrl && (
        <img
          className="product-return-rate__image"
          src={imageUrl || ""}
          alt="Product"
        />
      )}

      <div className="product-return-rate__info">
        <div className="product-return-rate__name">{name}</div>

        <div className="product-return-rate__statistics">
          <ProgressBar
            progress={percent || 0}
            className={classNames("product-return-rate__progress-bar", {
              "product-return-rate__progress-bar_white-background":
                whiteProgressBarBackground,
            })}
          />

          <div className="product-return-rate__amount">
            {unitAmount && (
              <div className="product-return-rate__unit">
                {unitAmount.toLocaleString("en-us", {
                  currency,
                  maximumFractionDigits: 0,
                })}
              </div>
            )}

            <div className="product-return-rate__unit">
              {unitAmount ? `(${percent}%)` : `${percent}%`}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
