import React, { useState } from "react";

export const NotificationContext = React.createContext({
  setErrorNotification: (errorNotification: string) => {},
  errorNotification: "",
  setSuccessNotification: (successNotification: string) => {},
  successNotification: "",
  setWarningNotification: (warningNotification: string) => {},
  warningNotification: "",
});

export const NotificationContextStore: React.FC = ({ children }) => {
  const [errorNotification, setErrorNotification] = useState("");
  const [successNotification, setSuccessNotification] = useState("");
  const [warningNotification, setWarningNotification] = useState("");
  return (
    <NotificationContext.Provider
      value={{
        errorNotification,
        setErrorNotification,
        successNotification,
        setSuccessNotification,
        warningNotification,
        setWarningNotification,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
