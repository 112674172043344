import React from "react";
import { PDFCard } from "../PDFCard/PDFCard";
import { ProductReturnRate } from "../ProductReturnRate/ProductReturnRate";
import { IReturnProductFromBackend } from "../../../../models";

import "./TopReturningProducts.scss";

interface ITopReturningProductsProps {
  topReturnedProducts: IReturnProductFromBackend[];
}

export const TopReturningProducts: React.FC<ITopReturningProductsProps> = ({
  topReturnedProducts,
}) => {
  return (
    <PDFCard color="white" hasShadow title="Top 5 returned products">
      <div className="top-returning-products">
        {topReturnedProducts.map((p) => {
          return (
            <ProductReturnRate
              key={p.product_detail_id}
              imageUrl={p.content_url}
              name={p.product_name}
              unitAmount={p.returned_qty}
              percent={50}
            />
          );
        })}
      </div>
    </PDFCard>
  );
};
