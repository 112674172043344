import { getRetailerUuid } from "../entities/RetailerUuid";
import { getGenericUserId } from "../entities/GenericUserId";

export function getHeaders(retailerUuid?: string, genericUserId?: number) {
  return {
    "retailer-uuid": retailerUuid || getRetailerUuid(),
    "generic-user-id":
      genericUserId !== undefined
        ? String(genericUserId)
        : String(getGenericUserId()),
    "content-type": "application/json",
  };
}
