import React, { useCallback } from "react";
import { Modal } from "antd";
import { Button } from "../../Button/Button";
import { IChargeFeature, IPendingChargeResponse } from "../../../models";
import { FormattedPrice } from "../../FormattedPrice/FormattedPrice";

import "./BillingPopup.scss";

interface IBillingPopupProps {
  isVisible: boolean;
  isLoading: boolean;
  popupIcon?: string;
  charge: IPendingChargeResponse;
  feature: IChargeFeature;
  handleChargeApprove: (chargeId: number) => void;
  handleChargeReject: (chargeId: number) => void;
}

export const BillingPopup: React.FC<IBillingPopupProps> = ({
  isVisible,
  isLoading,
  popupIcon,
  charge,
  feature,
  handleChargeApprove,
  handleChargeReject,
}) => {
  const onChargeApprove = useCallback(() => {
    handleChargeApprove(charge.id);
  }, [handleChargeApprove, charge.id]);

  const onChargeReject = useCallback(() => {
    handleChargeReject(charge.id);
  }, [handleChargeReject, charge.id]);

  return (
    <Modal
      className="billing-popup"
      open={isVisible}
      footer={null}
      closable={false}
    >
      {popupIcon && (
        <div className="billing-popup__image">
          <img src={popupIcon} alt="popup icon" />
        </div>
      )}

      <h3 className="billing-popup__summary">{charge.summary}</h3>

      {charge.description && (
        <p className="billing-popup__description">{charge.description}</p>
      )}

      <p className="billing-popup__price">
        <FormattedPrice price={charge.price} currency_code={charge.currency} />
        {charge.is_recurring
          ? charge.is_annualy
            ? "/year"
            : "/month"
          : "/one-time"}
      </p>

      <div className="billing-popup__actions">
        <Button
          className="billing-popup__button billing-popup__button_approve"
          onClick={onChargeApprove}
          label={feature.is_payment_mandatory ? "Pay" : "Yes, add"}
          disabled={isLoading}
        />
        {!feature.is_payment_mandatory && (
          <Button
            className="billing-popup__button billing-popup__button_reject"
            onClick={onChargeReject}
            label="No, reject"
            disabled={isLoading}
          />
        )}
      </div>
    </Modal>
  );
};
