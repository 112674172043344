import { Switch } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { ERoutes } from "../../../../../../../routes";

import "./SegmentCard.scss";

interface ISegmentCardProps {
  title: string;
  description: string;
  isActive: boolean;
  id: number;
  onActiveChange: (
    checked: boolean,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
}

export const SegmentCard: React.FC<ISegmentCardProps> = ({
  title,
  description,
  isActive,
  id,
  onActiveChange,
}) => {
  return (
    <div className="segment-card">
      <h4 className="segment-card__title">{title}</h4>

      <p className="segment-card__description">{description}</p>

      <div className="segment-card__settings">
        <div className="segment-card__setting">
          <Switch
            checked={isActive}
            id={String(id)}
            onChange={onActiveChange}
          />
          Active
        </div>
        <Link
          className="segment-card__setting segment-card__setting_link"
          to={ERoutes.SETTINGS_SEGMENTS__id.replace(":id", String(id))}
        >
          <i className="demo-icon icon-settings-mini">&#xe811;</i>
          Settings
        </Link>
      </div>
    </div>
  );
};
