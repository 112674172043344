import React from "react";

import "./PDFCard.scss";
import classNames from "classnames";

interface IPDFCardProps {
  title?: string;
  color?: "gray" | "white" | "yellow" | "red";
  hasShadow?: boolean;
  className?: string;
}

export const PDFCard: React.FC<IPDFCardProps> = ({
  title,
  color = "gray",
  hasShadow,
  className,
  children,
}) => {
  return (
    <div
      className={classNames("pdf-card", `pdf-card_${color}`, className, {
        "pdf-card_with-shadow": hasShadow,
      })}
    >
      {title && <p className="pdf-card__title">{title}</p>}

      <div
        className={classNames("pdf-card__content", {
          "pdf-card__content_margin": Boolean(title),
        })}
      >
        {children}
      </div>
    </div>
  );
};
