import { IIntegration } from "./../models";
import { getHeaders } from "./getHeaders";
import {
  IOmsTypeResponse,
  IRetailerApiPostParams,
  IRetailerGerResponse,
  IRetailerPostParams,
  ITicketRequestParams,
  ITicketRequestStatus,
  IUploadOrderIdsAndTrackingIdsParams,
  IUploadOrderIdsAndTrackingIdsResponse,
} from "../models/retailer";
import { handleApiResponse } from "../helper/handleApiResponse";
import { IPricingPlan, IReason } from "../models";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export function getRetailerByUuid(
  retailerUuid: string
): Promise<Omit<IRetailerGerResponse, "retailer_api">> {
  return fetch(`${BASE_URL}/retailer_uuid/${retailerUuid}/`, {
    method: "GET",
    headers: getHeaders(),
  }).then(handleApiResponse);
}

export function getRetailer(retailerId: number): Promise<IRetailerGerResponse> {
  return fetch(`${BASE_URL}/retailer/${retailerId}/`, {
    method: "GET",
    headers: getHeaders(),
  }).then(handleApiResponse);
}

export function addRetailer(
  data: IRetailerPostParams
): Promise<IRetailerGerResponse> {
  return fetch(`${BASE_URL}/retailer/`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(data),
  }).then(handleApiResponse);
}

export function updateRetailer(
  retailerId: number,
  isActive: boolean
): Promise<IRetailerGerResponse> {
  return fetch(`${BASE_URL}/retailer/`, {
    method: "PUT",
    headers: getHeaders(),
    body: JSON.stringify({ retailer_id: retailerId, is_active: isActive }),
  }).then(handleApiResponse);
}

export function addRetailerApi(
  data: IRetailerApiPostParams
): Promise<IRetailerGerResponse> {
  return fetch(`${BASE_URL}/retailer_api/`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(data),
  }).then(handleApiResponse);
}

export function signupRetailer(
  data: unknown,
  retailerUuid: string
): Promise<unknown> {
  return fetch(`${BASE_URL}/retailer/set_retailer_details/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "retailer-uuid": retailerUuid,
    },
    body: JSON.stringify({ retailer: data }),
  }).then(handleApiResponse);
}

export function getReturnedIOrderCount(): Promise<IPricingPlan> {
  return fetch(`${BASE_URL}/retailer/monthly_return_count/`, {
    method: "GET",
    headers: getHeaders(),
  }).then(handleApiResponse);
}

export function fetchReturnReasons(): Promise<IReason[]> {
  return fetch(`${process.env.REACT_APP_BASE_URL}/reasons/v2/`, {
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  }).then(handleApiResponse);
}

export const INTEGRATION_ERROR = "INTEGRATION_ERROR";
export const SERVER_ERROR = "SERVER_ERROR";

function handleIntegrationCall(res: any) {
  if (res.status === 400 || res.status === 503) {
    throw new Error(INTEGRATION_ERROR);
  }
  if (!res.ok) {
    throw new Error(SERVER_ERROR);
  }
  return res.json();
}

export function getIntegrations(): Promise<IIntegration[]> {
  return fetch(`${BASE_URL}/retailer/integration/`, {
    method: "GET",
    headers: getHeaders(),
  }).then(handleApiResponse);
}

export function getEnabledIntegrations(): Promise<IIntegration[]> {
  return fetch(`${BASE_URL}/retailer/integration/create_enabled/`, {
    method: "GET",
    headers: getHeaders(),
  }).then(handleApiResponse);
}

export function postIntegrationTicket(
  data: ITicketRequestParams
): Promise<ITicketRequestStatus> {
  return fetch(`${BASE_URL}/retailer/integration/`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(data),
  }).then(handleApiResponse);
}

export function getOmsType(): Promise<IOmsTypeResponse> {
  return fetch(`${BASE_URL}/retailer/oms_type/`, {
    method: "GET",
    headers: getHeaders(),
  }).then(handleIntegrationCall);
}

interface ICarrierAndRegion {
  region: string;
  carrier: string;
}

interface IInstalledApp {
  name: string;
}

interface ICompanyDetailsResponse {
  company_details: {
    company_id: number;
    company_name: string;
  };
  sign_up_details: {
    sign_up_date: string;
    company_contact_info: { name: string; email: string };
    store_url: string;
  };
  misc: {
    live_date: string;
  };
  onboarding_details: {
    onboarding_complete_date: string;
    pricing_plan: string;
    subscription_start_date: string;
  };
  shopify_details: {
    installation_channel: string;
    installed_apps: IInstalledApp[];
  };
  setting_details: {
    drf_languages: [string];
    return_policy_url: string;
    "carriers-and-regions": ICarrierAndRegion[];
    features: [string];
  };
}

export function getCompanyDetails(
  email: string
): Promise<ICompanyDetailsResponse> {
  return fetch(`${BASE_URL}/intercom/company/details/user_email/${email}/`, {
    method: "GET",
    headers: getHeaders(),
  }).then(handleApiResponse);
}

export function uploadOrderIdsAndTrackingIds(
  data: IUploadOrderIdsAndTrackingIdsParams
): Promise<IUploadOrderIdsAndTrackingIdsResponse> {
  return fetch(`${BASE_URL}/retailer/upload_orders_id_and_tracking_ids/`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(data),
  }).then(handleApiResponse);
}
