import capitalize from "lodash-es/capitalize";
import dayjs from "dayjs";
import "dayjs/plugin/utc";
import React from "react";

import { AssignTicket } from "./components/AssignTicket/AssignTicket";
import { FormattedPrice } from "./components/FormattedPrice/FormattedPrice";
import commentIcon from "./images/icons/comment.svg";
import {
  IAvailableFeature,
  IBillingCharges,
  IOrderItem,
  IRecurringFeatures,
  IUser,
} from "./models";
import { ERoutes } from "./routes";
import { TextWithAction } from "./components/TextWithAction/TextWithAction";
import { ShippingStatus } from "./components/ShippingStatus/ShippingStatus";
import { ColumnProps } from "antd/es/table";
import { toLocalDate } from "./utils";
import { FeatureDescription } from "./components/pages/Permissions/BillingPage/ChargesHistory/FeatureDescription/FeatureDescription";
import { ShippingLabel } from "./components/ShippingLabel/ShippingLabel";
import { ITmsAddress } from "./components/TmsCredentials/TmsAddress/TmsAddress";
import { TReturnType } from "./drf-fe/src/models";
import { ArrowUpOutlined } from "@ant-design/icons";
import { Navigate } from "./components/Navigate/Navigate";
import { RatingSmiley } from "./components/RatingSmiley/RatingSmiley";

export const PAGINATION_SIZES = ["10", "20", "50", "100"];
export const PAGINATION_PAGE = 1;
export const PAGINATION_PAGE_SIZE = 10;

export const FORMAT = "YYYY-MM-DD";
export const UTC_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const UTC_API_FORMAT = "YYYY-MM-DD 00:00:00";
export const WAREHOUSE_INPUT_MAX_LENGTH = 35;
export const COUNTRY_CODE_INPUT_MAX_LENGTH = 2;
export const REFUND_MONEY_ID = 1;
export const STORE_CREDIT_ID = 2;
export const SETUP_INSTRUCTIONS_COMPLETE = "SETUP_INSTRUCTIONS_COMPLETE";

export const PLAN_FEATURE_QUANTITY = 0;
export const PRICE_THRESHOLD = 1000;

export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const ROUTE_TO_REQUIRED_PERMISSION_MAP: Record<string, string> = {
  [ERoutes.HOMEPAGE]: "pages.access_homepage",
  [ERoutes.HOMEPAGE_OLD]: "pages.access_homepage_old",
  [ERoutes.RETURN_MANAGEMENT]: "pages.actions",
  [ERoutes.RETURNS_FORECAST]: "pages.access_returns_forecast",
  [ERoutes.INSIGHTS_CATEGORY]: "pages.access_insights_category",
  [ERoutes.INSIGHTS_BRAND]: "pages.access_insights_brand",
  [ERoutes.INSIGHTS_DATASETS]: "pages.access_insights_datasets",
  [ERoutes.INSIGHTS_DATASETS_SIMPLE__id]: "pages.access_insights_datasets",
  [ERoutes.INSIGHTS_DATASETS_GRAPH]: "pages.access_insights_datasets",
  [ERoutes.INSIGHTS_PRODUCT]: "pages.access_insights_product",
  [ERoutes.INSIGHTS_OVERVIEW]: "pages.access_insights_overview",
  [ERoutes.INSIGHTS_COMMENTS]: "pages.access_insights_comments",
  [ERoutes.IN_STORE_RETURN]: "pages.instore",
  [ERoutes.RESTOCKING]: "pages.access_proactive_restocking",
  [ERoutes.PERMISSIONS]: "pages.access_permissions",
  [ERoutes.FEATURES]: "pages.access_features",
  [ERoutes.USER_ACCESS]: "pages.access_user_access",
  [ERoutes.LOGS]: "pages.what_is_new",
  [ERoutes.SETTINGS_BRAND]: "pages.access_settings_brand",
  [ERoutes.SETTINGS_EMAIL_TEMPLATES]: "pages.access_settings_email_templates",
  [ERoutes.SETTINGS_SMS_TEMPLATES]: "pages.access_settings_sms_templates",
  [ERoutes.SETTINGS_RETURN_REASONS]: "pages.access_settings_return_reasons",
  [ERoutes.SETTINGS_API_KEYS]: "pages.access_settings_api_keys",
  [ERoutes.SETTINGS_STORES_AND_WAREHOUSES]:
    "pages.access_settings_stores_and_warehouses",
  [ERoutes.SETTINGS_TRANSLATIONS]: "pages.access_settings_translations",
  [ERoutes.SETTINGS_SELF_TRANSLATION]: "pages.access_settings_self_translation",
  [ERoutes.SETTINGS_SELF_TRANSLATION_V1]:
    "pages.access_settings_self_translation_v1",
  [ERoutes.SETTINGS_IMPORTS]: "pages.access_settings_imports",
  [ERoutes.SETTINGS_PRICING_PLAN]: "pages.access_settings_pricing_plan",
  [ERoutes.SETTINGS_RETURNS]: "pages.access_settings_reasons",
  [ERoutes.SETTINGS_INTEGRATIONS]: "pages.access_settings_integrations",
  [ERoutes.SETTINGS_TMS_CONFIGURATION]:
    "pages.access_settings_tms_configuration",
  [ERoutes.SETTINGS_CATEGORIES]: "pages.access_settings_categories",
  [ERoutes.SETTINGS_BLOCKED_RETURNS]: "pages.access_settings_blocked_returns",
  [ERoutes.SETTINGS_AUTOMATION]: "pages.access_settings_automation",
  [ERoutes.SETTINGS_AUTOMATION_REPORTS]:
    "pages.access_settings_automation_reports",
  [ERoutes.SETTINGS_REFUND_RULES]: "pages.access_settings_refund_rules",
  [ERoutes.SETTINGS_SEGMENTS]: "pages.access_settings_segments",
  [ERoutes.SETTINGS_SEGMENTS__id]: "pages.access_settings_segments",
  [ERoutes.SETTINGS_SHIPPING_RULES]: "pages.access_settings_shipping_rules",
  [ERoutes.SETTINGS_EXCHANGE]: "pages.access_settings_exchange",

  [ERoutes.REDIRECT_INTEGRATE_GORGIAS]: "pages.access_gorgias_integration_page",
  [ERoutes.ADMIN_INSIGHTS]: "pages.access_admin_insights",
  [ERoutes.FEEDBACK]: "pages.access_feedback",
};
export const ROUTES_PRIORITY = [
  ERoutes.REDIRECT_INTEGRATE_GORGIAS,
  ERoutes.HOMEPAGE,
  ERoutes.RETURN_MANAGEMENT,
  ERoutes.INSIGHTS_OVERVIEW,
  ERoutes.INSIGHTS_PRODUCT,
  ERoutes.INSIGHTS_BRAND,
  ERoutes.INSIGHTS_DATASETS,
  ERoutes.INSIGHTS_DATASETS_SIMPLE__id,
  ERoutes.INSIGHTS_DATASETS_GRAPH,
  ERoutes.INSIGHTS_CATEGORY,
  ERoutes.INSIGHTS_COMMENTS,
  ERoutes.RETURNS_FORECAST,
  ERoutes.RESTOCKING,
  ERoutes.SETTINGS_BRAND,
  ERoutes.SETTINGS_EMAIL_TEMPLATES,
  ERoutes.SETTINGS_SMS_TEMPLATES,
  ERoutes.SETTINGS_RETURN_REASONS,
  ERoutes.SETTINGS_API_KEYS,
  ERoutes.SETTINGS_STORES_AND_WAREHOUSES,
  ERoutes.SETTINGS_TRANSLATIONS,
  ERoutes.SETTINGS_SELF_TRANSLATION,
  ERoutes.SETTINGS_IMPORTS,
  ERoutes.SETTINGS_PRICING_PLAN,
  ERoutes.SETTINGS_RETURNS,
  ERoutes.SETTINGS_INTEGRATIONS,
  ERoutes.SETTINGS_TMS_CONFIGURATION,
  ERoutes.SETTINGS_BLOCKED_RETURNS,
  ERoutes.IN_STORE_RETURN,
  ERoutes.SETTINGS_AUTOMATION,
  ERoutes.SETTINGS_AUTOMATION_REPORTS,
  ERoutes.SETTINGS_REFUND_RULES,
  ERoutes.PERMISSIONS,
  ERoutes.FEATURES,
  ERoutes.USER_ACCESS,
  ERoutes.LOGS,
  ERoutes.ADMIN_INSIGHTS,
  ERoutes.FEEDBACK,
  ERoutes.SETTINGS_EXCHANGE,
];

const marketColumn = {
  title: "Market",
  dataIndex: "market",
  render: (text: string, record: IOrderItem) =>
    record.market || record.country_code,
};

const orderDateColumn = {
  title: "Order date",
  dataIndex: "order_date",
  sorter: (a: IOrderItem, b: IOrderItem) =>
    dayjs(a.order_date).diff(dayjs(b.order_date)),
  render: (text: string, record: IOrderItem) =>
    toLocalDate(record.order_date).format("YYYY.MM.DD"),
};

const returnPriceColumn = {
  title: "Return price",
  dataIndex: "return_item_price",
  className: "text-align__right",
  render: (text: string, record: IOrderItem) => (
    <div>
      <FormattedPrice
        price={record.return_item_price}
        currency_code={record.currency_code}
      />

      <span>
        {record.payment_details?.id === 1 && (
          <i className="demo-icon icon-ico_credit_card">&#xe818;</i>
        )}
      </span>
    </div>
  ),
};

const customerColumn = {
  title: "Customer",
  dataIndex: "name",
  sorter: (a: IOrderItem, b: IOrderItem) =>
    a.name > b.name ? 1 : a.name < b.name ? -1 : 0,
  render: (text: string, record: IOrderItem) => (
    <span className="hover-switch">
      <span className="hover-switch__regular">
        <RatingSmiley rating={record.rating} /> {record.name}
      </span>
      <span className="hover-switch__hover">{record.email}</span>
    </span>
  ),
};

const returnedCountColumn = {
  title: "# Returned",
  dataIndex: "return_item_count",
};

function getIdColumn({ onClick }: { onClick: (order: IOrderItem) => void }) {
  return {
    title: "ID#",
    dataIndex: "ext_order_id",
    sorter: (a: IOrderItem, b: IOrderItem) =>
      a.ext_order_id > b.ext_order_id
        ? 1
        : a.ext_order_id < b.ext_order_id
        ? -1
        : 0,
    render: (text: string, record: IOrderItem) => (
      <TextWithAction onClick={() => onClick(record)}>
        {text}
        <Navigate
          to={ERoutes.ORDER_PAGE.replace(":extOrderId", record.ext_order_id)}
        >
          <ArrowUpOutlined style={{ transform: "rotate(45deg)" }} />
        </Navigate>
      </TextWithAction>
    ),
  };
}

const returnTypeColumn = {
  title: "Return type",
  dataIndex: "return_type_summary",
  sorter: (a: IOrderItem, b: IOrderItem) =>
    b.return_type_summary.localeCompare(a.return_type_summary),
  render: (text: string, record: IOrderItem) => {
    return (
      <span>
        {capitalize(record.return_type_summary)}
        {record.has_user_comment && (
          <img style={{ marginLeft: 8 }} src={commentIcon} alt="has comment" />
        )}
        {record.has_in_store_return && (
          <span style={{ marginLeft: 8 }} className="text-secondary text-small">
            in-store
          </span>
        )}
      </span>
    );
  },
};

const SHIPPING_LABEL_DATA_INDEX = "is_shipping_label_available";
const getShippingLabelColumn = ({ refetch }: IOrdersTableParams) => {
  return {
    title: "Shipping label",
    dataIndex: SHIPPING_LABEL_DATA_INDEX,
    className: "text-align__right",
    render: (text: string, record: IOrderItem) => {
      return (
        <ShippingLabel
          order={record}
          handleShippingLabelRetrySuccess={refetch}
        />
      );
    },
  };
};

const returnTimeColumn = {
  title: "Request dates",
  dataIndex: "request_date",
  className: "text-align__right",
  sorter: (a: IOrderItem, b: IOrderItem) =>
    dayjs(a.request_date).diff(dayjs(b.request_date)),
  render: (text: string, record: IOrderItem) => {
    return dayjs(record.request_date).isSame(dayjs(), "day")
      ? toLocalDate(record.request_date).format("HH:mm A")
      : toLocalDate(record.request_date).format("YYYY.MM.DD HH:mm A");
  },
};

export interface IOrdersTableParams {
  refetch: () => Promise<unknown>;
}

const getShippingStatusColumn = ({ refetch }: IOrdersTableParams) => {
  return {
    title: "Shipping status",
    dataIndex: "tracking_status",
    render: (text: string, record: IOrderItem) => {
      return <ShippingStatus record={record} refetch={refetch} />;
    },
  };
};

const getAssignmentColumn = ({
  users,
  refetch,
}: {
  users: IUser[];
  refetch: () => Promise<unknown>;
}) => {
  return {
    title: "Assign to",
    dataIndex: "assigned_to",
    sorter: (a: IOrderItem, b: IOrderItem) =>
      a.user_name > b.user_name ? 1 : a.user_name < b.user_name ? -1 : 0,
    render: (text: string, record: IOrderItem) => (
      <AssignTicket order={record} refetch={refetch} users={users} />
    ),
  };
};

export function getOpenedColumns({
  users,
  onOrderIdClick,
  shouldHaveResendShippingLabel,
}: {
  users: IUser[];
  onOrderIdClick: (order: IOrderItem) => void;
  shouldHaveResendShippingLabel: boolean;
}) {
  return ({ refetch }: IOrdersTableParams): Array<ColumnProps<IOrderItem>> =>
    [
      getIdColumn({
        onClick: onOrderIdClick,
      }),
      getAssignmentColumn({ refetch, users }),
      orderDateColumn,
      customerColumn,
      returnTypeColumn,
      marketColumn,
      returnedCountColumn,
      returnPriceColumn,
      getShippingLabelColumn({ refetch }),
      returnTimeColumn,
      getShippingStatusColumn({ refetch }),
    ].filter(
      (x) =>
        shouldHaveResendShippingLabel ||
        x.dataIndex !== SHIPPING_LABEL_DATA_INDEX
    );
}

export function getProcessedColumns({
  onOrderIdClick,
}: {
  onOrderIdClick: (order: IOrderItem) => void;
}) {
  return ({ refetch }: IOrdersTableParams): Array<ColumnProps<IOrderItem>> => [
    getIdColumn({
      onClick: onOrderIdClick,
    }),
    orderDateColumn,
    customerColumn,
    returnedCountColumn,
    returnPriceColumn,
    returnTypeColumn,
    marketColumn,
    {
      title: "Return value",
      dataIndex: "return_price",
      className: "text-align__right",
      render: (text: string, record: IOrderItem) => {
        return (
          <span
            className={
              Number(record.return_price) > PRICE_THRESHOLD
                ? "color-green"
                : "color-yellow"
            }
          >
            <FormattedPrice
              price={Number(record.return_price)}
              currency_code={record.currency_code}
            />
          </span>
        );
      },
    },
    returnTimeColumn,
  ];
}

export const GRID_GAP = 20;

export const IMAGE_FALLBACK =
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWLzdieuGqlSprzPSRxj2lhXur2GGusyPrpgwE2LrlvpfiZgaclg&s";

export const API_ERROR = "Oooops! API error. Try again or call a tech support";

export const COLORS = [
  "#79A5FC",
  "#FFE24B",
  "#7FD868",
  "#61E4EC",
  "#D597FB",
  "#FE7575",
  "#1F4E8C",
  "#367350",
  "#51A66F",
  "#F2B544",
  "#A62121",
  "#E64447",
  "#12435D",
  "#1FA3AE",
  "#EEBE5B",
  "#022E40",
  "#17A697",
  "#07F2C7",
  "#594F32",
  "#A69B7C",
  "#D9777F",
  "#8C545E",
  "#707A8C",
  "#732257",
];

export const INTERNAL_COMMENTS = [
  {
    comment: "hello",
    creation_date: "2020-05-22T20:45:04",
    generic_user_id: 0,
    name: "Igor Shybyryn",
    order_id: 0,
    order_internal_comment_id: 0,
    picture: "",
  },
];

export enum ELocalStorageKeys {
  COGNITO_ID_TOKEN = "cognito_id_token",
  INITIAL_URL = "initial_url",
  SHOPIFY_STORE_NAME = "store_name",
}

export enum ESearchEntity {
  ALL = "ALL",
  ORDER = "ORDER",
  PRODUCT = "PRODUCT",
  CUSTOMER = "CUSTOMER",
}

export const CURRENCY_CODE: Record<string, string> = {
  USD: "$", // US Dollar
  EUR: "€", // Euro
  CRC: "₡", // Costa Rican Colón
  GBP: "£", // British Pound Sterling
  ILS: "₪", // Israeli New Sheqel
  INR: "₹", // Indian Rupee
  JPY: "¥", // Japanese Yen
  KRW: "₩", // South Korean Won
  NGN: "₦", // Nigerian Naira
  PHP: "₱", // Philippine Peso
  PLN: "zł", // Polish Zloty
  PYG: "₲", // Paraguayan Guarani
  THB: "฿", // Thai Baht
  UAH: "₴", // Ukrainian Hryvnia
  VND: "₫", // Vietnamese Dong
};

export enum ERefundMethodCode {
  REFUND_METHOD = "refund money",
  STORE_CREDIT = "store-credit",
}

export const ORDER_NOT_FOUND = "order_not_found";

export const DOCUMENTATION_PAGES: Record<string, string> = {
  [ERoutes.SETTINGS_SELF_TRANSLATION]:
    "https://intercom.help/yaylohelpdesk/en/articles/8198785-customizing-return-form-text",
  [ERoutes.HOMEPAGE]:
    "https://intercom.help/yaylohelpdesk/en/collections/3250466-setting-up-yayloh",
  [ERoutes.RETURN_MANAGEMENT]:
    "https://intercom.help/yaylohelpdesk/en/collections/3250465-processing-returns-on-yayloh",
  [ERoutes.INSIGHTS_OVERVIEW]:
    "https://intercom.help/yaylohelpdesk/en/collections/3284288-analytics",
  [ERoutes.SETTINGS_BRAND]:
    "https://intercom.help/yaylohelpdesk/en/collections/3284292-settings",
  [ERoutes.IN_STORE_RETURN]:
    "https://intercom.help/yaylohelpdesk/en/collections/3284291-in-store-returns",
  [ERoutes.USER_ACCESS]:
    "https://intercom.help/yaylohelpdesk/en/articles/5796669-users-add-new-users",
  [ORDER_NOT_FOUND]:
    "https://intercom.help/yaylohelpdesk/en/articles/5796643-why-can-t-i-find-my-returns-order-in-yayloh-or-why-am-i-getting-the-message-order-not-found",
};

export const ALL_REVIEWS_ID = -1;
export const ALL_RATING_GRADES = [1, 2, 3, 4, 5];
export const RATING_PARAM_NAME = "rating";
export const FEEDBACK_RATING_COUNT_DEFAULT = 0;

export enum EFeedbackFilterKeys {
  ALL_REVIEWS = "all_review_count",
  FIVE_STAR_REVIEWS = "five_star_review_count",
  FOUR_STAR_REVIEWS = "four_star_review_count",
  THREE_STAR_REVIEWS = "three_star_review_count",
  TWO_STAR_REVIEWS = "two_star_review_count",
  ONE_STAR_REVIEWS = "one_star_review_count",
}

export enum EChargeMode {
  RECURRENT = "recurrent",
  ONE_TIME = "one_time",
}

export enum EFeatureType {
  FEATURE = "feature",
  PLAN = "plan",
  AUTO_REFUND = "auto_refund",
}

export enum ECurrencyInputs {
  PRICE = "price",
  CURRENCY = "currency",
}

export const DEFAULT_TMS_ADDRESS: ITmsAddress = {
  name: "",
  address: "",
  country_code: "",
  zipcode: "",
  city: "",
  email: "",
  phone_number: "",
  province_code: "",
};

export const chargeHistoryColumns = [
  {
    title: "Date",
    key: "approval_date",
    render: undefined,
  },
  {
    title: "Feature",
    key: "summary",
    render: (text: string, record: IBillingCharges) => {
      return (
        <FeatureDescription summary={text} description={record.description} />
      );
    },
  },
  {
    title: "Value",
    key: "price",
    render: (price: number, record: IBillingCharges) => {
      return <FormattedPrice price={price} currency_code={record.currency} />;
    },
  },
  {
    title: "Approved by",
    key: "approved_by",
    render: undefined,
  },
];

export enum EFeatureTypeName {
  PLAN = "PLAN",
  TRACKING_PAGE = "TRACKING_PAGE",
  MONTHLY_RETURN = "MONTHLY_RETURN",
}

export const DEFAULT_BILLING_FEATURE: IAvailableFeature = {
  currency: "SEK",
  has_quantity: false,
  id: 1234,
  name: "Feature",
  price: 0,
  feature_type_name: EFeatureTypeName.PLAN,
};

export const DEFAULT_RECURRING_FEATURE: IRecurringFeatures = {
  addons: [],
  plan: null,
  sum_price: 0,
};

export const URL_PARAMS_CHARGE_ID = "charge_id";

export enum EFeatures {
  PLAN = "plan",
}

export enum ESortOrder {
  DESCEND = "descend",
  ASCEND = "ascend",
}

export enum EIntegrations {
  ZENDESK = "Zendesk",
  GORGIAS = "Gorgias",
  KLAVIYO = "Klaviyo",
}

export enum EReturnType {
  RETURN = "return",
  EXCHANGE = "exchange",
  CLAIM = "claim",
}

export const DEFAULT_AVAILABLE_RETURN_TYPES: TReturnType[] = [
  EReturnType.RETURN,
  EReturnType.EXCHANGE,
  EReturnType.CLAIM,
];

export enum EParamType {
  DATETIME = "datetime",
  STRING = "string",
  INTEGER = "integer",
  BIG_INTEGER = "big_integer",
}

export const SW_CURRRENCY_CODE = "SEK";

export enum EVIPSettingsParam {
  NUMBER_OF_ORDERS = "number_of_orders",
  REVENUE = "revenue",
  ACTIVE_SINCE = "active_since",
  LAST_ORDER_SIZE = "last_order_size",
  TOTAL_RETURNS = "total_returns",
}

export enum EPaymentMethodType {
  CREDIT_CARD = 1,
  STORE_CREDIT = 2,
  GIFT = 3,
  DISCOUNT = 4,
}

export enum EImpactAreaType {
  OPERATION = 1,
  RETURNS = 2,
  RETENTION = 3,
  FINANCIAL = 4,
}

export enum EMonthFrequency {
  MONTHLY = "monthly",
  ANNUALY = "annually",
}

export const WAREHOUSE_URL_NOT_FOUND =
  "Warehouse url does not exist! Please contact Yayloh customer support.";
export const SEARCH_PARAMS = {
  SEARCH_KEYWORD: "search_keyword",
} as const;
