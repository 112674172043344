import classNames from "classnames";
import React from "react";
import { Loader } from "../Loader/Loader";

import "./LoadingOverlay.scss";

interface ILoadingOverlayProps {
  isLoading: boolean;
}

export const LoadingOverlay: React.FC<ILoadingOverlayProps> = ({
  isLoading,
  children,
}) => {
  return (
    <>
      {isLoading && (
        <div
          className={classNames("loading-overlay", {
            "loading-overlay_fixed": children,
          })}
        >
          <Loader isLoading />
        </div>
      )}

      {children}
    </>
  );
};
