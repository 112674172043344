import React, { useState } from "react";
import { IOrderItem, IResponseStatus } from "../../models";
import { ShippingLabelActions } from "./components/ShippingLabelActions/ShippingLabelActions";
import { ShippingLabelError } from "./components/ShippingLabelError/ShippingLabelError";
import "./ShippingLabel.scss";
import { DownOutlined } from "@ant-design/icons";
import { MenuProps } from "antd";
import { Dropdown, Space, Typography } from "antd";
import { ResendShippingLabel } from "../ResendShippingLabel/ResendShippingLabel";
interface IShippingLabelProps {
  order: Pick<
    IOrderItem,
    | "label_error_message"
    | "order_id"
    | "order_shipping_label_id"
    | "shipping_label_s3_url"
    | "invoice_url"
  >;
  handleShippingLabelRetrySuccess:
    | ((response: IResponseStatus) => void)
    | ((response: IResponseStatus) => Promise<unknown>);
}

export const ShippingLabel: React.FC<IShippingLabelProps> = ({
  order,
  handleShippingLabelRetrySuccess,
}) => {
  const [selectedItem, setSelectedItem] = useState<string>("");
  const [selectedLabel, setSelectedLabel] = useState<string>("");

  const handleMenuClick = (item: any) => {
    setSelectedItem(item.key);
    setSelectedLabel(item.label);
  };
  const items: MenuProps["items"] = [
    {
      key: "1",
      label:
        order.order_shipping_label_id && order.shipping_label_s3_url ? (
          <ShippingLabelActions
            shippingLabelId={order.order_shipping_label_id}
            orderId={order.order_id}
            label="Label"
            getLinkProp={order.shipping_label_s3_url}
            icon="/favicon/Vector3.svg"
          />
        ) : null,
    },
    {
      key: "2",
      label:
        order.order_shipping_label_id && order.invoice_url ? (
          <ShippingLabelActions
            shippingLabelId={order.order_shipping_label_id}
            orderId={order.order_id}
            label="Commercial Invoice"
            getLinkProp={order.invoice_url}
            icon="/favicon/Vector1.svg"
          />
        ) : null,
    },
  ];

  const defaultLabel = (
    <div className="default-label">
      <img src="/favicon/Vector2.svg" alt="Icon" />
      Labels, Invoices
    </div>
  );
  const availableItems = items.filter(
    (item) => item?.label !== undefined && item?.label !== null
  );
  return (
    <div className="shipping-label">
      {order.label_error_message && (
        <ShippingLabelError
          errorMessage={order.label_error_message}
          handleShippingLabelRetrySuccess={handleShippingLabelRetrySuccess}
          orderId={order.order_id}
        />
      )}
      {order.order_shipping_label_id !== null &&
        order.order_shipping_label_id !== undefined && (
          <>
            {availableItems.length === 1 ? (
              availableItems[0]?.label
            ) : (
              <Dropdown
                overlayStyle={{ width: 200 }}
                menu={{
                  items,
                  selectable: true,
                  defaultSelectedKeys: [selectedItem],
                  onClick: handleMenuClick,
                }}
              >
                <Typography.Link
                  style={{ width: "max-content", color: "#3368FF" }}
                >
                  <Space>
                    {defaultLabel}
                    <DownOutlined />
                    {selectedLabel === "Labels, Invoices" && (
                      <img src="/favicon/Vector2.svg" alt="Icon" />
                    )}
                  </Space>
                </Typography.Link>
              </Dropdown>
            )}
            <ResendShippingLabel
              orderId={order.order_id}
              orderShippingLabelId={order.order_shipping_label_id}
            />
          </>
        )}
    </div>
  );
};
