import React from "react";
import classNames from "classnames";
import { ArrowDownOutlined } from "@ant-design/icons";

import "./RevenueSummary.scss";

interface IRevenueSummaryProps {
  revenue: number;
  pastRevenue?: number;
  hidePastRevenue?: boolean;
  currency?: string;
  isHighlighted?: boolean;
  className?: string;
  pastPeriodText?: string;
  isCompact?: boolean;
  isReverse?: boolean;
}

export const RevenueSummary: React.FC<IRevenueSummaryProps> = ({
  revenue,
  pastRevenue = 0,
  currency,
  isHighlighted,
  className,
  hidePastRevenue,
  pastPeriodText = "Last period:",
  isCompact,
  isReverse,
}) => {
  return (
    <div
      className={classNames("revenue-summary", className, {
        "revenue-summary_compact": isCompact,
      })}
    >
      <div className="revenue-summary__current-revenue">
        <p
          className={classNames("revenue-summary__amount", {
            "revenue-summary__amount_highlight": isHighlighted,
          })}
        >
          {revenue.toLocaleString()}
        </p>

        <div
          className={classNames("revenue-summary__indicator", {
            "revenue-summary__indicator_good": isReverse
              ? revenue < pastRevenue
              : revenue > pastRevenue,
            "revenue-summary__indicator_bad": isReverse
              ? revenue > pastRevenue
              : revenue < pastRevenue,
            "revenue-summary__indicator_neutral": revenue === pastRevenue,
            "revenue-summary__indicator_reverse": isReverse,
          })}
        >
          <ArrowDownOutlined />
        </div>
      </div>

      {!hidePastRevenue && (
        <div className="revenue-summary__past-revenue">
          <p className="revenue-summary__past-revenue-text">{pastPeriodText}</p>

          <p className="revenue-summary__past-revenue-amount">
            {pastRevenue.toLocaleString("en-US", {
              style: currency ? "currency" : undefined,
              currency,
              minimumFractionDigits: 0,
            })}
          </p>
        </div>
      )}
    </div>
  );
};
