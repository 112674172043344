import {
  IQueuedRequests,
  IBulkApproveOrders,
  IResponseStatus,
} from "./../models";
import { BASE_URL } from "../constants";
import {
  ICustomerInfo,
  IExportReturnsData,
  IImportInfo,
  IInternalComment,
  IOrder,
  IOrderImportCount,
  IPrevOrdersData,
  IProcessOrderRequest,
  IQueryParams,
  IRetailerUuidResponse,
  ITokenSignInResponse,
  IOrderFilterItem,
  IFilterActionManageParams,
  IFilterActionManageResponse,
  IFilterResult,
  IFilterQueryParams,
  IOrderDetailsLookupResponse,
} from "../models";
import { transformSortOrder } from "../utils";
import { getHeaders } from "./getHeaders";
import { fromPairs } from "lodash";

function createFetchOrders(
  type: string
): (params: Partial<IQueryParams>) => Promise<IOrder> {
  return function ({
    page,
    pagesize,
    start,
    end,
    keywords,
    direction,
    sortby,
  }) {
    if (keywords) {
      const url = new URL(
        `${BASE_URL}/search_orders/processed/${type}/start/${start}/end/${end}/page/${page}/page_size/${pagesize}/keywords/${keywords}/sort-by/${
          sortby || "creation_date"
        }/sort-direction/${transformSortOrder(direction)}/`
      );
      return fetch(url.toString(), {
        method: "GET",
        headers: getHeaders(),
      }).then((res) => res.json());
    }
    return fetch(
      `${BASE_URL}/orders/processed/${type}/start/${start}/end/${end}/page/${page}/page_size/${pagesize}/sort-by/${
        sortby || "creation_date"
      }/sort-direction/${transformSortOrder(direction)}/`,
      {
        method: "GET",
        headers: getHeaders(),
      }
    ).then((res) => res.json());
  };
}

export const fetchProcessedOrders = createFetchOrders("2");
export const fetchInProgressOrders = createFetchOrders("1");
export const fetchOpenedOrders = createFetchOrders("0");

export function fetchOrders(params: Partial<IQueryParams>): Promise<IOrder> {
  const body = fromPairs(
    Object.entries(params)
      .filter(
        ([key, value]) =>
          value !== undefined &&
          value !== null &&
          (key !== "direction" || value)
      )
      .map(([key, value]) => [
        key,
        key === "direction" ? transformSortOrder(String(value)) : value,
      ])
  );

  return fetch(`${BASE_URL}/filteraction/manage_v1`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(body),
  }).then((res) => res.json());
}

export function filterAction(
  params: Partial<IFilterQueryParams>
): Promise<IFilterResult> {
  const urlSearchParams = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    if (value !== undefined && value !== null) {
      urlSearchParams.append(
        key,
        key === "direction" ? transformSortOrder(String(value)) : String(value)
      );
    }
  });

  return fetch(
    `${BASE_URL}/filteraction/filter?${urlSearchParams.toString()}`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  ).then((res) => res.json());
}

function createFetchPreviousOrders(
  type: string
): (params: { lastDate: string }) => Promise<IPrevOrdersData> {
  return function ({ lastDate }) {
    return fetch(`${BASE_URL}/previous_orders/${lastDate}/processed/${type}/`, {
      method: "GET",
      headers: getHeaders(),
    }).then((res) => res.json());
  };
}

export const fetchPreviousProcessedOrders = createFetchPreviousOrders("2");
export const fetchPreviousInProgressOrders = createFetchPreviousOrders("1");
export const fetchPreviousOpenedOrders = createFetchPreviousOrders("0");

export function fetchOrder(
  orderId: number
): Promise<IOrderDetailsLookupResponse> {
  return fetch(`${BASE_URL}/order/${orderId}/v1/`, {
    method: "GET",
    headers: getHeaders(),
  }).then((res) => res.json());
}

export function fetchOrderCustomer(orderId: number): Promise<ICustomerInfo> {
  return fetch(`${BASE_URL}/order_customer/${orderId}/v1/`, {
    method: "GET",
    headers: getHeaders(),
  }).then((res) => res.json());
}

export function inProgressOrder(orderId: number) {
  return fetch(`${BASE_URL}/make_in_progress/${orderId}/`, {
    method: "POST",
    headers: getHeaders(),
  }).then((res) => res.json());
}

export function processOrder(processOrderRequest: IProcessOrderRequest) {
  return fetch(`${BASE_URL}/approve/`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(processOrderRequest),
  }).then((res) => res.json());
}

export function fetchRetailerUuid(
  email: string
): Promise<IRetailerUuidResponse[]> {
  const domain = email.split("@")[1];
  return fetch(`${BASE_URL}/retailer_domain/${domain}/`, {
    method: "GET",
    headers: getHeaders(),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res === null || res.length === 0) {
        return fetch(`${BASE_URL}/retailer_domain/by_email/${email}/`, {
          method: "GET",
          headers: getHeaders(),
        }).then((res) => res.json());
      }
      return res;
    });
}

export function sendGoogleTokenId(
  tokenId: string
): Promise<ITokenSignInResponse> {
  return fetch(`${BASE_URL}/google/tokensignin/`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      idtoken: tokenId,
    }),
  }).then((res) => res.json());
}

export function sendAwsCognitoTokenId(
  tokenId: string
): Promise<ITokenSignInResponse> {
  return fetch(`${BASE_URL}/cognito/tokensignin/`, {
    method: "POST",
    headers: getHeaders(),
    body: tokenId,
  }).then((res) => res.json());
}

export function getInternalComments(
  orderId: number
): Promise<IInternalComment[]> {
  return fetch(`${BASE_URL}/order/internal_comment/${orderId}/`, {
    method: "GET",
    headers: getHeaders(),
  }).then((res) => res.json());
}

export function addInternalComment(orderId: number, comment: string) {
  return fetch(`${BASE_URL}/order/internal_comment/${orderId}/`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      comment,
    }),
  })
    .then((res) => res.json())
    .catch(() => null);
}

export function markAsProcessed(orderId: number, comment: string) {
  return fetch(`${BASE_URL}/order/mark_as_processed_with_comment/${orderId}/`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      comment,
    }),
  }).then((res) => res.json());
}

export function resendShippingLabel(
  orderId: number,
  orderShippingLabelId: number
) {
  return fetch(
    `${BASE_URL}/communicate/resend-shipping-label/${orderId}/label/${orderShippingLabelId}/`,
    {
      method: "POST",
      headers: getHeaders(),
    }
  ).then((res) => res.json());
}

export function getLastOrderImports(
  amountOfImports: number
): Promise<IImportInfo[]> {
  return fetch(`${BASE_URL}/last_n_order_import/${amountOfImports}/`, {
    method: "GET",
    headers: getHeaders(),
  }).then((res) => res.json());
}

export function getOrderImportCount(
  start: string,
  end: string
): Promise<IOrderImportCount> {
  return fetch(`${BASE_URL}/order_import_count/start/${start}/end/${end}/`, {
    method: "GET",
    headers: getHeaders(),
  }).then((res) => res.json());
}

export function getReturnsDataForExport(
  start: string,
  end: string
): Promise<IExportReturnsData[]> {
  return fetch(`${BASE_URL}/export/start/${start}/end/${end}/`, {
    method: "GET",
    headers: getHeaders(),
  }).then((res) => res.json());
}

export function assign(
  assignItToMeFlag: string,
  orderId: number,
  genericUserId?: number
): Promise<IExportReturnsData[]> {
  return fetch(`${BASE_URL}/assign/${assignItToMeFlag}/order/${orderId}/`, {
    method: "PUT",
    headers: getHeaders(undefined, genericUserId),
  }).then((res) => res.json());
}

export function bulkApprove(orderIds: number[]) {
  return fetch(`${BASE_URL}/bulk/approve/`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      // "refund_method_id"
      order_list: orderIds,
    }),
  }).then((res) => res.json());
}

export function bulkMakeInProgress(orderIds: number[]) {
  return fetch(`${BASE_URL}/bulk/make_in_progress/`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      order_list: orderIds,
    }),
  }).then((res) => res.json());
}

export function bulkAssign(orderIds: number[]) {
  return fetch(`${BASE_URL}/bulk/assign/`, {
    method: "PUT",
    headers: getHeaders(),
    body: JSON.stringify({
      order_list: orderIds,
    }),
  }).then((res) => res.json());
}

export function bulkApproveQueue(): Promise<IQueuedRequests> {
  return fetch(`${BASE_URL}/bulk/approve/v1/`, {
    method: "GET",
    headers: getHeaders(),
  }).then((res) => res.json());
}

export function bulkApproveRequeue(orderId: number) {
  return fetch(`${BASE_URL}/bulk/approve/v1/?order_id=${orderId}`, {
    method: "PUT",
    headers: getHeaders(),
  }).then((res) => res.json());
}

export function bulkApproveMoveToProcessed(orders: IBulkApproveOrders) {
  return fetch(`${BASE_URL}/bulk/approve/v1/`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(orders),
  }).then((res) => res.json());
}

export function bulkApproveDeleteQueued(orderId: number) {
  return fetch(`${BASE_URL}/bulk/approve/v1/?order_id=${orderId}`, {
    method: "DELETE",
    headers: getHeaders(),
  }).then((res) => res.json());
}

export function getFilters(): Promise<IOrderFilterItem[]> {
  return fetch(`${BASE_URL}/filteraction/filters/`, {
    method: "GET",
    headers: getHeaders(),
  }).then((res) => res.json());
}

export function filterActionManage(
  params: Partial<IFilterActionManageParams>
): Promise<IFilterActionManageResponse> {
  const urlSearchParams = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    if (value !== undefined && value !== null) {
      urlSearchParams.append(key, String(value));
    }
  });
  return fetch(`${BASE_URL}/filteraction/manage_v1`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(params),
  }).then((res) => res.json());
}

export function retryShippingLabel(orderId: number): Promise<IResponseStatus> {
  return fetch(`${BASE_URL}/communicate/resend-failed-label/${orderId}/`, {
    method: "POST",
    headers: getHeaders(),
  }).then((res) => res.json());
}
