import classNames from "classnames";
import React from "react";
import spinner from "../../images/spinner.svg";
import "./LoadingSpinner.scss";

interface ILoadingSpinnerProps {
  className?: string;
}

export const LoadingSpinner: React.FC<ILoadingSpinnerProps> = ({
  className,
}) => {
  return (
    <div className={classNames("loading-spinner", className)}>
      <img src={spinner} alt="" />
    </div>
  );
};
