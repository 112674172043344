import React, { useEffect, useMemo, useState } from "react";
import { PDFHeader } from "./components/PDFHeader/PDFHeader";
import { PDFHeroSection } from "./components/PDFHeroSection/PDFHeroSection";
import { PDFSection } from "./components/PDFSection/PDFSection";
import { PDFCard } from "./components/PDFCard/PDFCard";
import { Summary } from "../Summary/Summary";
import { RefundChart } from "../RefundChart/RefundChart";
import { RetentionRateChart } from "../RetentionRateChart/RetentionRateChart";
import { RevenueSummary } from "../RevenueSummary/RevenueSummary";
import { TopReturningProducts } from "./components/TopReturningProducts/TopReturningProducts";
import { TopReturnedCategories } from "./components/TopReturnedCategories/TopReturnedCategories";
import {
  getLabelsSaved,
  getRetailerContent,
  getRevenueRetained,
  getTop3ReturnedCountries,
  getTop5ReturnedCategories,
  getTotalRefunds,
  getTotalSales,
  getWeeklyRefunds,
  getWeeklyRevenueRetained,
} from "../../api/home";
import {
  ILabelsSaved,
  IRatingResponse,
  IReturnProductFromBackend,
  IRevenuRetained,
  ITopReturnedCategories,
  ITopReturnedCountries,
  ITotalRefunds,
  ITotalSales,
  IWeeklyRefund,
  IWeeklyRevenueRetained,
} from "../../models";
import { LabelsSaved } from "./components/LabelsSaved/LabelsSaved";
import dayjs from "dayjs";
import { UTC_API_FORMAT } from "../../constants";
import { fetchTopReturnedProducts } from "../../api/statistic";
import { CustomerSatisfaction } from "./components/CustomerSatisfaction/CustomerSatisfaction";
import { IRetailerContent } from "../../drf-fe/src/models";
import { useParams } from "react-router";
import { getRating } from "../../api/feedback";
import { TopReturningCountry } from "../pages/HomepageV2/components/TopReturningCountry/TopReturningCountry";

import "./PDFTemplate.scss";

interface IPDFConfiguration {
  totalRefunds: ITotalRefunds;
  totalSales: ITotalSales;
  revenueRetained: IRevenuRetained;
  topReturnedCategories: ITopReturnedCategories;
  topReturnedCountries: ITopReturnedCountries;
  labelsSaved: ILabelsSaved;
  topReturnedProducts: IReturnProductFromBackend[];
  retailerContent: IRetailerContent;
  weeklyRefunds: IWeeklyRefund[];
  weeklyRevenueRetained: IWeeklyRevenueRetained[];
  rating: IRatingResponse;
}

export const PDFTemplate = () => {
  const [configuration, setConfiguration] = useState<
    IPDFConfiguration | undefined
  >(undefined);
  const { retailerUuid } = useParams<{ retailerUuid: string }>();

  useEffect(() => {
    if (!retailerUuid) {
      throw new Error("Missing retailer uuid");
    }

    const startDate = dayjs(new Date())
      .subtract(1, "month")
      .format(UTC_API_FORMAT);
    const endDate = dayjs(new Date()).format(UTC_API_FORMAT);

    Promise.all([
      getTotalRefunds(retailerUuid),
      getTotalSales(retailerUuid),
      getRevenueRetained(retailerUuid),
      getTop5ReturnedCategories(retailerUuid),
      getTop3ReturnedCountries(retailerUuid),
      getLabelsSaved(retailerUuid),
      fetchTopReturnedProducts(
        {
          start: startDate,
          end: endDate,
        },
        retailerUuid
      ),
      getRetailerContent(retailerUuid),
      getWeeklyRefunds(retailerUuid),
      getWeeklyRevenueRetained(retailerUuid),
      getRating(retailerUuid),
    ]).then(
      ([
        totalRefunds,
        totalSales,
        revenueRetained,
        topReturnedCategories,
        topReturnedCountries,
        labelsSaved,
        topReturnedProducts,
        retailerContent,
        weeklyRefunds,
        weeklyRevenueRetained,
        rating,
      ]) => {
        setConfiguration({
          totalRefunds,
          totalSales,
          revenueRetained,
          topReturnedCategories,
          topReturnedCountries,
          labelsSaved,
          retailerContent,
          topReturnedProducts: topReturnedProducts.slice(0, 5),
          weeklyRefunds: weeklyRefunds.refunds_by_week,
          weeklyRevenueRetained,
          rating,
        });
      }
    );
  }, [retailerUuid]);

  const revenueDifference = useMemo(() => {
    if (!configuration) {
      return {
        revenueRetainedPercent: 0,
        totalRefundsPercent: 0,
      };
    }
    const totalRefunds = Number(configuration.totalRefunds.total_refunds);
    const totalRevenueRetained = Number(
      configuration.revenueRetained.total_revenue_retained
    );

    const revenueSum = totalRefunds + totalRevenueRetained;

    return {
      revenueRetainedPercent: (totalRevenueRetained * 100) / revenueSum,
      totalRefundsPercent: (totalRefunds * 100) / revenueSum,
    };
  }, [configuration]);

  if (!configuration) {
    return null;
  }

  return (
    <div className="pdf-template">
      <PDFHeader />

      <PDFHeroSection retailerContent={configuration.retailerContent} />

      <PDFSection index="01." title="Return \nRates">
        <div className="pdf-template__grid">
          <PDFCard title="Total Sales (€)" color="white" hasShadow>
            <RevenueSummary
              revenue={Number(configuration.totalSales.total_sales)}
              pastRevenue={Number(configuration.totalSales.last_total_sales)}
              isHighlighted
              currency="EUR"
            />
          </PDFCard>

          <PDFCard title="Total refunds (€)" color="red">
            <RevenueSummary
              revenue={Number(configuration.totalRefunds.total_refunds)}
              pastRevenue={Number(
                configuration.totalRefunds.last_total_refunds
              )}
              currency="EUR"
            />
          </PDFCard>

          <PDFCard title="total revenue retained (€)" color="yellow">
            <RevenueSummary
              revenue={Number(
                configuration.revenueRetained.total_revenue_retained
              )}
              pastRevenue={Number(
                configuration.revenueRetained.last_total_revenue_retained
              )}
              currency="EUR"
            />
          </PDFCard>
        </div>

        <ul className="pdf-template__list">
          <li>
            Last period met minim most return 16.02.2023 t non deserunt ullamco
            est sit aliqua dolor do amet sint. Velit 22.02.2023 consequat duis
            enim velit mollit.
          </li>
        </ul>

        <div className="pdf-template__grid">
          <Summary
            cta={
              revenueDifference.revenueRetainedPercent >
              revenueDifference.totalRefundsPercent
                ? "Good job"
                : "Keep pushing"
            }
            icon={<i className="demo-icon icon-pie_chart">&#xe820;</i>}
            title={
              <>
                You have <b>{revenueDifference.totalRefundsPercent}%</b> of{" "}
                <b>Refunds</b> and{" "}
                <b>{revenueDifference.revenueRetainedPercent}%</b> of{" "}
                <b>Revenue retained.</b>
              </>
            }
            text={
              revenueDifference.revenueRetainedPercent >
              revenueDifference.totalRefundsPercent ? (
                <>
                  You are doing great on retention but there is always room for
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  improvement. Read more retention tips <a href="#">here</a>.
                </>
              ) : (
                <>
                  Lets work on those retention numbers a bit more.{" "}
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a href="#">Read how to do it</a>
                </>
              )
            }
          />

          <PDFCard title="Refunds">
            <RefundChart weeklyRefunds={configuration.weeklyRefunds} />
          </PDFCard>

          <PDFCard title="Retention Rate">
            <RetentionRateChart
              weeklyRevenueRetained={configuration.weeklyRevenueRetained}
            />
          </PDFCard>
        </div>
      </PDFSection>

      <PDFSection index="02." title="Returned \nProducts">
        <div className="pdf-template__grid">
          <TopReturningProducts
            topReturnedProducts={configuration.topReturnedProducts}
          />

          <TopReturnedCategories
            topReturnedCategories={configuration.topReturnedCategories}
          />

          <TopReturningCountry
            topReturnedCountries={configuration.topReturnedCountries}
          />
        </div>
      </PDFSection>

      <PDFSection index="03." title="Rate & \nSummary">
        <CustomerSatisfaction rating={configuration.rating} />

        <LabelsSaved
          className="pdf-template__labels-saved"
          labelsSaved={configuration.labelsSaved}
        />
      </PDFSection>
    </div>
  );
};
