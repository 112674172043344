import { BASE_URL } from "../constants";
import {
  ISmsTemplateTypes,
  IEmailTemplateTypes,
  IRetailerSetting,
  IGlobalStyles,
  IReason,
  IRetailerEmail,
  IRetailerEmailTemplate,
  IRetailerSmsTemplate,
  IStore,
  IWarehouse,
  IResponseStatus,
  ITmsProvidersTypes,
  ITmsRetailerApiTypes,
  ITestShippingLabelResponse,
  ITmsRetailerUpdate,
  IImportInfo,
  ISignUpGlobalStyles,
  TTranslationDictionary,
  IShippingCarriersResponse,
  IOnboardingProgress,
  IIntegrationAuthorizationRequest,
  IGetDrfContent,
  IFeature,
  IWarehouseReconciliationUrl,
  ITmsAddresses,
  IBlockedReturns,
  IAutomationRuleResponse,
  IAutoRefundReportResponse,
  ISegmentsResponse,
  ISegmentByIdResponse,
  ISegmentUpdatePayload,
  ISegmentsHistoryResponse,
  IShippingRuleResponse,
  ICustomCategoryResponse,
  ICustomCategoryPayload,
  ICustomCategoryProductsResponse,
  ICategoryProduct,
  IExchange,
  IExchangeOrderConfig,
  IAutomationParametersResponse,
  INewAutomationRule,
  IAutoRefundRuleCreateResponse,
  IApiDataType,
  IExchangeParamsResponse,
  IExchangeFeatureResponse,
  IReturnValidityExceptionDate,
  IToggleAutomationRule,
} from "../models";
import { getHeaders } from "./getHeaders";
import { handleApiResponse } from "../helper/handleApiResponse";

export function getGlobalStyles(
  retailerUuid?: string,
  genericUserId?: number
): Promise<IGlobalStyles> {
  return fetch(`${BASE_URL}/setting/global_styles/`, {
    method: "GET",
    headers: getHeaders(retailerUuid, genericUserId),
  }).then((res) => res.json());
}

export function updateGlobalStyles(
  data: IGlobalStyles,
  retailerUuid?: string,
  genericUserId?: number
): Promise<IGlobalStyles> {
  return fetch(`${BASE_URL}/setting/global_styles/`, {
    method: "PUT",
    headers: getHeaders(retailerUuid, genericUserId),
    body: JSON.stringify(data),
  }).then((res) => res.json());
}

export function getGlobalStylesDrfContent(
  retailerUuid: string,
  genericUserId: number
): Promise<IGetDrfContent> {
  return fetch(`${BASE_URL}/setting/global_styles/drf_content/`, {
    method: "GET",
    headers: getHeaders(retailerUuid, genericUserId),
  }).then(handleApiResponse);
}
export function getRetailerSetting(): Promise<IRetailerSetting> {
  return fetch(`${BASE_URL}/tracking_page/content/`, {
    method: "GET",
    headers: getHeaders(),
  }).then((res) => res.json());
}
export function updateRetailerSetting(
  data: IRetailerSetting
): Promise<IRetailerSetting> {
  return fetch(`${BASE_URL}/tracking_page/content/`, {
    method: "PUT",
    headers: getHeaders(),
    body: JSON.stringify(data),
  }).then((res) => res.json());
}

export function getGlobalStylesCss(): Promise<string> {
  return fetch(`${BASE_URL}/setting/global_styles/css/`, {
    method: "GET",
    headers: getHeaders(),
  }).then((res) => res.text());
}

export function updateGlobalStylesCss(styles: string) {
  return fetch(`${BASE_URL}/setting/global_styles/css/`, {
    method: "PUT",
    headers: getHeaders(),
    body: styles,
  }).then((res) => res.json());
}

export function getStores(): Promise<IStore[]> {
  return fetch(`${BASE_URL}/setting/stores/`, {
    method: "GET",
    headers: getHeaders(),
  }).then((res) => res.json());
}

export function updateStores(newStore: Omit<IStore, "retailer_store_id">) {
  return fetch(`${BASE_URL}/setting/stores/`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(newStore),
  }).then((res) => res.json());
}
// export function getInventoryThreshold(): Promise<any> {
//   return fetch(`${BASE_URL}/setting/exchange/low_inventory_threshold/`, {
//     method: "GET",
//     headers: getHeaders(),
//   }).then((res) => res.json());
// }
// export function updateInventoryThreshold(data: {
//   low_inventory_threshold: number;
// }): Promise<number> {
//   return fetch(`${BASE_URL}/setting/exchange/low_inventory_threshold/`, {
//     method: "PUT",
//     headers: getHeaders(),
//     body: JSON.stringify(data),
//   }).then((res) => res.json());
// }
export function getRetailerFeatureExchange(): Promise<IExchangeFeatureResponse> {
  return fetch(`${BASE_URL}/setting/exchange/exchange_feature/`, {
    method: "GET",
    headers: getHeaders(),
  }).then((res) => res.json());
}
export function updateRetailerFeatureExchange(
  permissionId: number
): Promise<unknown> {
  return fetch(`${BASE_URL}/setting/exchange/exchange_feature/`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({ permission_id: permissionId }),
  }).then(handleApiResponse);
}
export function deleteRetailerFeatureExchange(
  mappingId: number
): Promise<IFeature[]> {
  return fetch(`${BASE_URL}/setting/exchange/exchange_feature/`, {
    method: "DELETE",
    headers: getHeaders(),
    body: JSON.stringify({ mapping_id: mappingId }),
  }).then(handleApiResponse);
}
export function getExchangeConfig(): Promise<IExchange> {
  return fetch(`${BASE_URL}/setting/exchange/exchange_option/`, {
    method: "GET",
    headers: getHeaders(),
  }).then((res) => res.json());
}
export function getExchangeOptions(): Promise<IExchangeOrderConfig[]> {
  return fetch(`${BASE_URL}/setting/exchange/exchange_options/`, {
    method: "GET",
    headers: getHeaders(),
  }).then((res) => res.json());
}
export function getExchangeParams(): Promise<IExchangeParamsResponse> {
  return fetch(`${BASE_URL}/setting/exchange/params/`, {
    method: "GET",
    headers: getHeaders(),
  }).then((res) => res.json());
}
export function updateExchange(
  newExchangeConfig: IExchange,
  retailerUuid?: string,
  genericUserId?: number
): Promise<IExchange> {
  return fetch(`${BASE_URL}/setting/exchange/exchange_option/`, {
    method: "PUT",
    headers: getHeaders(),
    body: JSON.stringify(newExchangeConfig),
  }).then((res) => res.json());
}

export function getRetailerFeatureEmailTemplate(): Promise<any> {
  return fetch(`${BASE_URL}/setting/email/notification/`, {
    method: "GET",
    headers: getHeaders(),
  }).then((res) => res.json());
}
export function updateRetailerFeatureEmailTemplate(
  state: boolean,
  retailerUuid?: string,
  genericUserId?: number
): Promise<unknown> {
  return fetch(`${BASE_URL}/setting/email/notification/`, {
    method: "PUT",
    headers: getHeaders(retailerUuid, genericUserId),
    body: JSON.stringify(state),
  }).then(handleApiResponse);
}
export function getRetailerFeatureShippingRules(): Promise<any> {
  return fetch(`${BASE_URL}/setting/shipping/generate/`, {
    method: "GET",
    headers: getHeaders(),
  }).then((res) => res.json());
}
export function updateRetailerFeatureShippingRules(
  state: boolean,
  retailerUuid?: string,
  genericUserId?: number
): Promise<unknown> {
  return fetch(`${BASE_URL}/setting/shipping/generate/`, {
    method: "PUT",
    headers: getHeaders(retailerUuid, genericUserId),
    body: JSON.stringify(state),
  }).then(handleApiResponse);
}
export function getRetailerFeatureAutoRefund(): Promise<any> {
  return fetch(`${BASE_URL}/setting/auto-refund/feature/`, {
    method: "GET",
    headers: getHeaders(),
  }).then((res) => res.json());
}
export function updateRetailerFeatureAutoRefund(
  enabled: boolean
): Promise<boolean> {
  return fetch(`${BASE_URL}/setting/auto-refund/feature/`, {
    method: "PUT",
    headers: getHeaders(),
    body: JSON.stringify({ enabled: enabled }),
  }).then(handleApiResponse);
}
export function getWarehouse(): Promise<IWarehouse> {
  return fetch(`${BASE_URL}/setting/warehouse/`, {
    method: "GET",
    headers: getHeaders(),
  }).then((res) => res.json());
}

export function updateWarehouse(
  updatedWarehouse: IWarehouse,
  retailerUuid?: string,
  genericUserId?: number
) {
  return fetch(`${BASE_URL}/setting/warehouse/`, {
    method: "POST",
    headers: getHeaders(retailerUuid, genericUserId),
    body: JSON.stringify(updatedWarehouse),
  }).then(handleApiResponse);
}

export function getWarehouseReconciliationUrl(): Promise<IWarehouseReconciliationUrl> {
  return fetch(`${BASE_URL}/setting/warehouse_reconciliation/url/`, {
    method: "GET",
    headers: getHeaders(),
  }).then(handleApiResponse);
}

export function updateGlobalStylesOnboarding(
  setWarehouse: ISignUpGlobalStyles,
  retailerUuid?: string,
  genericUserId?: number
) {
  return fetch(`${BASE_URL}/setting/global_styles/`, {
    method: "PUT",
    headers: getHeaders(retailerUuid, genericUserId),
    body: JSON.stringify(setWarehouse),
  }).then(handleApiResponse);
}

export function getGlobalStylesOnboarding(
  retailerUuid?: string,
  genericUserId?: number
): Promise<ISignUpGlobalStyles> {
  return fetch(`${BASE_URL}/setting/global_styles/`, {
    method: "GET",
    headers: getHeaders(retailerUuid, genericUserId),
  }).then(handleApiResponse);
}

export function getReasons(): Promise<IReason[]> {
  return fetch(`${BASE_URL}/setting/reasons/`, {
    method: "GET",
    headers: getHeaders(),
  }).then((res) => res.json());
}

export function updateReasons(updatedReason: IReason) {
  return fetch(`${BASE_URL}/setting/reasons/`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(updatedReason),
  }).then((res) => res.json());
}

export function updateReasonsOrder(order: Record<string, string>) {
  return fetch(`${BASE_URL}/setting/reasons/re_order/`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(order),
  }).then((res) => res.json());
}

export function getReturnValidityExceptionPeriods(): Promise<
  IReturnValidityExceptionDate[]
> {
  return fetch(`${BASE_URL}/setting/returns/validity_date`, {
    method: "GET",
    headers: getHeaders(),
  }).then((res) => res.json());
}

export function setReturnValidityExceptionPeriods(
  dates: Pick<IReturnValidityExceptionDate, "start_date" | "end_date">[]
) {
  return fetch(`${BASE_URL}/setting/returns/validity_date`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      dates,
    }),
  }).then((res) => res.json());
}

export function deleteReturnValidityExceptionPeriods(
  dates: IReturnValidityExceptionDate[]
) {
  return fetch(`${BASE_URL}/setting/returns/validity_date`, {
    method: "DELETE",
    headers: getHeaders(),
    body: JSON.stringify({
      dates,
    }),
  }).then((res) => res.json());
}

export function getTranslations(): Promise<string> {
  return fetch(`${BASE_URL}/setting/translation/`, {
    method: "GET",
    headers: getHeaders(),
  }).then((res) => res.text());
}

export function getDefaultDictionary(): Promise<TTranslationDictionary> {
  return fetch(process.env.REACT_APP_DEFAULT_TRANSLATION_URL || "", {
    headers: {
      method: "GET",
    },
  }).then((res) => res.json());
}

export function updateTranslations(translation: string) {
  return fetch(`${BASE_URL}/setting/translation/`, {
    method: "PUT",
    headers: getHeaders(),
    body: translation,
  }).then((res) => res.json());
}

export function getEmailTemplateTypes(
  retailerUuid?: string,
  genericUserId?: number
): Promise<IEmailTemplateTypes[]> {
  return fetch(`${BASE_URL}/setting/email_template_type/`, {
    method: "GET",
    headers: getHeaders(retailerUuid, genericUserId),
  }).then((res) => res.json());
}
export function getSMSTemplateTypes(): Promise<ISmsTemplateTypes[]> {
  return fetch(`${BASE_URL}/setting/sms_template_type/`, {
    method: "GET",
    headers: getHeaders(),
  }).then((res) => res.json());
}

export function getRetailerEmail(): Promise<IRetailerEmail> {
  return fetch(`${BASE_URL}/setting/retailer_email/`, {
    method: "GET",
    headers: getHeaders(),
  }).then((res) => res.json());
}

export function updateRetailerEmail(update: IRetailerEmail): Promise<unknown> {
  return fetch(`${BASE_URL}/setting/retailer_email/`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(update),
  }).then((res) => res.json());
}

export function getEmailTemplate(
  id: number,
  retailerUuid?: string,
  genericUserId?: number
): Promise<IRetailerEmailTemplate> {
  return fetch(`${BASE_URL}/setting/retailer_email_template/${id}/`, {
    method: "GET",
    headers: getHeaders(retailerUuid, genericUserId),
  }).then(handleApiResponse);
}

export function getSMSTemplate(id: number): Promise<IRetailerSmsTemplate> {
  return fetch(`${BASE_URL}/setting/retailer_sms_template/${id}/`, {
    method: "GET",
    headers: getHeaders(),
  }).then((res) => res.json());
}

export function updateEmailTemplate(
  id: number,
  update: IRetailerEmailTemplate,
  retailerUuid?: string,
  genericUserId?: number
): Promise<any> {
  return fetch(`${BASE_URL}/setting/retailer_email_template/${id}/`, {
    method: "POST",
    headers: getHeaders(retailerUuid, genericUserId),
    body: JSON.stringify(update),
  }).then(handleApiResponse);
}

export function updateSMSTemplate(
  id: number,
  update: IRetailerSmsTemplate
): Promise<any> {
  return fetch(`${BASE_URL}/setting/retailer_sms_template/${id}/`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(update),
  }).then((res) => res.json());
}

export function sendTestEmail(id: number) {
  return fetch(`${BASE_URL}/communicate/test_email/${id}/`, {
    method: "POST",
    headers: getHeaders(),
  }).then((res) => res.json());
}

export function sendTestToCustomEmail(id: number, email: string) {
  return fetch(`${BASE_URL}/communicate/test_email/${id}/${email}/`, {
    method: "POST",
    headers: getHeaders(),
  }).then((res) => res.json());
}
export function getOrderImports(
  start: string,
  end: string,
  retailerUuid?: string,
  genericUserId?: number
): Promise<IResponseStatus[]> {
  return fetch(`${BASE_URL}/import/orders/start/${start}/end/${end}/`, {
    method: "GET",
    headers: getHeaders(retailerUuid, genericUserId),
  }).then((res) => res.json());
}

export function getRefundImports(
  start: string,
  end: string,
  retailerUuid?: string,
  genericUserId?: number
): Promise<IResponseStatus[]> {
  return fetch(`${BASE_URL}/import/credits/start/${start}/end/${end}/`, {
    method: "GET",
    headers: getHeaders(retailerUuid, genericUserId),
  }).then((res) => res.json());
}

export function getSingleImport(extOrderId: string): Promise<IImportInfo[]> {
  return fetch(`${BASE_URL}/import/single_order/${extOrderId}/`, {
    method: "GET",
    headers: getHeaders(),
  }).then((res) => res.json());
}

export function getTmsProviders(
  retailerUuid?: string,
  genericUserId?: number
): Promise<ITmsProvidersTypes[]> {
  return fetch(`${BASE_URL}/shipping/get_tms_providers/`, {
    method: "GET",
    headers: getHeaders(retailerUuid, genericUserId),
  }).then(handleApiResponse);
}

export function getTmsRetailerApi(
  retailerId: number
): Promise<ITmsRetailerApiTypes> {
  return fetch(
    `${BASE_URL}/shipping/tms_retailer_api/retailer/${retailerId}/`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  ).then((res) => res.json());
}

export function sendTmsRetailerApi(
  retailerId: number,
  update: ITmsRetailerUpdate
): Promise<any> {
  return fetch(
    `${BASE_URL}/shipping/tms_retailer_api/retailer/${retailerId}/`,
    {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify(update),
    }
  ).then((res) => res.json());
}

export function getTestShippingLabel(
  retailerId: number
): Promise<ITestShippingLabelResponse> {
  return fetch(
    `${BASE_URL}/shipping/generate_test_shipping_label/retailer/${retailerId}/`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  ).then((res) => res.json());
}

export function getSignUpTmsRetailerApi(
  retailerUuid?: string,
  genericUserId?: number
): Promise<ITmsRetailerApiTypes> {
  return fetch(`${BASE_URL}/shipping/tms_retailer_api/`, {
    method: "GET",
    headers: getHeaders(retailerUuid, genericUserId),
  }).then(handleApiResponse);
}

export function sendSignUpTmsRetailerApi(
  update: ITmsRetailerUpdate,
  retailerUuid?: string,
  genericUserId?: number
): Promise<any> {
  return fetch(`${BASE_URL}/shipping/tms_retailer_api/`, {
    method: "POST",
    headers: getHeaders(retailerUuid, genericUserId),
    body: JSON.stringify(update),
  }).then(handleApiResponse);
}

export function getSignUpTestShippingLabel(
  update: ITmsRetailerUpdate,
  retailerUuid?: string,
  genericUserId?: number
): Promise<ITestShippingLabelResponse> {
  return fetch(`${BASE_URL}/shipping/generate_test_shipping_label/`, {
    method: "POST",
    headers: getHeaders(retailerUuid, genericUserId),
    body: JSON.stringify(update),
  }).then(handleApiResponse);
}

export function getTestShippingLabelV2(
  update: ITmsAddresses,
  retailerUuid?: string,
  genericUserId?: number
): Promise<ITestShippingLabelResponse> {
  return fetch(`${BASE_URL}/shipping/generate_test_shipping_label/v2/`, {
    method: "POST",
    headers: getHeaders(retailerUuid, genericUserId),
    body: JSON.stringify(update),
  }).then(handleApiResponse);
}

export function getTestShippingLabelV2ForRetailer(
  retailerId: number,
  addresses: ITmsAddresses,
  retailerUuid?: string,
  genericUserId?: number
): Promise<ITestShippingLabelResponse> {
  return fetch(
    `${BASE_URL}/shipping/generate_test_shipping_label/retailer/${retailerId}/v2/`,
    {
      method: "POST",
      headers: getHeaders(retailerUuid, genericUserId),
      body: JSON.stringify(addresses),
    }
  ).then(handleApiResponse);
}

export function getShippingCarriers(): Promise<IShippingCarriersResponse> {
  return fetch(`${BASE_URL}/shipping/carriers/`, {
    method: "GET",
    headers: getHeaders(),
  }).then(handleApiResponse);
}

export function setShippingCarriersSettings(
  carriers: string[]
): Promise<IOnboardingProgress> {
  return fetch(`${BASE_URL}/setting/onboarding/set_carriers/`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({ carriers }),
  }).then(handleApiResponse);
}

export function getIntegrationSettings(): Promise<any> {
  return fetch(`${BASE_URL}/setting/integration/`, {
    method: "GET",
    headers: getHeaders(),
  }).then(handleApiResponse);
}

export function postIntegrationActive(
  integrationName: string,
  integrationPayload: Record<string, string>
): Promise<any> {
  return fetch(`${BASE_URL}/auth/${integrationName}/domain/`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(integrationPayload),
  }).then(handleApiResponse);
}

export function getIntegrationAuthorizaton(
  integrationName: string
): Promise<IIntegrationAuthorizationRequest> {
  return fetch(`${BASE_URL}/auth/${integrationName}/authorisation/`, {
    method: "GET",
    headers: getHeaders(),
  }).then(handleApiResponse);
}

export function postDisableIntegration(integrationName: string) {
  return fetch(`${BASE_URL}/integration/${integrationName}/disable/`, {
    method: "POST",
    headers: getHeaders(),
  }).then(handleApiResponse);
}
export function getReturnTypes(): Promise<IFeature[]> {
  return fetch(`${BASE_URL}/setting/retailer/return/types/`, {
    method: "GET",
    headers: getHeaders(),
  }).then(handleApiResponse);
}

export function enableReturnType(permissionId: number): Promise<unknown> {
  return fetch(`${BASE_URL}/setting/retailer/return/types/`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      permission_id: permissionId,
    }),
  }).then(handleApiResponse);
}

export function disableReturnType(mappingId: number): Promise<IFeature[]> {
  return fetch(`${BASE_URL}/setting/retailer/return/types/`, {
    method: "DELETE",
    headers: getHeaders(),
    body: JSON.stringify({
      mapping_id: mappingId,
    }),
  }).then(handleApiResponse);
}

export const fetcher = (url: string) =>
  fetch(`${BASE_URL}${url}`, {
    method: "GET",
    headers: getHeaders(),
  }).then(handleApiResponse);

export const getBlockedReturnsCategories = (): Promise<IBlockedReturns> => {
  return fetch(`${BASE_URL}/setting/blocked_returns`, {
    method: "GET",
    headers: getHeaders(),
  }).then(handleApiResponse);
};

export const updateBlockedReturnsCategory = ({
  id,
  isBlocked,
  blockedBy,
}: {
  id: number;
  isBlocked: boolean;
  blockedBy: number;
}): Promise<IResponseStatus> => {
  return fetch(`${BASE_URL}/setting/blocked_returns`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      id,
      blocked: isBlocked,
      updated_by: blockedBy,
    }),
  }).then(handleApiResponse);
};

export const downloadBlockedReturnsCategory = (id: number): Promise<string> => {
  return fetch(`${BASE_URL}/setting/blocked_returns/download/${id}`, {
    method: "GET",
    headers: {
      ...getHeaders(),
      "Content-Type": "text/csv;charset=UTF-8",
    },
  }).then((res) => {
    if (!res.ok) {
      throw new Error("SERVER_ERROR");
    }

    return res.text();
  });
};

export const getAutoRefundRules = (): Promise<IAutomationRuleResponse> => {
  return fetch(`${BASE_URL}/setting/auto-refund/rule`, {
    method: "GET",
    headers: getHeaders(),
  }).then(handleApiResponse);
};

export const setAutoRefundRuleEnabled = (
  refundRuleId: number,
  isEnabled: boolean
): Promise<IResponseStatus> => {
  return fetch(`${BASE_URL}/setting/auto_refund/rules/${refundRuleId}`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      is_enabled: isEnabled,
    }),
  }).then(handleApiResponse);
};

export const getAutoRefundReports = ({
  start_date,
  end_date,
}: {
  start_date: string;
  end_date: string;
}): Promise<IAutoRefundReportResponse> => {
  const queryString = new URLSearchParams({ start_date, end_date });

  return fetch(
    `${BASE_URL}/setting/auto_refund/reports?${queryString.toString()}`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  ).then(handleApiResponse);
};

export const getSegments = (): Promise<ISegmentsResponse> => {
  return fetch(`${BASE_URL}/setting/segments/`, {
    method: "GET",
    headers: getHeaders(),
  }).then(handleApiResponse);
};

export const getSegmentSettingsById = (
  id: string
): Promise<ISegmentByIdResponse> => {
  return fetch(`${BASE_URL}/setting/segments/${id}`, {
    method: "GET",
    headers: getHeaders(),
  }).then(handleApiResponse);
};

export const updateSegmentSettingsById = (
  id: string,
  payload: ISegmentUpdatePayload
): Promise<IResponseStatus> => {
  return fetch(`${BASE_URL}/setting/segments/${id}`, {
    method: "PUT",
    headers: getHeaders(),
    body: JSON.stringify(payload),
  }).then(handleApiResponse);
};

export const getSegmentSettingsHistoryById = (
  id: string
): Promise<ISegmentsHistoryResponse> => {
  return fetch(`${BASE_URL}/setting/segments/${id}/versions`, {
    method: "GET",
    headers: getHeaders(),
  }).then(handleApiResponse);
};

export const getShippingRules = (): Promise<IShippingRuleResponse> => {
  return fetch(`${BASE_URL}/setting/shipping/rules`, {
    method: "GET",
    headers: getHeaders(),
  }).then(handleApiResponse);
};

export const getCustomCategories = (): Promise<ICustomCategoryResponse> => {
  return fetch(`${BASE_URL}/setting/custom_categories/`, {
    method: "GET",
    headers: getHeaders(),
  }).then(handleApiResponse);
};

export const createNewCustomCategory = (
  payload: ICustomCategoryPayload
): Promise<IResponseStatus> => {
  return fetch(`${BASE_URL}/setting/custom_categories/`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(payload),
  }).then(handleApiResponse);
};

export const updateCustomCategory = (
  categoryId: number,
  payload: ICustomCategoryPayload
): Promise<IResponseStatus> => {
  return fetch(`${BASE_URL}/setting/custom_categories/${categoryId}`, {
    method: "PUT",
    headers: getHeaders(),
    body: JSON.stringify(payload),
  }).then(handleApiResponse);
};

export const getCustomCategoriesProducts =
  (): Promise<ICustomCategoryProductsResponse> => {
    return fetch(`${BASE_URL}/setting/custom_categories/products`, {
      method: "GET",
      headers: getHeaders(),
    }).then(handleApiResponse);
  };

export const getProductsByCustomCategory = (
  categoryId: number
): Promise<{ products: ICategoryProduct[] }> => {
  return fetch(`${BASE_URL}/setting/custom_categories/${categoryId}`, {
    method: "GET",
    headers: getHeaders(),
  }).then(handleApiResponse);
};

export const getAutoRefundParameters =
  (): Promise<IAutomationParametersResponse> => {
    return fetch(`${BASE_URL}/setting/auto-refund/rule/parameters`, {
      method: "GET",
      headers: getHeaders(),
    }).then(handleApiResponse);
  };

export const setAutoRefundRule = (
  payload: INewAutomationRule
): Promise<IAutoRefundRuleCreateResponse> => {
  return fetch(`${BASE_URL}/setting/auto-refund/rule`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(payload),
  }).then(handleApiResponse);
};

export const toggleAutoRefundRule = (
  payload: IToggleAutomationRule
): Promise<IAutoRefundRuleCreateResponse> => {
  return fetch(`${BASE_URL}/setting/auto-refund/rule/${payload.ruleId}`, {
    method: "PUT",
    headers: getHeaders(),
    body: JSON.stringify({
      is_enabled: payload.isEnabled,
    }),
  }).then(handleApiResponse);
};

export const deleteAutoRefundRule = (
  ruleId: number
): Promise<IResponseStatus> => {
  return fetch(`${BASE_URL}/setting/auto-refund/rule/${ruleId}`, {
    method: "DELETE",
    headers: getHeaders(),
  }).then(handleApiResponse);
};

export const getApiList = () => {
  return fetch(`${BASE_URL}/setting/api_keys/`, {
    method: "GET",
    headers: getHeaders(),
  }).then(handleApiResponse);
};

export function updateApiList(
  friendly_name: string,
  apikey: number | string
): Promise<IApiDataType> {
  return fetch(`${BASE_URL}/setting/api_keys/`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({ friendly_name: friendly_name, apikey: apikey }),
  }).then(handleApiResponse);
}

export const deleteApi = (id: number): Promise<IApiDataType> => {
  return fetch(`${BASE_URL}/setting/api_keys/${id}/`, {
    method: "DELETE",
    headers: getHeaders(),
  }).then(handleApiResponse);
};
