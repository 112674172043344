import React from "react";
import "./TextWithAction.scss";
import classNames from "classnames";

export const TextWithAction: React.FC<
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLSpanElement>,
    HTMLSpanElement
  >
> = ({ children, className, ...spanProps }) => {
  return (
    <span className={classNames("text-with-action", className)} {...spanProps}>
      {children}
    </span>
  );
};
