import { BASE_URL } from "../constants";
import { handleApiResponse } from "../helper/handleApiResponse";
import {
  IFeature,
  IRetailer,
  IRetailerAdminResponse,
  IRetailerUuidResponse,
  IUser,
  IUserWithPermissions,
  IVerifyResponse,
} from "../models";
import { getHeaders } from "./getHeaders";

export function getRetailers(): Promise<IRetailer[]> {
  return fetch(`${BASE_URL}/access/retailer_admin/retailers/`, {
    method: "GET",
    headers: getHeaders(),
  }).then(handleApiResponse);
}

export function removeAdmin(entryId: number) {
  return fetch(`${BASE_URL}/access/retailer_admin/mapping/${entryId}/`, {
    method: "DELETE",
    headers: getHeaders(),
  }).then(handleApiResponse);
}

export function updateAdmin(
  entryId: number,
  updatedAdmin: Partial<IUserWithPermissions>
) {
  return fetch(`${BASE_URL}/access/retailer_admin/mapping/${entryId}/`, {
    method: "PUT",
    headers: getHeaders(),
    body: JSON.stringify(updatedAdmin),
  }).then(handleApiResponse);
}

export function addUser(
  email: string,
  selectedRetailerId: number
): Promise<{ generic_user_id: number }> {
  window.dataLayer.push({
    event: "added_new_user",
    new_user_email: email,
  });
  return fetch(
    `${BASE_URL}/access/superadmin/email_user/retailer/${selectedRetailerId}/`,
    {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify({ email }),
    }
  ).then(handleApiResponse);
}

export function addAdmin(selectedRetailerId: number, genericUserId: number) {
  return fetch(
    `${BASE_URL}/access/retailer_admin/retailer/${selectedRetailerId}/user/${genericUserId}/`,
    {
      method: "POST",
      headers: getHeaders(),
    }
  ).then(handleApiResponse);
}

export function getUserPermissions(
  adminRetailerId: number
): Promise<IUserWithPermissions[]> {
  return fetch(
    `${BASE_URL}/access/retailer_admin/retailer/${adminRetailerId}/`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  ).then(handleApiResponse);
}

export function getFeatures(retailerId: number): Promise<IFeature[]> {
  return fetch(
    `${BASE_URL}/access/retailer_feature_set/retailer/${retailerId}/`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  ).then(handleApiResponse);
}

export function addFeatureConfig(retailerId: number, feature: IFeature) {
  return fetch(
    `${BASE_URL}/access/retailer_feature_set/mapping/${retailerId}/`,
    {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify(feature),
    }
  ).then(handleApiResponse);
}

export function deleteFeatureConfig(retailerId: number, feature: IFeature) {
  return fetch(
    `${BASE_URL}/access/retailer_feature_set/mapping/${retailerId}/`,
    {
      method: "DELETE",
      headers: getHeaders(),
      body: JSON.stringify(feature),
    }
  ).then(handleApiResponse);
}

export function getUsers(): Promise<IUser[]> {
  return fetch(`${BASE_URL}/access/user_management/users/`, {
    method: "GET",
    headers: getHeaders(),
  }).then(handleApiResponse);
}

export function deleteUser(genericUserId: number) {
  return fetch(`${BASE_URL}/access/user_management/${genericUserId}/`, {
    method: "DELETE",
    headers: getHeaders(),
  }).then(handleApiResponse);
}

export function getUserPagesAccess(genericUserId: number): Promise<IFeature[]> {
  return fetch(`${BASE_URL}/access/user_management/pages/${genericUserId}/`, {
    method: "GET",
    headers: getHeaders(),
  }).then(handleApiResponse);
}

export function getUserFeaturesAccess(
  genericUserId: number
): Promise<IFeature[]> {
  return fetch(
    `${BASE_URL}/access/user_management/features/${genericUserId}/`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  ).then(handleApiResponse);
}

export function addPagePermission(genericUserId: number, feature: IFeature) {
  return fetch(`${BASE_URL}/access/user_management/page/mapping/`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      ...feature,
      generic_user_id: genericUserId,
    }),
  }).then(handleApiResponse);
}

export function deletePagePermission(genericUserId: number, feature: IFeature) {
  return fetch(`${BASE_URL}/access/user_management/page/mapping/`, {
    method: "DELETE",
    headers: getHeaders(),
    body: JSON.stringify({
      ...feature,
      generic_user_id: genericUserId,
    }),
  }).then(handleApiResponse);
}

export function addFeaturePermission(genericUserId: number, feature: IFeature) {
  return fetch(`${BASE_URL}/access/user_management/feature/mapping/`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      ...feature,
      generic_user_id: genericUserId,
    }),
  }).then(handleApiResponse);
}

export function deleteFeaturePermission(
  genericUserId: number,
  feature: IFeature
) {
  return fetch(`${BASE_URL}/access/user_management/feature/mapping/`, {
    method: "DELETE",
    headers: getHeaders(),
    body: JSON.stringify({
      ...feature,
      generic_user_id: genericUserId,
    }),
  }).then(handleApiResponse);
}

export function getRetailerFeatures(retailerId: number): Promise<IFeature[]> {
  return fetch(
    `${process.env.REACT_APP_BASE_URL}/access/retailer_feature_set/retailer/${retailerId}/`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  ).then(handleApiResponse);
}

export function getCognitoAuthToken(code: string) {
  const formData = new FormData();
  formData.append("grant_type", "authorization_code");
  formData.append("client_id", process.env.REACT_APP_COGNITO_CLIENT_ID || "");
  formData.append("code", code);
  formData.append(
    "redirect_uri",
    process.env.REACT_APP_COGNITO_REDIRECT_URI || ""
  );

  const data = new URLSearchParams();
  data.append("grant_type", "authorization_code");
  data.append("client_id", process.env.REACT_APP_COGNITO_CLIENT_ID || "");
  data.append("code", code);
  data.append("redirect_uri", process.env.REACT_APP_COGNITO_REDIRECT_URI || "");

  return fetch(`${process.env.REACT_APP_COGNITO_AUTH_DOMAIN}/oauth2/token`, {
    method: "POST",
    headers: {
      // 'retailer_uuid': getRetailerUuid(),
      // 'generic-user-id': String(getGenericUserId()),
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/json; charset=utf-8",
      // 'Authorization': `Basic ${btoa(process.env.REACT_APP_COGNITO_CLIENT_ID + ':' + process.env.REACT_APP_COGNITO_CLIENT_SECRET)}`
      // 'Authorization': 'Basic ' + Buffer.from(`${process.env.REACT_APP_COGNITO_CLIENT_ID}`).toString('base64')
    },
    body: data.toString(),
  });
  // .then(handleApiResponse)
}

export function getRetailersUserAccess(
  genericUserId: number
): Promise<IRetailerUuidResponse[]> {
  return fetch(
    `${process.env.REACT_APP_BASE_URL}/access/user_retailer_access/${genericUserId}/`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  ).then(handleApiResponse);
}

export function verify(): Promise<IVerifyResponse> {
  return fetch(`${process.env.REACT_APP_BASE_URL}/auth/shopify/verify/`, {
    method: "GET",
    headers: getHeaders(),
    credentials: "include",
  }).then(handleApiResponse);
}

export function shopifyLogout() {
  return fetch(`${process.env.REACT_APP_BASE_URL}/auth/shopify/logout/`, {
    method: "GET",
    headers: getHeaders(),
    credentials: "include",
  }).then(handleApiResponse);
}

export function isRetailerAdmin(): Promise<IRetailerAdminResponse> {
  return fetch(`${process.env.REACT_APP_BASE_URL}/access/is_retailer_admin/`, {
    method: "GET",
    headers: getHeaders(),
  }).then(handleApiResponse);
}
