import React, { useEffect, useState } from "react";

export const setGTag = (gTag: string): void => {
  const script = document.createElement("script");
  script.text = `
        ;(function (w, d, s, l, i) {
            w[l] = w[l] || []
            w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" })
            var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l != "dataLayer" ? "&l=" + l : ""
            j.async = true
            j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl
            f.parentNode.insertBefore(j, f)
        })(window, document, "script", "dataLayer", "${gTag}")
    `;
  document.head.appendChild(script);
};

interface GoogleTagManagerProps {
  gtag: string;
}

const GoogleTagManager: React.FC<GoogleTagManagerProps> = ({ gtag }) => {
  const [isTag, setIsTag] = useState<boolean>(false);

  useEffect(() => {
    if (gtag && !isTag) {
      setGTag(gtag);
      setIsTag(true);
    }
  }, [gtag, isTag]);

  return (
    <>
      {isTag && (
        <noscript>
          <iframe
            src={"https://www.googletagmanager.com/ns.html?id=" + gtag}
            height="0"
            width="0"
            title={"googleTagManager"}
          ></iframe>
        </noscript>
      )}
    </>
  );
};

export default GoogleTagManager;
