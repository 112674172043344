import React, { useMemo } from "react";
import { ICountryRate } from "../../../../../../models";
import { COUNTRY_CODES } from "../../../../../../validationSchemas/countryCode";

import "./ReturningCountryRate.scss";

interface IReturningCountryRateProps {
  countryRate: ICountryRate;
}

export const ReturningCountryRate: React.FC<IReturningCountryRateProps> = ({
  countryRate,
}) => {
  const country = useMemo(() => {
    return COUNTRY_CODES.find((c) => c.code === countryRate.country_code);
  }, [countryRate]);

  return (
    <div className="returning-country-rate">
      <div className="returning-country-rate__country">
        {country?.name} ({countryRate.return_percent}%)
      </div>

      <div className="returning-country-rate__revenue">
        {countryRate.return_cost_in_euro.toLocaleString("en-us", {
          style: "currency",
          currency: "EUR",
        })}
      </div>
    </div>
  );
};
