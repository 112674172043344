import dayjs, { Dayjs } from "dayjs";
import React, { useEffect, useState } from "react";

import { TDateRange } from "../components/DateRangePicker/DateRangePicker";
import { useLocation } from "react-router-dom";
import { IAnalyticsChannel, IReturnProductFromBackend } from "../models";
import { getAnalyticsChannels } from "../api/statistic";

export const ALL_OPTION = {
  label: "All source channels",
  value: "all",
};

export const DEFAULT_CHANNELS = ["web", "pos"];

interface IInsightsPageContext {
  product: IReturnProductFromBackend | undefined;
  setProduct: (product: IReturnProductFromBackend | undefined) => void;
  dateRange: TDateRange;
  setDateRange: (dateRange: TDateRange) => void;
  channels: string[];
  setChannels: React.Dispatch<React.SetStateAction<string[]>>;
  availableChannels: IAnalyticsChannel[] | undefined;
}

export const InsightsPageContext = React.createContext<IInsightsPageContext>({
  product: undefined,
  setProduct() {},
  dateRange: [dayjs().subtract(1, "month"), dayjs()] as [Dayjs, Dayjs],
  setDateRange() {},
  channels: [],
  setChannels: () => {},
  availableChannels: undefined,
});

export const InsightsPageContextStore = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const [product, setProduct] = useState<IReturnProductFromBackend>();
  const location = useLocation();
  const [dateRange, setDateRange] = useState<TDateRange>(() => {
    const urlSearchParams = new URLSearchParams(location.search);
    const start = dayjs(urlSearchParams.get("start"));
    const end = dayjs(urlSearchParams.get("end"));
    if (start.isValid() && end.isValid()) {
      return [start, end];
    }
    return [
      dayjs().subtract(process.env.REACT_APP_IS_DEBUG ? 6 : 1, "month"),
      dayjs(),
    ] as [Dayjs, Dayjs];
  });

  const [channels, setChannels] = useState<string[]>([]);
  const [availableChannels, setAvailableChannels] =
    useState<IAnalyticsChannel[]>();

  useEffect(() => {
    getAnalyticsChannels()
      .then((channels) => {
        setAvailableChannels(channels);
        setChannels([
          ALL_OPTION.value,
          ...channels.map((x) => x.source_channel),
        ]);
      })
      .catch((err) => {
        console.error(err);
        setChannels(DEFAULT_CHANNELS);
      });
    return () => {
      setAvailableChannels(undefined);
    };
  }, [setAvailableChannels]);

  return (
    <InsightsPageContext.Provider
      value={{
        product,
        setProduct,
        dateRange,
        setDateRange,
        channels,
        setChannels,
        availableChannels,
      }}
    >
      {children}
    </InsightsPageContext.Provider>
  );
};
