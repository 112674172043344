export async function handleApiResponse(res: any) {
  const data = await res.json();

  if (!res.ok) {
    throw new Error(data.message || "SERVER_ERROR");
  }
  if (res.status === 400) {
    throw new Error(data.message || "NO_INPUT_DATA_PROVIDED");
  }
  if (res.status === 401) {
    throw new Error(data.message || "UNAUTHORIZED");
  }
  if (res.status === 500) {
    throw new Error(data.message || "INTERNAL_SERVER_ERROR");
  }

  return data;
}
