import { noop } from "lodash";
import React, { useCallback, useContext, useState } from "react";

import { getTestShippingLabelV2 } from "../api/settings";
import {
  EResponseStatus,
  IRetailer,
  ITestShippingLabelResponse,
  ITmsAddresses,
} from "../models";
import { NotificationContext } from "./NotificationContext";

interface ITmsConfigurationContext {
  shippingLabel: ITestShippingLabelResponse | null;
  onTestShippingLabel: (addresses: ITmsAddresses) => void;
}

export const TmsConfigurationContext =
  React.createContext<ITmsConfigurationContext>({
    shippingLabel: null,
    onTestShippingLabel: noop,
  });

interface ITmsConfigurationContextStoreProps {
  selectedRetailer: IRetailer | undefined;
}

export const TmsConfigurationContextStore: React.FC<
  ITmsConfigurationContextStoreProps
> = ({ selectedRetailer, children }) => {
  const [shippingLabel, setShippingLabel] =
    useState<ITestShippingLabelResponse | null>(null);

  const { setErrorNotification } = useContext(NotificationContext);
  const onTestShippingLabel = useCallback(
    (addresses: ITmsAddresses) => {
      if (selectedRetailer) {
        getTestShippingLabelV2(addresses, selectedRetailer.retailer_uuid)
          .then((shippingLabelResponse) => {
            if (shippingLabelResponse.status !== EResponseStatus.OK) {
              setErrorNotification(
                JSON.stringify(
                  shippingLabelResponse.details.error_details,
                  null,
                  2
                )
              );
            } else {
              setShippingLabel(shippingLabelResponse);
            }
          })
          .catch((e) => setErrorNotification(e.message));
      } else {
        setErrorNotification(
          "Cannot find the retailer to generate the test shipping label"
        );
      }
    },
    [selectedRetailer, setShippingLabel, setErrorNotification]
  );

  return (
    <TmsConfigurationContext.Provider
      value={{
        shippingLabel,
        onTestShippingLabel,
      }}
    >
      {children}
    </TmsConfigurationContext.Provider>
  );
};
