import React, { useCallback } from "react";
import classNames from "classnames";
import { Button } from "../../../Button/Button";
import { IPricingPlanCard } from "../../PricingPlan";
import { EMonthFrequency } from "../../../../constants";
import { EBillingPlan } from "../../../../models/retailer";

import "./PricingPlanCard.scss";

interface IPricingPlanCardProps {
  pricingPlan: IPricingPlanCard;
  handleClick?: (billingPlanId: EBillingPlan) => void;
  isBestPlan?: boolean;
  isDisabled?: boolean;
  monthFrequency: EMonthFrequency;
}

const getYearlyPrice = (price: number) => price * 12;

export const PricingPlanCard: React.FC<IPricingPlanCardProps> = ({
  pricingPlan,
  handleClick,
  isBestPlan,
  isDisabled,
  monthFrequency,
}) => {
  const onClick = useCallback(() => {
    if (!handleClick) {
      return;
    }

    handleClick?.(pricingPlan.id);
  }, [handleClick, pricingPlan.id]);

  return (
    <div className="pricing-plan-card">
      {isBestPlan && (
        <div className="pricing-plan-card__badge">Best for you</div>
      )}
      <h2 className="pricing-plan-card__title">{pricingPlan.title}</h2>

      <div className="pricing-plan-card__starting-at">Starting at</div>

      <div className="pricing-plan-card__price">
        <div className="pricing-plan-card__price-number">
          $
          {monthFrequency === EMonthFrequency.MONTHLY
            ? pricingPlan.price
            : getYearlyPrice(pricingPlan.price).toLocaleString()}
        </div>
        <p className="pricing-plan-card__price-small">
          {monthFrequency === EMonthFrequency.MONTHLY && "/mo"}
          {monthFrequency === EMonthFrequency.ANNUALY && "/yr"}
        </p>
      </div>

      <div className="pricing-plan-card__returns">
        {pricingPlan.returnsPerYear()}
      </div>

      <Button
        label="Get started"
        className={classNames("pricing-plan-card__button", {
          "pricing-plan-card__button_highlighted": isBestPlan,
        })}
        disabled={isDisabled}
        onClick={onClick}
      />
    </div>
  );
};
