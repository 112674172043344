import React from "react";
import Modal from "antd/es/modal";
import { Button } from "../Button/Button";
import { WarningOutlined } from "@ant-design/icons";
import "./IntegrationErrorModal.scss";

export interface IIntegrationError {
  tryAgain: () => Promise<unknown>;
}

interface IIntegrationErrorModalProps {
  onClose: () => void;
  integrationError?: IIntegrationError;
}

export const IntegrationErrorModal: React.FC<IIntegrationErrorModalProps> = ({
  onClose,
  integrationError,
}) => {
  return (
    <Modal open={Boolean(integrationError)} onCancel={onClose} footer={null}>
      <div className="integration-error-modal">
        <WarningOutlined className="integration-error-modal__warning-icon" />
        <div className="integration-error-modal__title">
          Something went wrong!
        </div>
        <div className="integration-error-modal__explanation">
          We could not complete the request towards Shopify. Please try again.
        </div>
        <Button
          className="integration-error-modal__button"
          onClick={() => {
            onClose();
            integrationError?.tryAgain();
          }}
          label="Try again"
        />
        <div className="integration-error-modal__note">
          If the problem persist please reach out to
          <br />
          <a href="mailto:support@yayloh.com">support@yayloh.com</a>
        </div>
      </div>
    </Modal>
  );
};
