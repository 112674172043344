import React from "react";
import { Summary } from "../../../Summary/Summary";
import { ILabelsSaved } from "../../../../models";
import shippingLabelSource from "../../../../images/shipping-label.png";
import { RevenueSummary } from "../../../RevenueSummary/RevenueSummary";
import classNames from "classnames";

import "./LabelsSaved.scss";

interface ILabelsSavedProps {
  labelsSaved: ILabelsSaved;
  className?: string;
}

export const LabelsSaved: React.FC<ILabelsSavedProps> = ({
  labelsSaved,
  className,
}) => {
  return (
    <div className={classNames("labels-saved", className)}>
      <Summary
        cta="You did it! 🥳"
        icon={<i className="demo-icon icon-leaf">&#xe822;</i>}
        title={
          <>
            <b>{labelsSaved.label_generation_coverage_percent}%</b> of your
            paper labels <b>saved</b>
          </>
        }
        text="Amet minim most return 16.02.2023 t non deserunt ullamco est sit aliqua dolor do amet sint. Velit 22.02.2023 consequat duis enim velit mollit."
      />

      <div className="labels-saved__rate">
        <div className="labels-saved__rate-content">
          <div className="labels-saved__rate-content-text">
            Cras at ligula non quam vestibulum laoreet eget in augue. Donec
            interdum pretium quam et tincidunt.
          </div>

          <div className="labels-saved__rate-content-flex">
            <RevenueSummary
              revenue={Number(labelsSaved.return_label_generated_by_yayloh)}
              pastRevenue={Number(
                labelsSaved.last_return_label_generated_by_yayloh
              )}
            />

            <div className="labels-saved__rate-content-italic-text">
              pre-printed labels were avoided because you are generating labels
              on demand with yayloh.
            </div>
          </div>
        </div>

        <img src={shippingLabelSource} alt="Shipping label" />
      </div>
    </div>
  );
};
