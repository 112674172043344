import "./SignUp.scss";

import uniqueId from "lodash-es/uniqueId";
import snakeCase from "lodash-es/snakeCase";
import noop from "lodash-es/noop";
import React, {
  createContext,
  FormEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { saveCompletedStep, saveCurrentStep } from "../../../api/onboarding";
import { getReasons } from "../../../api/settings";
import { UserContext } from "../../../context/UserContext";
import { EStep, IReason } from "../../../models";
import { onReturnReasonsSave } from "../../../utils";
import { Loader } from "../../Loader/Loader";
import { SetupOpenContext } from "../../../context/SetupOpenContext";

const SetupPageLazy = React.lazy(
  () => import(/* webpackChunkName: "SetupPage" */ "./SetupPage/SetupPage")
);
const SignUpDrfTemplateLazy = React.lazy(
  () =>
    import(
      /* webpackChunkName: "SignUpDrfTemplate" */ "./SignUpDrfTemplate/SignUpDrfTemplate"
    )
);
const SignUpEmailTemplateLazy = React.lazy(
  () =>
    import(
      /* webpackChunkName: "SignUpEmailTemplate" */ "./SignUpEmailTemplate/SignUpEmailTemplate"
    )
);
const SignUpErrorLazy = React.lazy(
  () =>
    import(/* webpackChunkName: "SignUpError" */ "./SignUpError/SignUpError")
);
const SignUpIntroLazy = React.lazy(
  () =>
    import(/* webpackChunkName: "SignUpIntro" */ "./SignUpIntro/SignUpIntro")
);
const SignUpReturnReasonsLazy = React.lazy(
  () =>
    import(
      /* webpackChunkName: "SignUpReturnReasons" */ "./SignUpReturnReasons/SignUpReturnReasons"
    )
);
const SignUpWelcomeLazy = React.lazy(
  () =>
    import(
      /* webpackChunkName: "SignUpWelcome" */ "./SignUpWelcome/SignUpWelcome"
    )
);
const SignUpWarehouseLazy = React.lazy(
  () =>
    import(
      /* webpackChunkName: "SignUpWarehouse" */ "./SignUpWarehouse/SignUpWarehouse"
    )
);
const ShippingCarriersLazy = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ShippingCarriers" */ "./ShippingCarriers/ShippingCarriers"
    )
);
const SignUpPricingPlanLazy = React.lazy(
  () =>
    import(
      /* webpackChunkName: "SignUpPricingPlan" */ "./SignUpPricingPlan/SignUpPricingPlan"
    )
);

function transformReason(reason: IReason) {
  if (!reason.reason_code) {
    return {
      ...reason,
      reason_code: reason.reason_code || snakeCase(reason.reason_text),
    };
  }
  return reason;
}

export const ORDER = [
  EStep.INTRO,
  EStep.PRICING_PLAN,
  EStep.SETUP_PAGE,
  EStep.DRF_TEMPLATE,
  EStep.EMAIL_TEMPLATE,
  EStep.RETURN_REASONS,
  EStep.WAREHOUSE,
  EStep.SHIPPING_CARRIERS,
];

interface ISignUpContextProps {
  step: EStep;
  totalSteps: number;
  genericUserId: number;
  retailerUuid: string | null;
  retailerName: string | null;
  setIsLoading: (isLoading: boolean) => void;
  goNext: () => void;
  goBack: () => void;
  skip: () => void;
  goToStart: () => void;
  goToLastVisitedStep: () => void;
  onError: (error: Error) => void;
  reasons: IReason[];
  deleteReason: (reasonId: string | number | undefined) => void;
  addReason: () => void;
  updateReason: (
    reasonId: string | number | undefined,
    reasonText: string
  ) => void;
  saveReason: (e: FormEvent<HTMLFormElement>) => void;
}

export const SignUpContext = createContext<ISignUpContextProps>({
  step: ORDER[0],
  genericUserId: -1,
  totalSteps: 0,
  retailerUuid: null,
  retailerName: null,
  setIsLoading: noop,
  goNext: noop,
  goBack: noop,
  skip: noop,
  goToStart: noop,
  goToLastVisitedStep: noop,
  onError: noop,
  reasons: [],
  deleteReason: noop,
  addReason: noop,
  updateReason: noop,
  saveReason: noop,
});

interface ISignUpProps {
  retailerUuid?: string;
  retailerName?: string;
  genericUserId?: number;
}

export const SignUp: React.FC<ISignUpProps> = ({
  retailerUuid: retailerUuidFromProps,
  retailerName: retailerNameFromProps,
  genericUserId: genericUserIdFromProps,
}) => {
  const {
    currentRetailerUuid,
    availableRetailers,
    genericUserId: genericUserIdFromContext,
    onboardingProgress,
    setOnboardingProgress,
    hasCompleteBtnClicked,
    setHasCompleteBtnClicked,
  } = useContext(UserContext);

  const { setHasSetupOpened } = useContext(SetupOpenContext);
  const [step, setStep] = useState(
    onboardingProgress?.last_visited_step || ORDER[0]
  );
  const [isLoading, setIsLoading] = useState(false);
  const [retailerUuid, setRetailerUuid] = useState<string | null>(null);
  const [retailerName, setRetailerName] = useState<string | null>(null);
  const [genericUserId, setGenericUserId] = useState<number>(-1);
  const totalSteps = ORDER.length;

  useEffect(() => {
    setRetailerUuid(retailerUuidFromProps || currentRetailerUuid);
    const retailer = availableRetailers?.find(
      (x) => x.retailer_uuid === currentRetailerUuid
    );
    setRetailerName(retailerNameFromProps || retailer?.name || "");
    setGenericUserId(genericUserIdFromProps ?? genericUserIdFromContext);
  }, [
    setRetailerUuid,
    setRetailerName,
    setGenericUserId,
    genericUserIdFromContext,
    availableRetailers,
    retailerUuidFromProps,
    retailerNameFromProps,
    genericUserIdFromProps,
    currentRetailerUuid,
  ]);

  useEffect(() => {
    const firstNotCompletedStep = ORDER.find(
      (x) => !onboardingProgress?.completed_steps.includes(x)
    );
    if (hasCompleteBtnClicked && firstNotCompletedStep) {
      setStep(firstNotCompletedStep);
      setHasCompleteBtnClicked(false);
    }
  }, [hasCompleteBtnClicked, setHasCompleteBtnClicked, onboardingProgress]);

  const goNext = useCallback(async () => {
    setIsLoading(true);
    try {
      const onboardingProgress = await saveCompletedStep(step);
      const isDone =
        ORDER.every((x) => onboardingProgress.completed_steps.includes(x)) ||
        false;
      if (isDone) {
        setStep(EStep.WELCOME);
      } else {
        const nextStepIndex = ORDER.indexOf(step) + 1;
        if (nextStepIndex > ORDER.length - 1) {
          setHasSetupOpened(false);
        } else {
          setStep(ORDER[nextStepIndex]);
        }
      }
      setOnboardingProgress(onboardingProgress);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  }, [setStep, step, setOnboardingProgress, setHasSetupOpened]);

  const skip = useCallback(() => {
    const nextStepIndex = ORDER.indexOf(step) + 1;
    if (nextStepIndex > ORDER.length - 1) {
      setHasSetupOpened(false);
    } else {
      setStep(ORDER[nextStepIndex]);
    }
  }, [setStep, step, setHasSetupOpened]);

  const goBack = useCallback(() => {
    const prevStepIndex = Math.max(ORDER.indexOf(step) - 1, 0);
    setStep(ORDER[prevStepIndex]);
  }, [setStep, step]);

  const goToStart = useCallback(() => {
    setStep(EStep.INTRO);
  }, [setStep]);

  const goToLastVisitedStep = useCallback(() => {
    setStep(onboardingProgress?.last_visited_step || ORDER[0]);
  }, [setStep, onboardingProgress]);

  useEffect(() => {
    if (step !== EStep.ERROR && step !== EStep.WELCOME) {
      saveCurrentStep(step).then(setOnboardingProgress);
    }
  }, [step, setOnboardingProgress]);

  const onError = useCallback(
    (error) => {
      setStep(EStep.ERROR);
      console.error(error);
    },
    [setStep]
  );

  const [reasons, setReasons] = useState<IReason[]>([]);
  const [originalReasons, setOriginalReasons] = useState<IReason[]>([]);

  useEffect(() => {
    getReasons().then((reasons) => {
      setReasons(reasons.map(transformReason));
      setOriginalReasons(reasons);
    });
  }, [setReasons, setOriginalReasons]);

  const deleteReason = useCallback(
    (reasonId: string | number | undefined) => {
      setReasons(
        reasons.map((reason) => {
          if (reason.reason_id === reasonId) {
            return {
              ...reason,
              is_active: false,
            };
          }
          return reason;
        })
      );
    },
    [reasons, setReasons]
  );

  const addReason = useCallback(() => {
    setReasons((prevReasons) => [
      ...prevReasons,
      {
        reason_id: uniqueId("reason_"),
        reason_text: "",
        is_active: true,
        ordering: prevReasons.length,
        reason_code: "",
      },
    ]);
  }, [setReasons]);

  const updateReason = useCallback(
    (reasonId: string | number | undefined, reasonText: string) => {
      setReasons(
        reasons.map((x) =>
          x.reason_id === reasonId
            ? { ...x, reason_id: reasonId, reason_text: reasonText }
            : x
        )
      );
    },
    [reasons, setReasons]
  );

  const saveReason = useCallback(
    (e) => {
      e.preventDefault();
      if (retailerUuid !== null && genericUserId !== -1) {
        setIsLoading(true);
        onReturnReasonsSave(reasons.map(transformReason), originalReasons)
          .then((result) => {
            if (!result) {
              setIsLoading(false);
              return;
            }
            setOriginalReasons(result.returnReasons);
            setIsLoading(false);
            goNext();
          })
          .catch((e) => {
            console.error(e);
            setIsLoading(false);
            onError(e);
          });
      }
    },
    [
      retailerUuid,
      genericUserId,
      setIsLoading,
      reasons,
      originalReasons,
      setOriginalReasons,
      goNext,
      onError,
    ]
  );

  return (
    <div className="sign-up">
      <div className="sign-up__content" onClick={(e) => e.stopPropagation()}>
        <SignUpContext.Provider
          value={{
            step,
            totalSteps,
            retailerUuid,
            retailerName,
            genericUserId,
            setIsLoading,
            goNext,
            goBack,
            skip,
            goToStart,
            goToLastVisitedStep,
            onError,

            reasons,
            addReason,
            deleteReason,
            updateReason,
            saveReason,
          }}
        >
          <Loader isLoading={isLoading}>
            {step === EStep.INTRO && <SignUpIntroLazy />}
            {step === EStep.PRICING_PLAN && <SignUpPricingPlanLazy />}
            {step === EStep.SETUP_PAGE && <SetupPageLazy />}
            {step === EStep.DRF_TEMPLATE && <SignUpDrfTemplateLazy />}
            {step === EStep.EMAIL_TEMPLATE && <SignUpEmailTemplateLazy />}
            {step === EStep.RETURN_REASONS && <SignUpReturnReasonsLazy />}
            {step === EStep.WAREHOUSE && <SignUpWarehouseLazy />}
            {step === EStep.SHIPPING_CARRIERS && <ShippingCarriersLazy />}
            {step === EStep.WELCOME && <SignUpWelcomeLazy />}
            {step === EStep.ERROR && <SignUpErrorLazy />}
          </Loader>
        </SignUpContext.Provider>
      </div>
    </div>
  );
};

export default SignUp;
