import React, { useState, useCallback, useContext } from "react";
import "./ResendShippingLabel.scss";
import classNames from "classnames";
import { resendShippingLabel } from "../../api/returnManagement";
import { NotificationContext } from "../../context/NotificationContext";

interface IResendShippingLabelProps {
  orderId: number;
  orderShippingLabelId: number;
}

export function ResendShippingLabel(props: IResendShippingLabelProps) {
  const [wasResend, setWasResend] = useState(false);
  const { setErrorNotification, setSuccessNotification } =
    useContext(NotificationContext);
  const handleResend = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation();
      resendShippingLabel(props.orderId, props.orderShippingLabelId)
        .then(() => {
          setWasResend(true);
          setSuccessNotification(`Shipping label was resend.`);
        })
        .catch((error) => setErrorNotification(error.toString()));
    },
    [
      setWasResend,
      props.orderId,
      props.orderShippingLabelId,
      setSuccessNotification,
      setErrorNotification,
    ]
  );
  return (
    <div
      className={classNames("resend-shipping-label", {
        "resend-shipping-label_sent": wasResend,
      })}
      onClick={handleResend}
    >
      {wasResend ? "Success" : "Resend"}
    </div>
  );
}
