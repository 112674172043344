import React from "react";
import { PDFCard } from "../PDFCard/PDFCard";
import { ProductReturnRate } from "../ProductReturnRate/ProductReturnRate";
import { ITopReturnedCategories } from "../../../../models";

import "./TopReturnedCategories.scss";

interface ITopReturnedCategoriesProps {
  topReturnedCategories: ITopReturnedCategories;
}

export const TopReturnedCategories: React.FC<ITopReturnedCategoriesProps> = ({
  topReturnedCategories,
}) => {
  return (
    <PDFCard title="top 5 returned categories">
      <div className="top-returned-categories">
        {topReturnedCategories.categories.length <= 0 && <div>Empty</div>}

        {topReturnedCategories.categories.map((c) => {
          return (
            <ProductReturnRate
              key={c.name}
              name={c.name || "-"}
              percent={c.return_percent}
              whiteProgressBarBackground
            />
          );
        })}
      </div>
    </PDFCard>
  );
};
