import React, { useContext } from "react";
import { SideNavigation } from "../../../SideNavigation/SideNavigation";
import { ERoutes } from "../../../../routes";
import { PermissionsContext } from "../../../../context/PermissionsContext";
import { ROUTE_TO_REQUIRED_PERMISSION_MAP } from "../../../../constants";

export function SettingsNavigation() {
  const { getPermission } = useContext(PermissionsContext);

  return (
    <SideNavigation
      heading="Settings"
      routes={[
        {
          to: ERoutes.SETTINGS_BRAND,
          label: "Styles & banners",
          exact: true,
        },
        {
          to: ERoutes.SETTINGS_STORES_AND_WAREHOUSES,
          label: "Stores & warehouses",
        },
        {
          to: ERoutes.SETTINGS_EMAIL_TEMPLATES,
          label: "E-mail templates",
        },
        {
          to: ERoutes.SETTINGS_SMS_TEMPLATES,
          label: "SMS templates",
        },
        {
          to: ERoutes.SETTINGS_RETURNS,
          label: "Returns",
        },
        {
          to: ERoutes.SETTINGS_RETURN_REASONS,
          label: "Return reasons",
        },
        {
          to: ERoutes.SETTINGS_EXCHANGE,
          label: "Exchange",
        },
        {
          to: ERoutes.SETTINGS_SHIPPING_RULES,
          label: "Shipping rules",
        },
        {
          to: ERoutes.SETTINGS_TRANSLATIONS,
          label: "Translations",
        },
        {
          to: ERoutes.SETTINGS_IMPORTS,
          label: "Import",
        },
        {
          to: ERoutes.SETTINGS_INTEGRATIONS,
          label: "Integrations",
        },
        {
          to: ERoutes.SETTINGS_PRICING_PLAN,
          label: "Pricing plan",
        },
        {
          to: ERoutes.SETTINGS_SELF_TRANSLATION_V1,
          label: "Text & Translations V1",
        },
        {
          to: ERoutes.SETTINGS_SELF_TRANSLATION,
          label: "Text & Translations",
        },
        {
          to: ERoutes.SETTINGS_TMS_CONFIGURATION,
          label: "Return label",
        },
        {
          to: ERoutes.SETTINGS_API_KEYS,
          label: "Api keys",
        },
        {
          to: ERoutes.SETTINGS_CATEGORIES,
          label: "Categories",
        },
        {
          to: ERoutes.SETTINGS_BLOCKED_RETURNS,
          label: "Blocked returns",
        },
        {
          to: ERoutes.SETTINGS_AUTOMATION,
          label: "Automation",
        },
        {
          to: ERoutes.SETTINGS_REFUND_RULES,
          label: "Refund rules",
        },
        {
          to: ERoutes.SETTINGS_SEGMENTS,
          label: "Segments",
        },
      ].filter((x) => getPermission(ROUTE_TO_REQUIRED_PERMISSION_MAP[x.to]))}
    />
  );
}
