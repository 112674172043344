import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import isEqual from "lodash-es/isEqual";
import omit from "lodash-es/omit";

import {
  getRetailerFeatures,
  getUserFeaturesAccess,
  getUserPagesAccess,
  getUserPermissions,
} from "./api/access";
import { getFeatureAccess } from "./api/billing";
import { getOmsType } from "./api/retailer";
import { getReasons, updateReasons, updateReasonsOrder } from "./api/settings";
import { TDateRange } from "./components/DateRangePicker/DateRangePicker";
import { EReturnItemState } from "./components/pages/ReturnManagement/ReturnItem/types";
import operationIcon from "./images/icons/ico_operation.svg";
import retentionIcon from "./images/icons/ico_retention.svg";
import returnsIcon from "./images/icons/ico_returns.svg";
import financialIcon from "./images/icons/ico_financial.svg";
import {
  ALL_REVIEWS_ID,
  CURRENCY_CODE,
  ESearchEntity,
  FORMAT,
  ROUTE_TO_REQUIRED_PERMISSION_MAP,
  ROUTES_PRIORITY,
  UTC_API_FORMAT,
  EFeatures,
  EImpactAreaType,
  ERefundMethodCode,
} from "./constants";
import {
  createGetPermission,
  DEFAULT_PERMISSIONS,
} from "./context/PermissionsContext";
import { setGenericUserId } from "./entities/GenericUserId";
import {
  EResponseStatus,
  IAutoCompleteSuggestion,
  IChannelsParams,
  ICustomerSearchResult,
  IFeature,
  IItem,
  IOrderSearchResult,
  IPermissions,
  IProductSearchResult,
  IReason,
  IRefundMethod,
  IRetailer,
  IReturnProductFromBackend,
  ITrackingResponseStatus,
} from "./models";
import { ERoutes } from "./routes";
import { ALL_OPTION } from "./context/InsightsPageContext";

dayjs.extend(utc);

export function isEqualDates(
  dateA: Dayjs | undefined,
  dateB: Dayjs | undefined
): boolean {
  return dayjs(dateA).format(FORMAT) === dayjs(dateB).format(FORMAT);
}

export function calculatePercentage(p: IReturnProductFromBackend) {
  if (p.ordered_qty === 0) {
    return 0;
  }
  return Math.round((100 * p.returned_qty) / p.ordered_qty);
}

export function commonCalculatePercentage(
  ordered_qty: number,
  returned_qty: number
) {
  if (ordered_qty === 0) {
    return 0;
  }
  return Math.round((100 * returned_qty) / ordered_qty);
}

export function transformSortOrder(order: string | undefined) {
  return order === "descend" ? "desc" : "asc";
}

export function isValidJson(jsonString: string) {
  try {
    JSON.parse(jsonString);
    return true;
  } catch (e) {
    return false;
  }
}

export function dateRangeToParams(dateRange: TDateRange | undefined) {
  return {
    start: dateRange?.[0] && dateRange[0].format(UTC_API_FORMAT),
    end: dateRange?.[1] && dateRange[1].format(UTC_API_FORMAT),
  };
}

export function getInitials(name: string | null) {
  if (name === null || name === "") {
    return "X";
  }
  try {
    return name
      .trim()
      .split(" ")
      .map((x) => x[0].toUpperCase())
      .join("");
  } catch (e) {
    return "X";
  }
}
function permissionReducer(acc: Record<string, boolean>, permission: IFeature) {
  return {
    ...acc,
    [permission.code]: permission.mapping_id !== null,
  };
}

export function handleLogin(
  generic_user_id: number,
  retailerUuid: IRetailer | undefined
): Promise<IPermissions> {
  setGenericUserId(generic_user_id);
  return Promise.all([
    getUserPagesAccess(generic_user_id),
    getUserFeaturesAccess(generic_user_id),
    retailerUuid
      ? getRetailerFeatures(retailerUuid.retailer_id)
      : Promise.resolve([]),
    retailerUuid
      ? getUserPermissions(retailerUuid.retailer_id)
      : Promise.resolve([]),
    getFeatureAccess(EFeatures.PLAN).catch((e) => {
      console.error(e);
      return undefined;
    }),
    getOmsType(),
  ]).then(
    ([pages, features, retailerFeatures, userPermissions, billing, oms]) => {
      return {
        pages: {
          ...DEFAULT_PERMISSIONS.pages,
          ...pages.reduce(permissionReducer, {}),
        },
        features: {
          ...DEFAULT_PERMISSIONS.features,
          ...features.reduce(permissionReducer, {}),
          ...retailerFeatures.reduce(permissionReducer, {}),
        },
        roles: {
          isRetailerAdmin: userPermissions.some(
            (x) => x.generic_user_id === generic_user_id
          ),
          isShopifyRetailer: oms?.oms_integration_type === "SHOPIFY",
        },
        shopify: {
          isBillingActive: Boolean(billing?.details.feature?.has_access),
        },
      };
    }
  );
}

export function decodeJwt(jwt: string): Record<string, any> {
  var base64Url = jwt.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

function getFirstAccessibleRoute(
  permissions: IPermissions,
  isSuperadmin: boolean
) {
  const getPermission = createGetPermission(permissions, isSuperadmin);
  return ROUTES_PRIORITY.filter((route) => {
    return getPermission(ROUTE_TO_REQUIRED_PERMISSION_MAP[route]);
  });
}

function getAccessibleRoute(
  initialUrl: string,
  accessibleRoutes: string[],
  loggedInUserRoutes: string[],
  loginRoutes: string[]
) {
  if (
    !accessibleRoutes.includes(initialUrl) &&
    loggedInUserRoutes.includes(initialUrl)
  ) {
    return ERoutes.FORBIDDEN;
  }
  if (!loginRoutes.includes(initialUrl)) {
    return initialUrl;
  }
  switch (initialUrl) {
    case ERoutes.REDIRECT_SETTINGS_BILLING:
      return ERoutes.SETTINGS_PRICING_PLAN;
    case ERoutes.REDIRECT_INTEGRATION_ZENDESK:
      return ERoutes.SETTINGS_INTEGRATIONS;
    default:
      return ERoutes.HOMEPAGE;
  }
}

const LOGIN_ROUTES = [
  ERoutes.LOGIN,
  ERoutes.REDIRECT_SHOPIFY,
  ERoutes.REDIRECT_SETTINGS_BILLING,
  ERoutes.REDIRECT_BILLING,
  ERoutes.REDIRECT_INTEGRATION_ZENDESK,
  ERoutes.PDF_TEMPLATE,
] as string[];

export function getRedirectRoute({
  userPermissions,
  isSuperadmin,
  initialUrl,
}: {
  userPermissions: IPermissions;
  isSuperadmin: boolean;
  initialUrl: string;
}): string {
  const accessibleRoutes: string[] = getFirstAccessibleRoute(
    userPermissions,
    isSuperadmin
  );
  // NOTE: update in case of dynamic routes

  const loggedInUserRoutes = Object.values(
    ERoutes as Record<string, string>
  ).filter((x) => !LOGIN_ROUTES.includes(x));
  const route = getAccessibleRoute(
    initialUrl,
    accessibleRoutes,
    loggedInUserRoutes,
    LOGIN_ROUTES
  );

  return route || ERoutes.HOMEPAGE;
}

export function getRandomColor() {
  const x = Math.floor(Math.random() * 256);
  const y = Math.floor(Math.random() * 256);
  const z = Math.floor(Math.random() * 256);
  return "rgb(" + x + "," + y + "," + z + ")";
}

export function customerResultToSuggestion(
  x: ICustomerSearchResult
): IAutoCompleteSuggestion {
  return {
    value: x.email,
    label: x.name,
    type: ESearchEntity.CUSTOMER,
  };
}

export function orderResultToSuggestion(
  x: IOrderSearchResult
): IAutoCompleteSuggestion {
  return {
    status: x.current_status,
    value: x.ext_order_id,
    label: x.ext_order_id,
    type: ESearchEntity.ORDER,
  };
}

export function productResultToSuggestion(
  x: IProductSearchResult
): IAutoCompleteSuggestion {
  return {
    value: x.product_name,
    label: x.product_name,
    type: ESearchEntity.PRODUCT,
  };
}

export function toLocalDate(date: Dayjs | Date | string) {
  return dayjs.utc(date).local();
}

export function getIsSuperAdmin(email: string) {
  return (process.env.REACT_APP_SUPERADMIN || "")
    .split(" ")
    .some((x) => x === email || email.split("@")[1] === x);
}

export function getCurrencySign(currencyCode: string) {
  return CURRENCY_CODE[currencyCode] || currencyCode;
}

export function transformHtml(escapedHtml: string) {
  if (escapedHtml[0] === '"') {
    escapedHtml = escapedHtml.substr(1);
  }
  if (escapedHtml[escapedHtml.length - 1] === '"') {
    escapedHtml = escapedHtml.substr(0, escapedHtml.length - 1);
  }
  return escapedHtml
    .replace(/\\"/g, '"')
    .replace(/\\n/g, "\n")
    .replace(/\\t/g, "\t")
    .replace(/{{/g, "{")
    .replace(/}}/g, "}");
}

export function prepareHtmlForServer(html: string, placeholders: string[]) {
  let withDoubledCurlyBraces = html.replace(/{/g, "{{").replace(/}/g, "}}");

  placeholders.forEach((placeholder) => {
    const re = new RegExp(`{{${placeholder}}}`, "g");
    withDoubledCurlyBraces = withDoubledCurlyBraces.replace(
      re,
      `{${placeholder}}`
    );
  });

  return withDoubledCurlyBraces;
}

export function hasProductReturned(product: IItem) {
  return product.reason_id !== null;
}

export function getLabelForState(state: EReturnItemState) {
  switch (state) {
    case EReturnItemState.OPENED: {
      return "Open";
    }
    case EReturnItemState.IN_PROGRESS: {
      return "In-progress";
    }
    case EReturnItemState.PROCESSED: {
      return "Processed";
    }
  }
}

const RETURN_REASON_FIELDS_TO_OMIT = ["reason_id", "ordering"];
export async function onReturnReasonsSave(
  updatedReturnReasons: IReason[],
  originalReturnReasons: IReason[]
) {
  if (!updatedReturnReasons) {
    return undefined;
  }
  return await Promise.all(
    updatedReturnReasons
      .filter((x) => {
        const originalReason = originalReturnReasons.find(
          (reason) => reason.reason_id === x.reason_id
        );
        return x.reason_id === -1 || !isEqual(x, originalReason);
      })
      .map((x) => updateReasons(x))
  )
    .then(() => getReasons())
    .then(async (returnReasons) => {
      const ordering = returnReasons.reduce((acc, returnReason, i) => {
        if (!returnReasons) {
          return {};
        }
        const index = updatedReturnReasons.findIndex(
          (x) =>
            x.reason_id === String(returnReason.reason_id) ||
            isEqual(
              omit(x, RETURN_REASON_FIELDS_TO_OMIT),
              omit(returnReason, RETURN_REASON_FIELDS_TO_OMIT)
            )
        );
        return {
          ...acc,
          [String(returnReason.reason_id)]: index + 1,
        };
      }, {});

      await updateReasonsOrder(ordering);
      const orderedReturnReasons = await getReasons();
      const newOrder = orderedReturnReasons.map((x) => String(x.reason_id));
      return {
        returnReasons: orderedReturnReasons,
        order: newOrder,
      };
    });
}

export const getIsTrackingStatusOk = (
  trackingStatus: ITrackingResponseStatus | undefined
) => {
  return trackingStatus && trackingStatus.status === EResponseStatus.OK;
};

export const getTotalReviewsText = (totalReviews: number | undefined) => {
  if (totalReviews === ALL_REVIEWS_ID) {
    return "All";
  }

  return totalReviews === 1
    ? `${totalReviews} review`
    : `${totalReviews} reviews`;
};

export const downloadCsv = (csv: string, filename: string) => {
  const csvFile = new Blob([csv], { type: "text/csv" });
  const downloadLink = document.createElement("a");
  downloadLink.download = filename;
  downloadLink.href = window.URL.createObjectURL(csvFile);
  downloadLink.style.display = "none";
  document.body.appendChild(downloadLink);
  downloadLink.click();
};

export const getDatasetImpactAreaMetadata = (
  datasetCategory: EImpactAreaType
) => {
  switch (datasetCategory) {
    case EImpactAreaType.OPERATION:
      return {
        title: "Operations",
        image: operationIcon,
      };
    case EImpactAreaType.RETENTION:
      return {
        title: "Retention",
        image: retentionIcon,
      };
    case EImpactAreaType.RETURNS:
      return {
        title: "Reduce returns",
        image: returnsIcon,
      };
    case EImpactAreaType.FINANCIAL:
      return {
        title: "Financials",
        image: financialIcon,
      };
    default:
      return {
        title: "",
        image: "",
      };
  }
};

export function getRefundMethodIds(refundMethods: IRefundMethod[] | undefined) {
  return {
    refundMethodId: refundMethods?.find(
      (x) => x.code === ERefundMethodCode.REFUND_METHOD
    )?.refund_method_id,
    storeCreditMethodId: refundMethods?.find(
      (x) => x.code === ERefundMethodCode.STORE_CREDIT
    )?.refund_method_id,
  };
}

export const sendEmailToSupport = (subject: string) => {
  window.location.href = `mailto:support@yayloh.com?subject=${subject}`;
};

export function getSearchChannelsUrlParams(
  channels: IChannelsParams["channels"]
) {
  return new URLSearchParams({
    source_channels: channels.filter((x) => x !== ALL_OPTION.value).join(","),
  });
}
