import { Dayjs } from "dayjs";
import React from "react";
import { EVIPSettingsParam } from "../../../../../../../../../constants";
import { ISegmentWithParams } from "../../../../../../../../../models";
import { Parameters } from "./components/Parameters/Parameters";
import { Preview } from "./components/Preview/Preview";

import "./CurrentSettings.scss";

interface ICurrentSettingsProps {
  segmentSettings: ISegmentWithParams | undefined;
  originalSegmentSettings: ISegmentWithParams | undefined;
  handleSegmentSettingsChange: (
    value: string | number,
    name?: EVIPSettingsParam
  ) => void;
  handleSegmentSettingsUpdate: (e: React.FormEvent) => void;
  handleSegmentSettingsDateChange: (
    date: Dayjs | null,
    dateString: string
  ) => void;
  isUpdateDisabled: boolean;
}

export const CurrentSettings: React.FC<ICurrentSettingsProps> = ({
  segmentSettings,
  originalSegmentSettings,
  handleSegmentSettingsChange,
  handleSegmentSettingsUpdate,
  handleSegmentSettingsDateChange,
  isUpdateDisabled,
}) => {
  return (
    <div className="vip-current-settings">
      <Preview segmentSettings={originalSegmentSettings} />

      <Parameters
        segmentSettings={segmentSettings}
        handleSegmentSettingsChange={handleSegmentSettingsChange}
        handleSegmentSettingsUpdate={handleSegmentSettingsUpdate}
        handleSegmentSettingsDateChange={handleSegmentSettingsDateChange}
        isUpdateDisabled={isUpdateDisabled}
      />
    </div>
  );
};
