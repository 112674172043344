import { getHeaders } from "./getHeaders";

const BASE_URL = process.env.REACT_APP_BASE_URL;

// export interface IGetShippingLabelLinkResponse {
//   shipping_label_s3_url: string;
// }
// export function getShippingLabelLink(
//   order_shipping_label_id: number
// ): Promise<IGetShippingLabelLinkResponse> {
//   return fetch(
//     `${BASE_URL}/shipping/get_label/order_shipping_label_id/${order_shipping_label_id}/`,
//     {
//       method: "GET",
//       headers: getHeaders(),
//     }
//   ).then((res) => res.json());
// }

export interface IGetShippingCarriersResponse {
  shipping_carriers: string[];
}

export function getShippingCarriers(): Promise<IGetShippingCarriersResponse> {
  return fetch(`${BASE_URL}/order/shipping_info/`, {
    method: "GET",
    headers: getHeaders(),
  }).then((res) => res.json());
}

interface IChangeTrackingIdPayload {
  order_shipment_id: number | null;
  ext_tracking_id: string;
  shipping_carrier: string;
  order_id: number;
  country_code: string | null;
}

export function changeTrackingId(payload: IChangeTrackingIdPayload) {
  return fetch(`${BASE_URL}/order/shipping_info/`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(payload),
  }).then((res) => res.json());
}
