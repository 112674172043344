import React from "react";
import classNames from "classnames";

import "./HomepageCard.scss";
import { Loader } from "../../../../Loader/Loader";

interface IHomepageCardProps {
  title?: React.ReactNode;
  icon?: React.ReactNode;
  hasSmallSpacing?: boolean;
  helpLink?: string;
  className?: string;
  isLoading?: boolean;
  hasBrandHover?: boolean;
  brandHoverImageSource?: string;
}

export const HomepageCard: React.FC<IHomepageCardProps> = ({
  title,
  icon,
  hasSmallSpacing,
  helpLink,
  className,
  isLoading,
  children,
  hasBrandHover,
  brandHoverImageSource,
}) => {
  if (hasBrandHover && !brandHoverImageSource) {
    throw new Error(
      `HomepageCard with title "${title}" has no brandHoverImageSource prop `
    );
  }

  return (
    <div className={classNames("homepage-card", className)}>
      {icon && <div className="homepage-card__icon">{icon}</div>}

      {isLoading ? (
        <div className="homepage-card__loading">
          <Loader isLoading removeBackground />
        </div>
      ) : (
        <div className="homepage-card__content">
          {title && <p className="homepage-card__title">{title}</p>}

          <div
            className={classNames("homepage-card__content", {
              "homepage-card__content_margin":
                Boolean(title) && !hasSmallSpacing,
              "homepage-card__content_margin-small":
                Boolean(title) && hasSmallSpacing,
            })}
          >
            {children}
          </div>

          {helpLink && (
            <a
              className="homepage-card__help-link"
              href={helpLink}
              target="_blank"
              rel="noreferrer"
            >
              Check details
            </a>
          )}
        </div>
      )}

      {hasBrandHover && !isLoading && (
        <img
          className="homepage-card__brand-hover"
          src={brandHoverImageSource}
          alt="Brand hover"
        />
      )}
    </div>
  );
};
