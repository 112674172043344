import React from "react";

import "./Summary.scss";

interface ISummaryProps {
  title: React.ReactNode;
  text: React.ReactNode;
  icon: React.ReactNode;
  cta?: React.ReactNode;
  detailsHref?: string;
}

export const Summary: React.FC<ISummaryProps> = ({
  title,
  text,
  icon,
  cta,
  detailsHref,
}) => {
  return (
    <div className="summary">
      {cta && <div className="summary__cta">{cta}</div>}

      <div className="summary__main">
        <div className="summary__icon">{icon}</div>

        <div className="summary__content">
          <div className="summary__title">
            <p className="summary__title-text">{title}</p>
          </div>

          <div className="summary__text">{text}</div>

          {detailsHref && (
            <a
              className="summary__details-href"
              href={detailsHref}
              target="_blank"
              rel="noreferrer"
            >
              Check Details
            </a>
          )}
        </div>
      </div>
    </div>
  );
};
