import * as React from "react";
import "./Input.scss";
import classNames from "classnames";

interface IInputProps {
  placeholder?: string;
  value?: string | number;
  name?: string;
  className?: string;
  maxLength?: number;
  validationRules?: any[];
  inputMode?:
    | "text"
    | "email"
    | "none"
    | "tel"
    | "url"
    | "numeric"
    | "decimal"
    | "search"
    | undefined;
  onChange?: any;
  errors?: string[];
  label?: string;
}

interface IInputState {
  errors: string[];
}

export class Input extends React.Component<IInputProps, IInputState> {
  static defaultProps = {
    validationRules: [],
    errors: [],
  };

  state = {
    errors: [],
  };

  handleBlur = () => {
    this.setState({
      errors: this.props
        .validationRules!.map((x) => x(this.props.value))
        .filter((x) => Boolean(x)),
    });
  };

  handleFocus = () => {
    this.setState({
      errors: [],
    });
  };

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onChange?.(e.target.value, e.target.name);
  };

  render() {
    return (
      <div className={classNames("input", this.props.className)}>
        <label className="text-small text-secondary">
          {this.props.label}
          <input
            className={classNames("input__text", {
              input__text_error: this.state.errors.length > 0,
            })}
            placeholder={this.props.placeholder}
            inputMode={this.props.inputMode}
            value={this.props.value}
            name={this.props.name}
            maxLength={this.props.maxLength}
            onBlur={this.handleBlur}
            onFocus={this.handleFocus}
            onChange={this.handleChange}
          />
        </label>
        {this.state.errors.length > 0 && (
          <div className="input__error">{this.state.errors[0]}</div>
        )}
        {this.state.errors.length === 0 && this.props.errors!.length > 0 && (
          <div className="input__error">{this.props.errors![0]}</div>
        )}
      </div>
    );
  }
}
