import React, { useContext, useEffect, useState } from "react";
import { getShippingCarriers } from "../api/shipping";
import { UserContext } from "./UserContext";

export const OrdersContext = React.createContext({
  shippingCarriers: [] as string[],
});

export const OrdersContextStore: React.FC = ({ children }) => {
  const [shippingCarriers, setShippingCarriers] = useState<string[]>([]);
  const { genericUserId, currentRetailerUuid } = useContext(UserContext);

  useEffect(() => {
    if (genericUserId >= 0 && currentRetailerUuid) {
      getShippingCarriers().then(({ shipping_carriers }) => {
        setShippingCarriers(shipping_carriers);
      });
    }
  }, [setShippingCarriers, genericUserId, currentRetailerUuid]);
  return (
    <OrdersContext.Provider
      value={{
        shippingCarriers,
      }}
    >
      {children}
    </OrdersContext.Provider>
  );
};
