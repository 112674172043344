import { Table } from "antd";
import { ColumnProps } from "antd/lib/table";
import dayjs from "dayjs";
import React, { useCallback, useMemo } from "react";
import {
  EParamType,
  SW_CURRRENCY_CODE,
  UTC_FORMAT,
} from "../../../../../../../../../constants";
import { ISegmentEditHistory } from "../../../../../../../../../models";
import { FormattedPrice } from "../../../../../../../../FormattedPrice/FormattedPrice";

import "./EditHistory.scss";

interface IEditHistoryProps {
  editHistory: ISegmentEditHistory[];
}

export const EditHistory: React.FC<IEditHistoryProps> = ({ editHistory }) => {
  const handleRenderType = useCallback((type: EParamType) => {
    return (value: string | number) => {
      switch (type) {
        case EParamType.BIG_INTEGER:
          return (
            <FormattedPrice
              price={Number(value)}
              currency_code={SW_CURRRENCY_CODE}
            />
          );
        case EParamType.DATETIME:
          return dayjs(value).format(UTC_FORMAT);
        default:
          return <>{value}</>;
      }
    };
  }, []);

  const historyTableColumns = useMemo((): ColumnProps<
    Record<string, string>
  >[] => {
    if (!editHistory.length) {
      return [];
    }

    return [
      {
        title: "Edit date",
        dataIndex: "update_date",
        key: "update_date",
        render: (value) => {
          return dayjs(value).format(UTC_FORMAT);
        },
      },
      {
        title: "Edited by",
        dataIndex: "updated_by",
        key: "updated_by",
        render: (value) => {
          return value.email;
        },
      },
      {
        title: "Enabled",
        dataIndex: "enabled",
        key: "enabled",
        render: (isEnabled: boolean) => {
          return isEnabled ? "Enabled" : "Disabled";
        },
      },
      ...Object.entries(editHistory[0].params).map(
        ([paramName, paramValue]) => {
          return {
            title: paramValue.label,
            dataIndex: paramName,
            key: paramName,
            render: handleRenderType(paramValue.type),
          };
        }
      ),
    ];
  }, [editHistory, handleRenderType]);

  const historyTableData = useMemo(() => {
    return editHistory.map((history) => {
      const historyData = Object.entries(history.params).reduce(
        (
          acc: Record<string, string | number>,
          [paramName, paramValue]
        ): Record<string, string | number> => ({
          ...acc,
          [paramName]: paramValue.value,
        }),
        {}
      );

      return {
        ...historyData,
        updated_by: history.updated_by,
        update_date: history.update_date,
        enabled: history.enabled,
      };
    });
  }, [editHistory]);

  return (
    <div className="vip-edit-history">
      <h3 className="vip-edit-history__title">Previous versions</h3>

      <Table dataSource={historyTableData || []}>
        {historyTableColumns.map((column) => {
          return (
            <Table.Column
              title={column.title}
              dataIndex={column.key}
              key={column.key}
              render={column.render}
            />
          );
        })}
      </Table>
    </div>
  );
};
