export interface IRetailerApi {
  friendly_name: string;
  is_enabled: boolean;
  retailer_uuid: string;
}

export interface IRetailerGerResponse {
  retailer_api: IRetailerApi[];
  retailer_code: string;
  retailer_domain: string;
  retailer_name: string;
}

export interface IRetailerPostParams {
  code: string;
  name: string;
  domain: string;
  address: string;
  country_code: string;
  zipcode: string;
  city: string;
  friendly_name: string;
}

export interface IRetailerApiPostParams {
  is_enabled: boolean;
  friendly_name: string;
}

export interface ITicketRequestParams {
  integration_id: number;
  order_id: number;
}

export interface ITicketRequestStatus {
  message: string;
  status: string;
}

export enum EBillingPlan {
  STARTER = 1,
  GROWTH = 2,
  SCALE = 3,
}

export enum EBillingStatus {
  OK = "ok",
  ACTIVE = "active",
  DECLINED = "declined",
  PENDING = "pending",
  EXPIRED = "expired",
  CANCELLED = "cancelled",
}

export interface IOmsTypeResponse {
  oms_integration_type?: string;
  status?: string;
}

export interface IUploadOrderIdsAndTrackingIdsParams {
  order: string[];
  tracking: string[];
}

export interface IUploadResult {
  found: string[];
  not_found: string[];
}

export interface IUploadOrderIdsAndTrackingIdsResponse {
  order: IUploadResult;
  tracking: IUploadResult;
}
