import React, { useState } from "react";
import { useLocation } from "react-router-dom";

export const SetupOpenContext = React.createContext({
  hasSetupOpened: false,
  setHasSetupOpened: (hasSetupOpened: boolean) => {},
});

export const SetupOpenContextStore: React.FC = ({ children }) => {
  const location = useLocation();

  const [hasSetupOpened, setHasSetupOpened] = useState(
    new URLSearchParams(location.search).get("hasSetupOpened") === "true"
  );
  return (
    <SetupOpenContext.Provider
      value={{
        hasSetupOpened,
        setHasSetupOpened,
      }}
    >
      {children}
    </SetupOpenContext.Provider>
  );
};
