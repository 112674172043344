import React from "react";
import { ReturningCountryRate } from "./ReturningCountryRate/ReturningCountryRate";
import countriesBacgroundSrc from "../../../../../images/countries.svg";
import { ITopReturnedCountries } from "../../../../../models";
import { HomepageCard } from "../../components/HomepageCard/HomepageCard";

import "./TopReturningCountry.scss";

interface ITopReturningCountryProps {
  topReturnedCountries: ITopReturnedCountries | undefined;
  className?: string;
  isLoading?: boolean;
}

export const TopReturningCountry = ({
  topReturnedCountries,
  className,
  isLoading,
}: ITopReturningCountryProps) => {
  return (
    <HomepageCard
      className={className}
      title="Top 3 returning country"
      isLoading={isLoading}
    >
      <div className="top-returning-country">
        {topReturnedCountries?.countries.map((country) => {
          return (
            <ReturningCountryRate
              key={country.country_code}
              countryRate={country}
            />
          );
        })}
      </div>

      <img
        className="top-returning-country__background"
        src={countriesBacgroundSrc}
        alt="Countries"
      />
    </HomepageCard>
  );
};
