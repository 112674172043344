import React from "react";

import "./PDFSection.scss";
import { uniqueId } from "lodash";

interface IPDFSectionProps {
  title: string;
  index: string;
}

export const PDFSection: React.FC<IPDFSectionProps> = ({
  title,
  index,
  children,
}) => {
  return (
    <div className="pdf-section">
      <div className="pdf-section__title">
        <p className="pdf-section__index">{index}</p>

        <div className="pdf-section__text">
          {title.split("\\n").map((t) => (
            <p key={uniqueId(t)} className="pdf-section__paragraph">
              {t}
            </p>
          ))}
        </div>
      </div>

      <div className="pdf-section__content">{children}</div>
    </div>
  );
};
