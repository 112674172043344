import "./PricingPlan.scss";

import React, { useCallback, useContext, useEffect, useState } from "react";

import { addFeatureForRetailer, approvePendingCharge } from "../../api/billing";
import { getReturnedIOrderCount, INTEGRATION_ERROR } from "../../api/retailer";
import {
  API_ERROR,
  EMonthFrequency,
  PLAN_FEATURE_QUANTITY,
} from "../../constants";
import { NotificationContext } from "../../context/NotificationContext";
import { UserContext } from "../../context/UserContext";
import { EResponseStatus, IAddFeatureResponse } from "../../models";
import { EBillingPlan } from "../../models/retailer";
import { LoadingOverlay } from "../LoadingOverlay/LoadingOverlay";
import { PricingPlanCard } from "./components/PricingPlanCard/PricingPlanCard";
import { uniqueId } from "lodash";
import { Radio, RadioChangeEvent } from "antd";

interface IPricingPlanProps {
  isOnboarding?: boolean;
  goNext?: () => void;
}

export interface IPricingPlanCard {
  key: React.Key;
  id: EBillingPlan;
  title: string;
  price: number;
  yearlyReturns: number;
  returnsPerYear: () => string;
}

const PRICING_PLANS: IPricingPlanCard[] = [
  {
    key: uniqueId("pricing_plan_"),
    id: EBillingPlan.STARTER,
    title: "Start Bright",
    price: 90,
    yearlyReturns: 1000,
    returnsPerYear: function () {
      return `Up to ${this.yearlyReturns} returns/year`;
    },
  },
  {
    key: uniqueId("pricing_plan_"),
    id: EBillingPlan.GROWTH,
    title: "Grow Smarter",
    price: 250,
    yearlyReturns: 3000,
    returnsPerYear: function () {
      return `Up to ${this.yearlyReturns} returns/year`;
    },
  },
  {
    key: uniqueId("pricing_plan_"),
    id: EBillingPlan.SCALE,
    title: "Scale Faster",
    price: 490,
    yearlyReturns: 6000,
    returnsPerYear: function () {
      return `More than ${this.yearlyReturns} returns/year`;
    },
  },
];

export const PricingPlan: React.FC<IPricingPlanProps> = ({
  isOnboarding = false,
  goNext,
}) => {
  const { genericUserId, retailerBillingStatus } = useContext(UserContext);
  const { setErrorNotification } = useContext(NotificationContext);
  const [monthlyReturnCount, setMonthlyReturnCount] = useState<number>(0);
  const [featureDetails, setFeatureDetails] = useState<IAddFeatureResponse>();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedMonthFrequency, setSelectedMonthFrequency] = useState(
    EMonthFrequency.ANNUALY
  );

  const [currentBillingPlanId, setCurrentBillingPlanId] = useState<
    EBillingPlan | undefined
  >(undefined);

  useEffect(() => {
    setIsLoading(true);

    if (
      retailerBillingStatus?.details.feature?.has_access &&
      goNext !== undefined &&
      isOnboarding
    ) {
      goNext();
      return;
    }

    const [plan] = retailerBillingStatus
      ? retailerBillingStatus?.details.feature?.features || []
      : [];
    setCurrentBillingPlanId(plan?.id);

    getReturnedIOrderCount()
      .then((returnedOrderCount) => {
        setMonthlyReturnCount(returnedOrderCount.monthly_return_count);
      })
      .catch((err) => {
        console.error(err);
        setErrorNotification(API_ERROR);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [
    setMonthlyReturnCount,
    setErrorNotification,
    setIsLoading,
    goNext,
    isOnboarding,
    setCurrentBillingPlanId,
    retailerBillingStatus,
  ]);

  useEffect(() => {
    if (featureDetails) {
      approvePendingCharge(featureDetails.details.charge_id)
        .then((res) => {
          window.location.href = res.details.confirmation_url;
        })
        .catch((err) => {
          console.error(err);
          setErrorNotification(API_ERROR);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [featureDetails, setErrorNotification]);

  const getBestPlan = useCallback(
    (billingPlanId: EBillingPlan) => {
      if (
        currentBillingPlanId !== undefined &&
        currentBillingPlanId <= EBillingPlan.SCALE
      ) {
        return currentBillingPlanId === billingPlanId;
      }

      if (monthlyReturnCount === 0) {
        return billingPlanId === EBillingPlan.GROWTH;
      }

      const pricingPlan = PRICING_PLANS.find((pricingPlan) => pricingPlan.id);
      if (!pricingPlan) {
        return;
      }

      const monthlyReturns = pricingPlan.yearlyReturns / 12;

      switch (billingPlanId) {
        case EBillingPlan.SCALE: {
          return monthlyReturns >= monthlyReturnCount;
        }
        default: {
          return monthlyReturns <= monthlyReturnCount;
        }
      }
    },
    [monthlyReturnCount, currentBillingPlanId]
  );

  const handleClick = useCallback(
    async (billingPlanId: EBillingPlan) => {
      setIsLoading(true);
      // NOTE: Loading is set to true and should not be set to false until redirect

      addFeatureForRetailer({
        quantity: PLAN_FEATURE_QUANTITY,
        feature_id: billingPlanId,
        created_by: genericUserId,
        is_annually: selectedMonthFrequency === EMonthFrequency.ANNUALY,
      })
        .then((details) => {
          if (details.status === EResponseStatus.FAILED) {
            setErrorNotification(details.message);
            return;
          }
          setFeatureDetails(details);
        })
        .catch((err) => {
          console.error(err);
          setErrorNotification(INTEGRATION_ERROR);
        });
    },
    [
      setErrorNotification,
      setIsLoading,
      setFeatureDetails,
      genericUserId,
      selectedMonthFrequency,
    ]
  );

  const handleChangeMonthFrequency = useCallback(
    (e: RadioChangeEvent) => {
      setSelectedMonthFrequency(e.target.value);
    },
    [setSelectedMonthFrequency]
  );

  return (
    <div className="pricing-plan">
      <LoadingOverlay isLoading={isLoading} />

      <h1 className="pricing-plan__title">Choose pricing plan</h1>

      <Radio.Group
        className="pricing-plan__frequency"
        onChange={handleChangeMonthFrequency}
        value={selectedMonthFrequency}
        buttonStyle="solid"
      >
        <Radio.Button value={EMonthFrequency.ANNUALY}>Yearly</Radio.Button>
        <Radio.Button value={EMonthFrequency.MONTHLY}>Monthly</Radio.Button>
      </Radio.Group>

      <div className="pricing-plan__plans">
        {PRICING_PLANS.map((pricingPlan) => {
          return (
            <PricingPlanCard
              key={pricingPlan.key}
              pricingPlan={pricingPlan}
              handleClick={handleClick}
              isBestPlan={getBestPlan(pricingPlan.id)}
              monthFrequency={selectedMonthFrequency}
            />
          );
        })}
      </div>
    </div>
  );
};
