import { BASE_URL } from "../constants";
import { IOnboardingProgress } from "../models";
import { getHeaders } from "./getHeaders";

export function saveCompletedStep(step: string): Promise<IOnboardingProgress> {
  return fetch(`${BASE_URL}/setting/onboarding/step_completed/${step}/`, {
    method: "POST",
    headers: getHeaders(),
  }).then((res) => res.json());
}

export function saveCurrentStep(step: string): Promise<IOnboardingProgress> {
  return fetch(`${BASE_URL}/setting/onboarding/current_step/${step}/`, {
    method: "POST",
    headers: getHeaders(),
  }).then((res) => res.json());
}
export function getOnboardingProgress(): Promise<IOnboardingProgress> {
  return fetch(`${BASE_URL}/setting/onboarding/progress/`, {
    method: "GET",
    headers: getHeaders(),
  }).then((res) => res.json());
}

export function setSetupFinished(): Promise<IOnboardingProgress> {
  return fetch(`${BASE_URL}/setting/onboarding/setup_finished/`, {
    method: "POST",
    headers: getHeaders(),
    credentials: "include",
  }).then((res) => res.json());
}
