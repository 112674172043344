import { BASE_URL } from "../constants";
import { handleApiResponse } from "../helper/handleApiResponse";
import {
  IAddFeature,
  IAddFeatureResponse,
  IAvailableFeature,
  IBillingCharges,
  IChargeResponse,
  IFeatureAccessResponse,
  ICharge,
  IPendingCharges,
  IRecurringFeatures,
  IResponseStatus,
} from "../models";
import { ERoutes } from "../routes";

import { getHeaders } from "./getHeaders";

const getBillingHeaders = (retailerUuid?: string) => {
  return retailerUuid
    ? {
        ...getHeaders(),
        retailer_uuid: retailerUuid,
      }
    : getHeaders();
};

export function getOneTimeChargesHistory(
  selectedRetailerUuid?: string
): Promise<IBillingCharges[]> {
  return fetch(`${BASE_URL}/charge/one_time/history/`, {
    method: "GET",
    headers: getBillingHeaders(selectedRetailerUuid),
  }).then(handleApiResponse);
}

export function getRecurrentChargesHistory(
  selectedRetailerUuid?: string
): Promise<IBillingCharges[]> {
  return fetch(`${BASE_URL}/charge/recurring/history/`, {
    method: "GET",
    headers: getBillingHeaders(selectedRetailerUuid),
  }).then(handleApiResponse);
}

export function getCurrentRecurringFeatures(
  selectedRetailerUuid?: string
): Promise<IRecurringFeatures> {
  return fetch(`${BASE_URL}/feature/current/recurring/`, {
    method: "GET",
    headers: getBillingHeaders(selectedRetailerUuid),
  }).then(handleApiResponse);
}

export function getAvailableFeatures(
  selectedRetailerUuid?: string
): Promise<IAvailableFeature[]> {
  return fetch(`${BASE_URL}/feature/available/admin_page/`, {
    method: "GET",
    headers: getBillingHeaders(selectedRetailerUuid),
  }).then(handleApiResponse);
}

export function removeFeature(
  retailerFeatureId: number,
  selectedRetailerUuid?: string
): Promise<IResponseStatus> {
  return fetch(`${BASE_URL}/feature/remove/${retailerFeatureId}/`, {
    method: "DELETE",
    headers: getBillingHeaders(selectedRetailerUuid),
  }).then(handleApiResponse);
}

export function addFeatureAdminPage(
  newCharge: ICharge,
  selectedRetailerUuid?: string
): Promise<IResponseStatus> {
  return fetch(`${BASE_URL}/feature/add/admin_page/`, {
    method: "POST",
    headers: getBillingHeaders(selectedRetailerUuid),
    body: JSON.stringify(newCharge),
  }).then(handleApiResponse);
}

export function addFeatureForRetailer(
  feature: IAddFeature
): Promise<IAddFeatureResponse> {
  return fetch(`${BASE_URL}/feature/add/`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(feature),
  }).then(handleApiResponse);
}

export function getPendingCharges(): Promise<IPendingCharges> {
  return fetch(`${BASE_URL}/charge/pending/`, {
    method: "GET",
    headers: getHeaders(),
  }).then(handleApiResponse);
}

export function approvePendingCharge(
  chargeId: number
): Promise<IChargeResponse> {
  return fetch(`${BASE_URL}/charge/approve/`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      charge_id: chargeId,
      return_url: `${window.location.origin}${ERoutes.REDIRECT_BILLING}`,
    }),
  }).then(handleApiResponse);
}

export function rejectPendingCharge(
  chargeId: number
): Promise<IChargeResponse> {
  return fetch(`${BASE_URL}/charge/reject/`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({ charge_id: chargeId }),
  }).then(handleApiResponse);
}

export function getFeatureAccess(
  feature: string
): Promise<IFeatureAccessResponse> {
  return fetch(`${BASE_URL}/feature/type/${feature}/access/`, {
    method: "GET",
    headers: getHeaders(),
  }).then(handleApiResponse);
}

export function verifyIsChargeActive(
  chargeId: number
): Promise<IResponseStatus> {
  return fetch(`${BASE_URL}/charge/verify/`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({ ext_charge_id: chargeId }),
  }).then(handleApiResponse);
}
