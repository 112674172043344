export enum ERoutes {
  LOGIN = "/login",
  HOMEPAGE = "/",
  HOMEPAGE_OLD = "/old",
  RETURN_MANAGEMENT = "/return-management",
  RETURN_MANAGEMENT__CUSTOM_LIST = "/return-management/custom-list",
  IN_STORE_RETURN = "/in-store-return",

  INSIGHTS_OVERVIEW = "/insights",
  INSIGHTS_PRODUCT = "/insights/product",
  INSIGHTS_CATEGORY = "/insights/category",
  INSIGHTS_CATEGORY__categoryName = "/insights/category/:categoryName",
  INSIGHTS_BRAND = "/insights/brand",
  INSIGHTS_BRAND__brandName = "/insights/brand/:brandName",
  INSIGHTS_DATASETS = "/insights/datasets",
  INSIGHTS_DATASETS_SIMPLE__id = "/insights/datasets/simple/:id",
  INSIGHTS_DATASETS_GRAPH = "/insights/datasets/graph/:type/:id",
  INSIGHTS_COMMENTS = "/insights/comments",
  ADMIN_INSIGHTS = "/admin-insights",
  FEEDBACK = "/feedback",

  RESTOCKING = "/restocking",
  RETURNS_FORECAST = "/returns-forecast",
  SETTINGS_BRAND = "/settings",
  SETTINGS_STORES_AND_WAREHOUSES = "/settings/stores-and-warehouses",
  SETTINGS_EMAIL_TEMPLATES = "/settings/email-templates",
  SETTINGS_SMS_TEMPLATES = "/settings/sms-templates",
  SETTINGS_RETURN_REASONS = "/settings/return-reasons",
  SETTINGS_TRANSLATIONS = "/settings/translations",
  SETTINGS_SELF_TRANSLATION_V1 = "/settings/self-translations-v1",
  SETTINGS_SELF_TRANSLATION = "/settings/self-translations",
  SETTINGS_OTHER = "/settings/other",
  SETTINGS_IMPORTS = "/settings/imports",
  SETTINGS_INTEGRATIONS = "/settings/integrations",
  SETTINGS_PRICING_PLAN = "/settings/pricing-plan",
  SETTINGS_RETURNS = "/settings/returns",
  SETTINGS_TMS_CONFIGURATION = "/settings/tms-configuration",
  SETTINGS_API_KEYS = "/settings/api-keys",
  SETTINGS_CATEGORIES = "/settings/categories",
  SETTINGS_BLOCKED_RETURNS = "/settings/blocked-returns",
  SETTINGS_AUTOMATION = "/settings/automation",
  SETTINGS_AUTOMATION_REPORTS = "/settings/automation/reports",
  SETTINGS_REFUND_RULES = "/settings/refund-rules",
  SETTINGS_SEGMENTS = "/settings/segments",
  SETTINGS_SEGMENTS__id = "/settings/segments/:id",
  SETTINGS_SHIPPING_RULES = "/settings/shipping-rules",
  SETTINGS_EXCHANGE = "/settings/exchange",

  PERMISSIONS = "/permissions",
  FEATURES = "/features",
  USER_ACCESS = "/user-access",
  LOGS = "/logs",
  FORBIDDEN = "/forbidden",

  PRODUCT_PAGE = "/product/:productName",
  ORDER_PAGE = "/order/:extOrderId",
  CUSTOMER_PAGE = "/customer/:customerEmail",
  CALLBACK_PAGE = "/callback",

  REDIRECT_INTEGRATE_GORGIAS = "/redirect/integration/gorgias",
  REDIRECT_SHOPIFY = "/redirect/shopify",
  REDIRECT_BILLING = "/redirect/billing",
  REDIRECT_SETTINGS_BILLING = "/redirect/settings/billing",
  REDIRECT_INTEGRATION_ZENDESK = "/redirect/integration/zendesk",
  REDIRECT_REFRESH = "/redirect/refresh",

  PDF_TEMPLATE = "/pdf-template/:retailerUuid",
}
