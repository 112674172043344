import { CopyOutlined, EditOutlined } from "@ant-design/icons";
import React, { useCallback, useContext, useState } from "react";
import { changeTrackingId } from "../../api/shipping";
import { IOrderItem } from "../../models";
import "./ShippingStatus.scss";
import "../Input/Input.scss";
import { OrdersContext } from "../../context/OrdersContext";
import Modal from "antd/es/modal";
import Select from "antd/es/select";
import Input from "antd/es/input";
import Button from "antd/es/button";
import { CopyToClipboard } from "react-copy-to-clipboard";

interface IShippingStatusProps {
  record: IOrderItem;
  refetch: () => void;
}

export const ShippingStatus: React.FC<IShippingStatusProps> = ({
  record,
  refetch,
}) => {
  const { shippingCarriers } = useContext(OrdersContext);
  const [hasModalOpened, setHasModalOpened] = useState(false);
  const [hasConfirmationModalOpened, setHasConfirmationModalOpened] =
    useState(false);
  const [trackingNumber, setTrackingNumber] = useState(record.tracking_id);
  const [shippingCarrier, setShippingCarrier] = useState(
    record.shipping_company || undefined
  );
  const handleTrackingNumberChange = useCallback(
    (e) => {
      setTrackingNumber(e.target.value);
    },
    [setTrackingNumber]
  );

  const setDefault = useCallback(() => {
    setTrackingNumber(record.tracking_id);
    setShippingCarrier(record.shipping_company || undefined);
  }, [setTrackingNumber, setShippingCarrier, record]);

  const validateTrackingNumber =
    trackingNumber !== null && trackingNumber.includes("\\");
  const trackingRegex = /[^a-zA-z0-9\s\-_]/g;
  const regEx = validateTrackingNumber || trackingRegex.test(trackingNumber);

  const hasButtonsDisabled =
    !trackingNumber ||
    !shippingCarrier ||
    (trackingNumber === record.tracking_id &&
      shippingCarrier === record.shipping_company) ||
    regEx;
  return (
    <div className="shipping-status" onClick={(e) => e.stopPropagation()}>
      <div>
        {record.tracking_url ? (
          <a href={record.tracking_url} target="blank">
            {record.tracking_status}
          </a>
        ) : (
          record.tracking_status
        )}
        <br />
        <span className="text-secondary text-small">
          {record.shipping_company}
        </span>
      </div>
      {record.tracking_id && (
        <CopyToClipboard text={record.tracking_id}>
          <CopyOutlined className="shipping-status__icon" />
        </CopyToClipboard>
      )}
      <div
        onClick={(e) => {
          setHasModalOpened(true);
        }}
      >
        <EditOutlined className="shipping-status__icon" />
      </div>
      <Modal
        title={
          record.shipping_company
            ? `Shipping carrier: ${record.shipping_company}`
            : "Enter shipping details"
        }
        open={hasModalOpened}
        onCancel={() => {
          setHasModalOpened(false);
          setDefault();
        }}
        footer={null}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setHasConfirmationModalOpened(true);
          }}
        >
          <Select
            className="shipping-status__input"
            value={shippingCarrier}
            onChange={setShippingCarrier}
            placeholder="Shipping carrier"
          >
            {shippingCarriers.map((x) => (
              <Select.Option key={x} value={x}>
                {x}
              </Select.Option>
            ))}
          </Select>
          <Input
            className={
              regEx ? "shipping-status__input_error" : "shipping-status__input"
            }
            value={trackingNumber}
            onChange={handleTrackingNumberChange}
            placeholder="Tracking number"
          />
          <div className="shipping-status__buttons">
            <Button
              className="shipping-status__button"
              type="primary"
              htmlType="submit"
              disabled={hasButtonsDisabled}
            >
              {record.shipping_company ? "Change" : "Save"}
            </Button>
            <Button
              className="shipping-status__button"
              type="default"
              disabled={hasButtonsDisabled}
              onClick={setDefault}
            >
              Cancel
            </Button>
          </div>
        </form>
      </Modal>
      <Modal
        title="Are you sure?"
        open={hasConfirmationModalOpened}
        onOk={() => {
          changeTrackingId({
            order_shipment_id: record.order_shipment_id,
            ext_tracking_id: trackingNumber,
            shipping_carrier: shippingCarrier || "",
            order_id: record.order_id,
            country_code: record.country_code,
          }).then(() => {
            setHasConfirmationModalOpened(false);
            setHasModalOpened(false);
            refetch();
          });
        }}
        onCancel={() => setHasConfirmationModalOpened(false)}
      >
        Please confirm new tracking number (<b>{trackingNumber}</b>) with
        shipping carrier (<b>{shippingCarrier}</b>)
      </Modal>
    </div>
  );
};
