import "./App.scss";
import "normalize.css";

import * as React from "react";
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from "react-router-dom";

import { ELocalStorageKeys } from "./constants";
import { InsightsPageContextStore } from "./context/InsightsPageContext";
import { UserContext } from "./context/UserContext";
import {
  IPermissions,
  IRetailer,
  IRetailerAdminResponse,
  IUserProfile,
  IVerifyResponse,
} from "./models";
import { ERoutes } from "./routes";
import {
  getRetailers,
  getRetailersUserAccess,
  shopifyLogout,
  verify,
} from "./api/access";
import { setGenericUserId } from "./entities/GenericUserId";
import { getRedirectRoute, getIsSuperAdmin, handleLogin } from "./utils";
import { OrdersContextStore } from "./context/OrdersContext";
import { Suspense } from "react";
import { Loader } from "./components/Loader/Loader";
import { RoutesSwitch } from "./components/RoutesSwitch";
import { Notifications } from "./components/Notifications";
import { FeatureBillingCheck } from "./components/FeatureBillingCheck/FeatureBillingCheck";
import { BlockerPopup } from "./components/BlockerPopup/BlockerPopup";
import { PDFTemplate } from "./components/PDFTemplate/PDFTemplate";

const CallbackPageLazy = React.lazy(
  () =>
    import(
      /* webpackChunkName: "CallbackPage" */ "./components/pages/CallbackPage/CallbackPage"
    )
);
const LoginLazy = React.lazy(
  () => import(/* webpackChunkName: "Login" */ "./components/pages/Login/Login")
);
const LoadingPageLazy = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Redirect" */ "./components/pages/LoadingPage/LoadingPage"
    )
);
const BillingCheckPageLazy = React.lazy(
  () =>
    import(
      /* webpackChunkName: "BillingCheckPage"*/ "./components/pages/BillingCheckPage/BillingCheckPage"
    )
);
const DashboardLayoutLazy = React.lazy(
  () =>
    import(
      /* webpackChunkName: "DashboardLayout" */ "./components/DashboardLayout"
    )
);
const IntegrateGorgiasLazy = React.lazy(
  () =>
    import(
      /* webpackChunkName: "IntegrateGorgias" */ "./components/pages/IntegrateGorgias/IntegrateGorgias"
    )
);

interface IContentProps {
  onSignIn: () => void;
  onCognitoSignIn: () => void;
  handleLogout: () => void;
}

const Content = ({
  onSignIn,
  onCognitoSignIn,
  handleLogout,
}: IContentProps) => {
  const {
    currentRetailerUuid,
    genericUserId,
    hasNoPermission,
    userProfile,
    retailerAdminResponse,
  } = React.useContext(UserContext);

  if (!userProfile || !currentRetailerUuid || genericUserId === -1) {
    return (
      <>
        <Switch>
          <Route exact path={ERoutes.CALLBACK_PAGE}>
            <Suspense fallback={<Loader isLoading />}>
              <CallbackPageLazy />
            </Suspense>
          </Route>
          <Route path={ERoutes.REDIRECT_SHOPIFY}>
            <Suspense fallback={<Loader isLoading />}>
              <LoadingPageLazy loadingMessage="Redirecting from Shopify..." />
            </Suspense>
          </Route>
          <Route path={ERoutes.REDIRECT_BILLING}>
            <Suspense fallback={<Loader isLoading />}>
              <BillingCheckPageLazy />
            </Suspense>
          </Route>
          <Route path={ERoutes.REDIRECT_SETTINGS_BILLING}>
            <Suspense fallback={<Loader isLoading />}>
              <BillingCheckPageLazy />
            </Suspense>
          </Route>
          <Route path={ERoutes.REDIRECT_INTEGRATION_ZENDESK}>
            <Suspense fallback={<Loader isLoading />}>
              <LoadingPageLazy loadingMessage="Redirecting from Zendesk..." />
            </Suspense>
          </Route>
          <Route path={ERoutes.REDIRECT_INTEGRATE_GORGIAS}>
            <Suspense fallback={<Loader isLoading />}>
              <IntegrateGorgiasLazy />
            </Suspense>
          </Route>
          <Route path={ERoutes.LOGIN}>
            <Notifications />
            <Suspense fallback={<Loader isLoading />}>
              <LoginLazy
                onSignIn={onSignIn}
                onCognitoSignIn={onCognitoSignIn}
              />
            </Suspense>
          </Route>
          <Route>
            <Redirect to={ERoutes.LOGIN} />
          </Route>
        </Switch>
      </>
    );
  }

  return (
    <OrdersContextStore key={currentRetailerUuid}>
      <InsightsPageContextStore>
        <BlockerPopup
          isOpen={hasNoPermission}
          title={userProfile?.firstName || ""}
          message="You don't have permission to the system. \n Please reach out to your manager."
        />

        <Switch>
          <Route exact path={ERoutes.CALLBACK_PAGE}>
            <Suspense fallback={<Loader isLoading />}>
              <CallbackPageLazy />
            </Suspense>
          </Route>
          <Route path={ERoutes.REDIRECT_SHOPIFY}>
            <Suspense fallback={<Loader isLoading />}>
              <LoadingPageLazy loadingMessage="Redirecting from Shopify..." />
            </Suspense>
          </Route>
          <Route path={ERoutes.REDIRECT_BILLING}>
            <Suspense fallback={<Loader isLoading />}>
              <BillingCheckPageLazy />
            </Suspense>
          </Route>
          <Route path={ERoutes.REDIRECT_SETTINGS_BILLING}>
            <Suspense fallback={<Loader isLoading />}>
              <BillingCheckPageLazy />
            </Suspense>
          </Route>
          <Route path={ERoutes.REDIRECT_INTEGRATION_ZENDESK}>
            <Suspense fallback={<Loader isLoading />}>
              <LoadingPageLazy loadingMessage="Redirecting from Zendesk..." />
            </Suspense>
          </Route>
          <Route path={ERoutes.REDIRECT_INTEGRATE_GORGIAS}>
            <Suspense fallback={<Loader isLoading />}>
              <IntegrateGorgiasLazy />
            </Suspense>
          </Route>
          <Route path={ERoutes.LOGIN}>
            <Notifications />
            <Suspense fallback={<Loader isLoading />}>
              <LoginLazy
                onSignIn={onSignIn}
                onCognitoSignIn={onCognitoSignIn}
              />
            </Suspense>
          </Route>
          <Route path={ERoutes.PDF_TEMPLATE}>
            <PDFTemplate />
          </Route>
          <Route>
            {userProfile === undefined && <Redirect to={ERoutes.LOGIN} />}
            {userProfile === null && <Loader isLoading />}
            <Suspense fallback={<Loader isLoading />}>
              <DashboardLayoutLazy
                isRetailerAdmin={retailerAdminResponse?.user.is_retailer_admin}
                handleLogout={handleLogout}
              >
                {userProfile && <RoutesSwitch userProfile={userProfile} />}
                <FeatureBillingCheck />
              </DashboardLayoutLazy>
            </Suspense>
          </Route>
        </Switch>
      </InsightsPageContextStore>
    </OrdersContextStore>
  );
};

interface IAppProps extends RouteComponentProps {
  setErrorNotification: (errorNotification: string) => void;
  errorNotification: string;
  setSuccessNotification: (successNotification: string) => void;
  successNotification: string;
  setWarningNotification: (warningNotification: string) => void;
  warningNotification: string;
  userProfile: IUserProfile | undefined | null;
  setUserProfile: React.Dispatch<
    React.SetStateAction<IUserProfile | undefined | null>
  >;
  retailerAdminResponse: IRetailerAdminResponse | undefined;
  setIsSuperadmin: React.Dispatch<React.SetStateAction<boolean>>;
  setUserPermissions: React.Dispatch<React.SetStateAction<IPermissions>>;
  changeAvailableRetailers: (
    availableRetailers: IRetailer[] | undefined
  ) => void;
  changeCurrentRetailerUuid: (retailerUuid: string) => void;
  setGenericUserId: (genericUserId: number) => void;
  setHasSetupOpened: (hasSetupOpened: boolean) => void;
}

interface IAppState {
  collapsed: boolean;
  auth2: any;
  hasLogoutOpened: boolean;
  verifyResponse: IVerifyResponse | undefined | null;
}

class App extends React.Component<IAppProps, IAppState> {
  state: IAppState = {
    collapsed: true,
    auth2: null,
    hasLogoutOpened: false,
    verifyResponse: undefined,
  };

  async componentDidMount() {
    if (!this.props.location.pathname.startsWith(ERoutes.CALLBACK_PAGE)) {
      localStorage.setItem(
        ELocalStorageKeys.INITIAL_URL,
        this.props.location.pathname + this.props.location.search
      );
    }

    // NOTE: try cognito login if token is present
    if (localStorage.getItem(ELocalStorageKeys.COGNITO_ID_TOKEN)) {
      const searchParams = new URLSearchParams();
      searchParams.append(
        "id_token",
        localStorage.getItem(ELocalStorageKeys.COGNITO_ID_TOKEN) || ""
      );
      this.props.history.push(
        ERoutes.CALLBACK_PAGE + "#" + searchParams.toString()
      );
      return;
    }

    try {
      const verifyResponse: IVerifyResponse = await verify();
      this.setState({
        verifyResponse,
      });

      localStorage.setItem(
        ELocalStorageKeys.SHOPIFY_STORE_NAME,
        verifyResponse.shopify_log_in_details.store_name
      );
      this.props.setHasSetupOpened(
        verifyResponse.shopify_log_in_details.hasSetupOpened === "true"
      );

      const userProfile = {
        firstName: verifyResponse.shopify_log_in_details.first_name,
        lastName: verifyResponse.shopify_log_in_details.last_name,
        email: verifyResponse.shopify_log_in_details.email,
        profileImage: verifyResponse.shopify_log_in_details.profile_image,
      };
      this.props.setUserProfile(userProfile);
      const userEmail = userProfile?.email || "";
      const isSuperadmin = userProfile && getIsSuperAdmin(userEmail);
      this.props.setIsSuperadmin(isSuperadmin);

      setGenericUserId(verifyResponse.shopify_log_in_details.generic_user_id);
      this.props.changeCurrentRetailerUuid(
        verifyResponse.shopify_log_in_details.retailer_uuid
      );
      this.props.setGenericUserId(
        verifyResponse.shopify_log_in_details.generic_user_id
      );
      const retailerUuid = (await getRetailers()).find((x) => {
        return (
          x.retailer_uuid ===
          verifyResponse.shopify_log_in_details.retailer_uuid
        );
      });
      if (!retailerUuid) {
        throw new Error("You don't have permissions for the provided retailer");
      }
      try {
        if (getIsSuperAdmin(verifyResponse.shopify_log_in_details.email)) {
          setGenericUserId(
            verifyResponse.shopify_log_in_details.generic_user_id
          );
          await getRetailers().then(this.props.changeAvailableRetailers);
        } else {
          setGenericUserId(
            verifyResponse.shopify_log_in_details.generic_user_id
          );
          await getRetailersUserAccess(
            verifyResponse.shopify_log_in_details.generic_user_id
          ).then(this.props.changeAvailableRetailers);
        }
        const userPermissions = await handleLogin(
          verifyResponse.shopify_log_in_details.generic_user_id,
          retailerUuid
        );

        this.props.setUserPermissions(userPermissions);

        const initialUrl = localStorage
          .getItem(ELocalStorageKeys.INITIAL_URL)
          ?.split("?")[0];
        const route = getRedirectRoute({
          userPermissions,
          isSuperadmin,
          initialUrl:
            initialUrl ||
            localStorage.getItem(ELocalStorageKeys.INITIAL_URL) ||
            "",
        });
        if (route.indexOf(ERoutes.CALLBACK_PAGE) === 0) {
          this.props.history.push(ERoutes.HOMEPAGE);
        } else {
          this.props.history.push(route || ERoutes.HOMEPAGE);
        }
      } catch (e) {
        console.error(e);
        this.props.history.push(ERoutes.LOGIN);
        this.props.setErrorNotification(
          "Something went wrong during authorization"
        );
      }
      return;
    } catch (e) {
      if (localStorage.getItem(ELocalStorageKeys.SHOPIFY_STORE_NAME)) {
        window.location.href = `https://${localStorage.getItem(
          ELocalStorageKeys.SHOPIFY_STORE_NAME
        )}/admin/apps`;
      }
      console.error(e);
    }

    this.setState({
      verifyResponse: null,
    });

    // @ts-ignore
    // NOTE: this code is not removed just in case we will use it again instead of cognito
    // if (!window.gapi) {
    //   // @ts-ignore
    //   window['onGoogleApiLoaded'] = this.initializeGoogleApi
    // } else {
    //   this.initializeGoogleApi()
    // }
  }

  // NOTE: this code is not removed just in case we will use it again instead of cognito
  // initializeGoogleApi = () => {
  //   // @ts-ignore
  //   gapi.load('auth2', () => {
  //     // @ts-ignore
  //     const auth2 = gapi.auth2.init({
  //       client_id: process.env.REACT_APP_GOOGLE_API_KEY,
  //       scope: 'profile'
  //     });
  //     this.setState({
  //       auth2
  //     }, () => {
  //       auth2.isSignedIn.listen(this.signInChanged);
  //       auth2.currentUser.listen(this.changeUserProfile);
  //       if (auth2.isSignedIn.get() === true) {
  //         auth2.signIn();
  //       }
  //     })
  //   });
  // }

  // signInChanged = (isSignedIn: boolean) => {
  //   if (!isSignedIn) {
  //     setRetailerUuid('')
  //     this.props.setUserProfile(null)
  //   }
  // }

  onSignIn = () => {
    if (this.state.auth2 !== null) {
      (this.state.auth2 as any).signIn();
    }
  };

  redirectToCognitoSignIn = () => {
    window.location.href = `${process.env.REACT_APP_COGNITO_AUTH_DOMAIN}/login?client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&response_type=token&scope=email+openid+profile&redirect_uri=${process.env.REACT_APP_COGNITO_REDIRECT_URI}`;
  };

  onCognitoSignIn = () => {
    if (this.state.auth2 as any) {
      (this.state.auth2 as any).signOut().then(() => {
        (this.state.auth2 as any).disconnect();
        this.redirectToCognitoSignIn();
      });
    } else {
      this.redirectToCognitoSignIn();
    }
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  // NOTE: this code is not removed just in case we will use it again instead of cognito
  // changeUserProfile = (currentUser: any) => {
  //   // NOTE: if user profile is stored => user tries to logout
  //   if (this.props.userProfile) {
  //     this.props.setUserProfile(undefined)
  //     return
  //   }
  //   const userProfile = currentUser.getBasicProfile()
  //   if (userProfile) {
  //     fetchRetailerUuid(userProfile.getEmail())
  //       .then((retailerUuid) => {
  //         if (retailerUuid[0]) {
  //           setRetailerUuid(retailerUuid[0].retailer_uuid)
  //         }
  //         return sendGoogleTokenId(currentUser.getAuthResponse().id_token)
  //           .then(({ generic_user_id }) => {
  //             return handleLogin(generic_user_id, retailerUuid)
  //           })
  //       })
  //       .then(userPermissions => {
  //         const userEmail = (userProfile && (userProfile as any).getEmail()) || ''
  //         const isSuperadmin = userProfile && (process.env.REACT_APP_SUPERADMIN || '').split(' ').some(x => x === userEmail || userEmail.split('@')[1] === x)

  //         this.props.setUserProfile({
  //           firstName: (userProfile as any).getName(),
  //           lastName: (userProfile as any).getFamilyName(),
  //           email: (userProfile as any).getEmail(),
  //           profileImage: (userProfile as any).getImageUrl()
  //         })
  //         this.props.setIsSuperadmin(isSuperadmin)
  //         this.props.setUserPermissions(userPermissions)
  //         if (localStorage.getItem(ELocalStorageKeys.INITIAL_URL)) {
  //           const route = getAccessibleRoute({
  //             userPermissions,
  //             isSuperadmin,
  //             initialUrl: localStorage.getItem(ELocalStorageKeys.INITIAL_URL) || ''
  //           })
  //           this.props.history.push(route || ERoutes.HOMEPAGE)
  //         }
  //       })
  //       .catch(e => {
  //         console.error(e)
  //         this.props.history.push(ERoutes.LOGIN)
  //         this.props.setErrorNotification('Something went wrong during authorization')
  //       })
  //   } else {
  //     this.props.setUserProfile(null)
  //     this.props.setIsSuperadmin(false)
  //     this.props.history.push(ERoutes.LOGIN)
  //   }
  // }

  handleLogout = () => {
    shopifyLogout().then(() => {
      localStorage.removeItem(ELocalStorageKeys.COGNITO_ID_TOKEN);
      window.location.reload();
    });
    // NOTE: this code is not removed just in case we will use it again instead of cognito
    // (this.state.auth2 as any).signOut()
    //   .then(() => {
    //     (this.state.auth2 as any).disconnect()
    //     window.location.reload(false)
    //   })
  };

  render() {
    return (
      <Content
        onSignIn={this.onSignIn}
        onCognitoSignIn={this.onCognitoSignIn}
        handleLogout={this.handleLogout}
      />
    );
  }
}

const AppWithRouter = withRouter(App);

export { AppWithRouter as App };
