import dayjs, { Dayjs } from "dayjs";
import { range } from "lodash";
import React from "react";
import { EVIPSettingsParam } from "../../../../../../../../../../../constants";
import { ISegmentWithParams } from "../../../../../../../../../../../models";
import { Button } from "../../../../../../../../../../Button/Button";
import DatePicker from "../../../../../../../../../../DatePicker/DatePicker";
import { Input } from "../../../../../../../../../../Input/Input";
import { SelectOption } from "../../../../../../../../../../SelectOption/SelectOption";

import "./Parameters.scss";

interface IParametersProps {
  segmentSettings: ISegmentWithParams | undefined;
  handleSegmentSettingsChange: (
    value: string | number,
    name?: EVIPSettingsParam
  ) => void;
  handleSegmentSettingsUpdate: (e: React.FormEvent) => void;
  handleSegmentSettingsDateChange: (
    date: Dayjs | null,
    dateString: string
  ) => void;
  isUpdateDisabled: boolean;
}

const OPTIONS = range(1, 101).map((value) => ({ value, title: String(value) }));

export const Parameters: React.FC<IParametersProps> = ({
  segmentSettings,
  handleSegmentSettingsChange,
  handleSegmentSettingsUpdate,
  handleSegmentSettingsDateChange,
  isUpdateDisabled,
}) => {
  if (!segmentSettings) {
    return null;
  }

  return (
    <div className="parameters">
      <h3 className="preview__title">New VIP Settings</h3>

      <form className="parameters__form" onSubmit={handleSegmentSettingsUpdate}>
        <div className="parameters__group">
          <SelectOption
            selectClassName="parameters__select"
            label="Minimum order count"
            placeholder="Select"
            onSelectionChange={handleSegmentSettingsChange}
            name="number_of_orders"
            value={
              segmentSettings?.params[EVIPSettingsParam.NUMBER_OF_ORDERS]?.value
            }
            options={OPTIONS}
          />

          <Input
            className="parameters__input"
            label="Revenue greater than"
            name="revenue"
            onChange={handleSegmentSettingsChange}
            placeholder="10,000"
            value={segmentSettings?.params[EVIPSettingsParam.REVENUE]?.value}
          />
        </div>

        <label className="parameters__label">
          Active since
          <DatePicker
            className="parameters__date"
            format="YYYY.MM.DD"
            value={dayjs(
              segmentSettings?.params[EVIPSettingsParam.ACTIVE_SINCE]?.value
            )}
            placeholder="Select"
            onChange={handleSegmentSettingsDateChange}
          />
        </label>

        <div className="parameters__group">
          <Input
            className="parameters__input"
            label="Average order size greater than"
            name="last_order_size"
            onChange={handleSegmentSettingsChange}
            placeholder="10,000"
            value={
              segmentSettings?.params[EVIPSettingsParam.LAST_ORDER_SIZE]?.value
            }
          />

          <SelectOption
            selectClassName="parameters__select"
            label="Total returns"
            placeholder="Select"
            onSelectionChange={handleSegmentSettingsChange}
            name="total_returns"
            value={
              segmentSettings?.params[EVIPSettingsParam.TOTAL_RETURNS]?.value
            }
            options={OPTIONS}
          />
        </div>

        <Button
          className="parameters__update"
          label="Update"
          disabled={isUpdateDisabled}
        />
      </form>
    </div>
  );
};
