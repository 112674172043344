import noop from "lodash-es/noop";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
  isRetailerAdmin,
  getUserPagesAccess,
  shopifyLogout,
} from "../api/access";
import { getFeatureAccess } from "../api/billing";
import { getOnboardingProgress, setSetupFinished } from "../api/onboarding";
import { getCompanyDetails, INTEGRATION_ERROR } from "../api/retailer";
import { ORDER } from "../components/pages/SignUp/SignUp";
import { API_ERROR, EFeatureType, ELocalStorageKeys } from "../constants";
import { getRetailerUuid, setRetailerUuid } from "../entities/RetailerUuid";
import {
  IFeatureAccessResponse,
  IOnboardingProgress,
  IPermissions,
  IRetailer,
  IRetailerAdminResponse,
  IUserProfile,
} from "../models";
import { ERoutes } from "../routes";
import { getIsSuperAdmin } from "../utils";
import { IntegrationErrorContext } from "./IntegrationErrorContext";
import { NotificationContext } from "./NotificationContext";
import {
  createGetPermission,
  DEFAULT_PERMISSIONS,
  PermissionsContext,
} from "./PermissionsContext";
import { isEqual } from "lodash";

export interface IUserContext {
  genericUserId: number;
  setGenericUserId: (genericUserId: number) => void;
  currentRetailerUuid: string;
  changeCurrentRetailerUuid: (retailerUuid: string) => void;
  availableRetailers: IRetailer[] | undefined;
  changeAvailableRetailers: (
    availableRetailers: IRetailer[] | undefined
  ) => void;
  userProfile: IUserProfile | undefined | null;
  setUserProfile: React.Dispatch<
    React.SetStateAction<IUserProfile | undefined | null>
  >;
  setIsSuperadmin: React.Dispatch<React.SetStateAction<boolean>>;
  setUserPermissions: React.Dispatch<React.SetStateAction<IPermissions>>;
  hasReturnFormOpened: boolean;
  setHasReturnFormOpened: (isSetUpDone: boolean) => void;
  onboardingProgress: IOnboardingProgress | undefined;
  setOnboardingProgress: (
    onboardingProgress: IOnboardingProgress | undefined
  ) => void;
  retailerAdminResponse: IRetailerAdminResponse | undefined;
  hasCompleteBtnClicked: boolean;
  setHasCompleteBtnClicked: (hasCompleteBtnClicked: boolean) => void;
  hasNoPermission: boolean;
  hasActivePayment: boolean;
  retailerBillingStatus: IFeatureAccessResponse | undefined;
  // TODO: Add functionality to pay button
  // triggerPayment: () => void
}

export const UserContext = React.createContext<IUserContext>({
  genericUserId: 0,
  setGenericUserId: noop,
  currentRetailerUuid: "",
  changeCurrentRetailerUuid: noop,
  availableRetailers: [],
  changeAvailableRetailers: noop,
  userProfile: undefined,
  setUserProfile: noop,
  setIsSuperadmin: noop,
  setUserPermissions: noop,
  hasReturnFormOpened: false,
  setHasReturnFormOpened: noop,
  onboardingProgress: undefined,
  setOnboardingProgress: noop,
  retailerAdminResponse: undefined,
  hasCompleteBtnClicked: false,
  setHasCompleteBtnClicked: noop,
  hasNoPermission: false,
  hasActivePayment: true,
  retailerBillingStatus: undefined,
});

export const UserContextStore: React.FC = ({ children }) => {
  const { setIntegrationError } = useContext(IntegrationErrorContext);
  const { setErrorNotification } = useContext(NotificationContext);
  const [genericUserId, setGenericUserId] = useState<number>(-1);

  const [userProfile, setUserProfile] = useState<
    IUserProfile | undefined | null
  >(undefined);
  const setUserProfileWithCheck = useCallback(
    (userProfile: React.SetStateAction<IUserProfile | null | undefined>) => {
      setUserProfile((prevUserProfile) => {
        const newUserProfile =
          typeof userProfile === "function"
            ? userProfile(prevUserProfile)
            : userProfile;
        return isEqual(prevUserProfile, newUserProfile)
          ? prevUserProfile
          : newUserProfile;
      });
    },
    [setUserProfile]
  );

  const [isSuperadmin, setIsSuperadmin] = useState(false);

  const [userPermissions, setUserPermissions] =
    useState<IPermissions>(DEFAULT_PERMISSIONS);

  const setUserPermissionsWithCheck = useCallback(
    (userPermissions: React.SetStateAction<IPermissions>) => {
      setUserPermissions((prevUserPermissions) => {
        const newUserPermissions =
          typeof userPermissions === "function"
            ? userPermissions(prevUserPermissions)
            : userPermissions;
        return isEqual(prevUserPermissions, newUserPermissions)
          ? prevUserPermissions
          : newUserPermissions;
      });
    },
    [setUserPermissions]
  );

  const [availableRetailers, setAvailableRetailers] = useState<
    IRetailer[] | undefined
  >(undefined);

  const [currentRetailerUuid, setCurrentRetailerUuid] = useState(
    getRetailerUuid()
  );
  const history = useHistory();

  const changeCurrentRetailerUuid = useCallback(
    (retailerUuid: string) => {
      setCurrentRetailerUuid(retailerUuid);
      setRetailerUuid(retailerUuid);
    },
    [setCurrentRetailerUuid]
  );

  const changeAvailableRetailers = useCallback(
    (availableRetailers: IRetailer[] | undefined) => {
      if (availableRetailers) {
        const isCurrentRetailerAvailableRetailer = availableRetailers.some(
          (x) => x.retailer_uuid === currentRetailerUuid && x.is_active
        );
        if (!isCurrentRetailerAvailableRetailer) {
          changeCurrentRetailerUuid(availableRetailers[0]?.retailer_uuid);
        }
      }
      setAvailableRetailers((prevAvailableRetailers) =>
        isEqual(prevAvailableRetailers, availableRetailers)
          ? prevAvailableRetailers
          : availableRetailers
      );
    },
    [setAvailableRetailers, currentRetailerUuid, changeCurrentRetailerUuid]
  );

  const [hasNoPermission, setHasNoPermission] = useState(false);

  const [hasReturnFormOpened, setHasReturnFormOpened] = useState(false);

  const [hasCompleteBtnClicked, setHasCompleteBtnClicked] = useState(false);

  const [onboardingProgress, setOnboardingProgress] = useState<
    IOnboardingProgress | undefined
  >(undefined);

  useEffect(() => {
    const isDone =
      ORDER.every((x) => onboardingProgress?.completed_steps.includes(x)) ||
      false;
    if (!onboardingProgress?.is_complete && isDone) {
      setSetupFinished().then(setOnboardingProgress);
    }
  }, [setOnboardingProgress, onboardingProgress]);

  const [retailerAdminResponse, setRetailerAdminResponse] = useState<
    IRetailerAdminResponse | undefined
  >(undefined);

  const [hasActivePayment, setHasActivePayment] = useState(true);
  const [retailerBillingStatus, setRetailerBillingStatus] = useState<
    IFeatureAccessResponse | undefined
  >(undefined);

  useEffect(() => {
    if (currentRetailerUuid && genericUserId >= 0) {
      getOnboardingProgress().then(setOnboardingProgress);
      isRetailerAdmin().then(setRetailerAdminResponse);
      const getBillingCheckHandler = () =>
        getFeatureAccess(EFeatureType.PLAN)
          .then((billingStatus) => {
            setRetailerBillingStatus(billingStatus);
            setHasActivePayment(
              Boolean(billingStatus.details.feature?.has_access)
            );
          })
          .catch((error) => {
            if (error.message === INTEGRATION_ERROR) {
              setIntegrationError({
                tryAgain: getBillingCheckHandler,
              });
            }
          });

      getBillingCheckHandler();
    }
  }, [
    setOnboardingProgress,
    setRetailerAdminResponse,
    setRetailerBillingStatus,
    setHasActivePayment,
    setIntegrationError,
    currentRetailerUuid,
    genericUserId,
  ]);

  useEffect(() => {
    if (currentRetailerUuid && userProfile) {
      if (isSuperadmin) {
        // NOTE: we don't want to track companies for superadmins in intercom
        window.dataLayer.push({
          event: "login",
          name: userProfile.firstName,
          email: userProfile.email,
        });
        return;
      }
      getCompanyDetails(userProfile?.email)
        .then((companyDetails) => {
          if (companyDetails) {
            window.dataLayer.push({
              event: "login",
              name: userProfile.firstName,
              email: userProfile.email,
              ...companyDetails,
            });
          } else {
            window.dataLayer.push({
              event: "login",
              name: userProfile.firstName,
              email: userProfile.email,
            });
          }
        })
        .catch(console.error);
    }
  }, [userProfile, currentRetailerUuid, isSuperadmin]);

  useEffect(() => {
    if (genericUserId && genericUserId >= 0 && userProfile) {
      getUserPagesAccess(genericUserId)
        .then((userPageAccess) => {
          const hasNoPermission = userPageAccess.every(
            (access) => !access.mapping_id
          );

          if (hasNoPermission && !getIsSuperAdmin(userProfile.email)) {
            setHasNoPermission(hasNoPermission);
            shopifyLogout().then(() => {
              localStorage.removeItem(ELocalStorageKeys.COGNITO_ID_TOKEN);
              history.push(ERoutes.LOGIN);
            });
          }
        })
        .catch((err) => {
          console.error(err);
          setErrorNotification(API_ERROR);
        });
    }
  }, [
    genericUserId,
    setHasNoPermission,
    setErrorNotification,
    history,
    userProfile,
  ]);

  return (
    <UserContext.Provider
      value={{
        genericUserId,
        setGenericUserId,
        currentRetailerUuid,
        changeCurrentRetailerUuid,
        availableRetailers,
        changeAvailableRetailers,
        userProfile,
        setUserProfile: setUserProfileWithCheck,
        setIsSuperadmin,
        setUserPermissions: setUserPermissionsWithCheck,
        hasReturnFormOpened,
        setHasReturnFormOpened,
        onboardingProgress,
        setOnboardingProgress,
        retailerAdminResponse,
        hasCompleteBtnClicked,
        setHasCompleteBtnClicked,
        hasNoPermission,
        hasActivePayment,
        retailerBillingStatus,
      }}
    >
      <PermissionsContext.Provider
        value={{
          getPermission: createGetPermission(
            {
              ...userPermissions,
            },
            Boolean(isSuperadmin)
          ),
          isSuperadmin: Boolean(isSuperadmin),
          isRetailerAdmin: Boolean(
            retailerAdminResponse?.user.is_retailer_admin
          ),
        }}
      >
        {children}
      </PermissionsContext.Provider>
    </UserContext.Provider>
  );
};
