import React, { useMemo } from "react";
import { IWeeklyRevenueRetained } from "../../models";
import { sortBy } from "lodash";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { RetentionTooltip } from "./RetentionTooltip/RetentionTooltip";

import "./RetentionRateChart.scss";

interface IRetentionRateChartProps {
  weeklyRevenueRetained: IWeeklyRevenueRetained[];
}

export const RetentionRateChart: React.FC<IRetentionRateChartProps> = ({
  weeklyRevenueRetained,
}) => {
  const revenueRetainedChartData = useMemo(() => {
    return sortBy(weeklyRevenueRetained, (x) => x.week_number).map((x, i) => {
      return {
        name: `Week ${i + 1}`,
        ...x,
      };
    });
  }, [weeklyRevenueRetained]);

  return (
    <div className="retention-rate-chart">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={revenueRetainedChartData} barSize={20}>
          <CartesianGrid strokeDasharray="2 2" vertical={false} />

          <XAxis dataKey="name" fontSize="10px" interval={0} />
          <YAxis
            width={36}
            fontSize="10px"
            tickFormatter={(value) => {
              return `${value}%`;
            }}
          />

          <Bar dataKey="exchange_percent" name="Exchange" fill="#FE7575" />
          <Bar
            dataKey="store_credit_percent"
            name="Store credits"
            fill="#FFE24B"
          />
          <Tooltip
            cursor={false}
            content={(props) => <RetentionTooltip tooltipProps={props} />}
          />
          <Legend
            iconType="circle"
            iconSize={8}
            formatter={(value) => {
              return (
                <span className="retention-rate-chart__legend">{value}</span>
              );
            }}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
