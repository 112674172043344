import { noop } from "lodash";
import React, { useCallback, useState } from "react";
import {
  IIntegrationError,
  IntegrationErrorModal,
} from "../components/IntegrationErrorModal/IntegrationErrorModal";

interface IIntegrationErrorContext {
  setIntegrationError: (
    integrationError: IIntegrationError | undefined
  ) => void;
}

export const IntegrationErrorContext =
  React.createContext<IIntegrationErrorContext>({
    setIntegrationError: noop,
  });

export const IntegrationErrorContextStore: React.FC = ({ children }) => {
  const [integrationError, setIntegrationError] = useState<
    IIntegrationError | undefined
  >(undefined);
  const clearIntegrationError = useCallback(() => {
    setIntegrationError(undefined);
  }, [setIntegrationError]);

  return (
    <IntegrationErrorContext.Provider
      value={{
        setIntegrationError,
      }}
    >
      <IntegrationErrorModal
        onClose={clearIntegrationError}
        integrationError={integrationError}
      />
      {children}
    </IntegrationErrorContext.Provider>
  );
};
