import React, { Suspense, useContext, useMemo } from "react";
import { Link, Route, Switch } from "react-router-dom";
import { Loader } from "./Loader/Loader";
import { NotificationContext } from "../context/NotificationContext";
import { PermissionsContext } from "../context/PermissionsContext";
import { IUserProfile } from "../models";
import { ERoutes } from "../routes";
import { UserContext } from "../context/UserContext";
import { TmsConfigurationContextStore } from "../context/TmsConfigurationContext";
import { Segments } from "./pages/Settings/pages/Segments/Segments";
import { SegmentSettings } from "./pages/Settings/pages/Segments/pages/SegmentSettings/SegmentSettings";

const ProductPageLazy = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ProductPage" */ "./pages/ProductPage/ProductPage"
    )
);
const HomepageOldLazy = React.lazy(
  () =>
    import(/* webpackChunkName: "Homepage" */ "./pages/HomepageOld/HomepageOld")
);
const HomepageV2Lazy = React.lazy(
  () =>
    import(/* webpackChunkName: "Homepage" */ "./pages/HomepageV2/HomepageV2")
);
const ErrorPageLazy = React.lazy(
  () =>
    import(/* webpackChunkName: "ErrorPage" */ "./pages/ErrorPage/ErrorPage")
);
const OrderPageWithRouterLazy = React.lazy(
  () =>
    import(
      /* webpackChunkName: "OrderPageWithRouter" */ "./pages/OrderPage/OrderPage"
    )
);
const CustomerPageWithRouterLazy = React.lazy(
  () =>
    import(
      /* webpackChunkName: "CustomerPageWithRouter" */ "./pages/CustomerPage/CustomerPage"
    )
);
const ReturnManagementWithRouterLazy = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ReturnManagementWithRouter" */ "./pages/ReturnManagement/ReturnManagement"
    )
);
const ReturnManagementCustomListWithRouterLazy = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ReturnManagementCustomListWithRouter" */ "./pages/ReturnManagementCustomList/ReturnManagementCustomList"
    )
);
const ReturnsForecastLazy = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ReturnsForecast" */ "./pages/ReturnsForecast/ReturnsForecast"
    )
);
const InsightsCategoryLazy = React.lazy(
  () =>
    import(
      /* webpackChunkName: "InsightsCategory" */ "./pages/InsightsCategory/InsightsCategory"
    )
);
const InsightsBrandLazy = React.lazy(
  () =>
    import(
      /* webpackChunkName: "InsightsBrand" */ "./pages/InsightsBrand/InsightsBrand"
    )
);
const InsightsDatasetsLazy = React.lazy(
  () =>
    import(
      /* webpackChunkName: "InsightsDatasets" */ "./pages/InsightsDatasets/InsightsDatasets"
    )
);
const InsightsSimpleDatasetLazy = React.lazy(
  () =>
    import(
      /* webpackChunkName: "InsightsSimpleDataset" */ "./pages/InsightsSimpleDatasets/InsightsSimpleDatasets"
    )
);
const InsightsGraphDatasetsLazy = React.lazy(
  () =>
    import(
      /* webpackChunkName: "InsightsGraphDatasets" */ "./pages/InsightsGraphDatasets/InsightsGraphDatasets"
    )
);
const InsightsProductLazy = React.lazy(
  () =>
    import(
      /* webpackChunkName: "InsightsProduct" */ "./pages/InsightsProduct/InsightsProduct"
    )
);
const CommentsLazy = React.lazy(
  () => import(/* webpackChunkName: "Comments" */ "./pages/Comments/Comments")
);
const InsightsOverviewLazy = React.lazy(
  () =>
    import(
      /* webpackChunkName: "InsightsOverview" */ "./pages/InsightsOverview/InsightsOverview"
    )
);
const EmailTemplatesLazy = React.lazy(
  () =>
    import(
      /* webpackChunkName: "EmailTemplates" */ "./pages/Settings/pages/EmailTemplates/EmailTemplates"
    )
);
const SmsTemplatesLazy = React.lazy(
  () =>
    import(
      /* webpackChunkName: "SmsTemplates" */ "./pages/Settings/pages/SmsSettings/SmsTemplates"
    )
);
const ReturnReasonsSettingsLazy = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ReturnReasonsSettings" */ "./pages/Settings/pages/ReturnReasonsSettings/ReturnReasonsSettings"
    )
);
const ApiKeysSettingsLazy = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ApiKeysSettings" */ "./pages/Settings/pages/ApiKeysSettings/ApiKeysSettings"
    )
);
const StoresAndWarehousesLazy = React.lazy(
  () =>
    import(
      /* webpackChunkName: "StoresAndWarehouses" */ "./pages/Settings/pages/StoresAndWarehouses/StoresAndWarehouses"
    )
);
const ExchangeLazy = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Exchange" */ "./pages/Settings/pages/Exchange/Exchange"
    )
);
const TranslationSettingsLazy = React.lazy(
  () =>
    import(
      /* webpackChunkName: "TranslationSettings" */ "./pages/Settings/pages/TranslationSettings/TranslationSettings"
    )
);
const ImportsSettingsLazy = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ImportsSettings" */ "./pages/Settings/pages/ImportsSettings/ImportSettings"
    )
);
const TmsConfigurationLazy = React.lazy(
  () =>
    import(
      /* webpackChunkName: "TmsConfiguration" */ "./pages/Settings/pages/TmsConfiguration/TmsConfiguration"
    )
);
const CategoriesLazy = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Categories" */ "./pages/Settings/pages/Categories/Categories"
    )
);
const BlockReturnsLazy = React.lazy(
  () =>
    import(
      /* webpackChunkName: "BlockReturns" */ "./pages/Settings/pages/BlockedReturns/BlockedReturns"
    )
);
const PricingPlanSettingsLazy = React.lazy(
  () =>
    import(
      /* webpackChunkName: "PricingPlanSettings" */ "./pages/Settings/pages/PricingPlanSettings/PricingPlanSettings"
    )
);
const ReturnsSettingsLazy = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ReturnsSettings" */ "./pages/Settings/pages/ReturnsSettings/ReturnsSettings"
    )
);
const BrandSettingsLazy = React.lazy(
  () =>
    import(
      /* webpackChunkName: "BrandSettings" */ "./pages/Settings/pages/BrandSettings/BrandSettings"
    )
);
const SelfTranslationSettingsLazy = React.lazy(
  () =>
    import(
      /* webpackChunkName: "SelfTranslationSettings" */ "./pages/Settings/pages/SelfTranslationSettings/SelfTranslationSettings"
    )
);
const AutomationSettingsLazy = React.lazy(
  () =>
    import(
      /* webpackChunkName: "AutomationSettings" */ "./pages/Settings/pages/Automation/AutomationSettings"
    )
);
const RefundRulesSettingsLazy = React.lazy(
  () =>
    import(
      /* webpackChunkName: "RefundRulesSettings" */ "./pages/Settings/pages/RefundRulesSettings/RefundRulesSettings"
    )
);
const SelfTranslationSettingsV2Lazy = React.lazy(
  () =>
    import(
      /* webpackChunkName: "SelfTranslationSettingsV2" */ "./pages/Settings/pages/SelfTranslationSettingsV2/SelfTranslationSettingsV2"
    )
);
const IntegrationSettingsLazy = React.lazy(
  () =>
    import(
      /* webpackChunkName: "IntegrationSettings" */ "./pages/Settings/pages/Integrations/Integrations"
    )
);
const ShippingRulesSettingsLazy = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ShippingRulesSettings" */ "./pages/Settings/pages/ShippingRules/ShippingRules"
    )
);
const InStoreReturnFormLazy = React.lazy(
  () =>
    import(
      /* webpackChunkName: "InStoreReturnForm" */ "./pages/InStoreReturnForm/InStoreReturnForm"
    )
);
const AdminInsightsLazy = React.lazy(
  () =>
    import(
      /* webpackChunkName: "AdminInsights" */ "./pages/AdminInsights/AdminInsights"
    )
);
const PermissionsLazy = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Permissions" */ "./pages/Permissions/Permissions"
    )
);
const FeaturesConfigLazy = React.lazy(
  () =>
    import(
      /* webpackChunkName: "FeaturesConfig" */ "./pages/FeaturesConfig/FeaturesConfig"
    )
);
const UserAccessLazy = React.lazy(
  () =>
    import(/* webpackChunkName: "UserAccess" */ "./pages/UserAccess/UserAccess")
);
const ReleaseLogsLazy = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ReleaseLogs" */ "./pages/ReleaseLogs/ReleaseLogs"
    )
);
const FeedbackLazy = React.lazy(
  () => import(/* webpackChunkName: "Feedback" */ "./pages/Feedback/Feedback")
);

interface IRoutesSwitchProps {
  userProfile: IUserProfile;
}

export const RoutesSwitch: React.FC<IRoutesSwitchProps> = React.memo(
  ({ userProfile }) => {
    const { setErrorNotification, setSuccessNotification } =
      useContext(NotificationContext);
    const { getPermission } = useContext(PermissionsContext);
    const { availableRetailers, currentRetailerUuid } = useContext(UserContext);
    const selectedRetailer = useMemo(() => {
      return availableRetailers?.find(
        (retailer) => retailer.retailer_uuid === currentRetailerUuid
      );
    }, [availableRetailers, currentRetailerUuid]);
    return (
      <Suspense fallback={<Loader isLoading />}>
        <Switch>
          {
            <Route exact={true} path={ERoutes.PRODUCT_PAGE}>
              <ProductPageLazy onError={setErrorNotification} />
            </Route>
          }
          {
            <Route exact={true} path={ERoutes.ORDER_PAGE}>
              <OrderPageWithRouterLazy onError={setErrorNotification} />
            </Route>
          }
          {
            <Route exact={true} path={ERoutes.CUSTOMER_PAGE}>
              <CustomerPageWithRouterLazy onError={setErrorNotification} />
            </Route>
          }
          {getPermission("pages.access_homepage") && (
            <Route exact={true} path={ERoutes.HOMEPAGE}>
              <HomepageV2Lazy />
            </Route>
          )}
          {getPermission("pages.access_homepage_old") && (
            <Route exact={true} path={ERoutes.HOMEPAGE_OLD}>
              <HomepageOldLazy userProfile={userProfile} />
            </Route>
          )}
          {getPermission("pages.actions") && (
            <Route path={ERoutes.RETURN_MANAGEMENT__CUSTOM_LIST}>
              <ReturnManagementCustomListWithRouterLazy />
            </Route>
          )}
          {getPermission("pages.actions") && (
            <Route path={ERoutes.RETURN_MANAGEMENT}>
              <ReturnManagementWithRouterLazy />
            </Route>
          )}
          {getPermission("pages.access_returns_forecast") && (
            <Route path={ERoutes.RETURNS_FORECAST}>
              <ReturnsForecastLazy />
            </Route>
          )}
          {getPermission("pages.access_insights_category") && (
            <Route
              path={[
                ERoutes.INSIGHTS_CATEGORY__categoryName,
                ERoutes.INSIGHTS_CATEGORY,
              ]}
            >
              <InsightsCategoryLazy />
            </Route>
          )}
          {getPermission("pages.access_insights_brand") && (
            <Route
              path={[ERoutes.INSIGHTS_BRAND__brandName, ERoutes.INSIGHTS_BRAND]}
            >
              <InsightsBrandLazy />
            </Route>
          )}
          {getPermission("pages.access_insights_datasets") && (
            <Route path={ERoutes.INSIGHTS_DATASETS_GRAPH}>
              <InsightsGraphDatasetsLazy />
            </Route>
          )}
          {getPermission("pages.access_insights_datasets") && (
            <Route path={ERoutes.INSIGHTS_DATASETS_SIMPLE__id}>
              <InsightsSimpleDatasetLazy />
            </Route>
          )}
          {getPermission("pages.access_insights_datasets") && (
            <Route path={ERoutes.INSIGHTS_DATASETS}>
              <InsightsDatasetsLazy />
            </Route>
          )}
          {getPermission("pages.access_insights_product") && (
            <Route path={ERoutes.INSIGHTS_PRODUCT}>
              <InsightsProductLazy />
            </Route>
          )}
          {getPermission("pages.access_insights_comments") && (
            <Route path={ERoutes.INSIGHTS_COMMENTS}>
              <CommentsLazy />
            </Route>
          )}
          {getPermission("pages.access_insights_overview") && (
            <Route path={ERoutes.INSIGHTS_OVERVIEW}>
              <InsightsOverviewLazy />
            </Route>
          )}
          {getPermission("pages.access_settings_self_translation") && (
            <Route path={ERoutes.SETTINGS_SELF_TRANSLATION}>
              <SelfTranslationSettingsV2Lazy
                setSuccessNotification={setSuccessNotification}
                setErrorNotification={setErrorNotification}
              />
            </Route>
          )}
          {getPermission("pages.access_settings_self_translation_v1") && (
            <Route path={ERoutes.SETTINGS_SELF_TRANSLATION_V1}>
              <SelfTranslationSettingsLazy
                availableRetailers={availableRetailers || []}
                currentRetailerUuid={currentRetailerUuid}
                setErrorNotification={setErrorNotification}
              />
            </Route>
          )}
          {getPermission("pages.access_settings_email_templates") && (
            <Route path={ERoutes.SETTINGS_EMAIL_TEMPLATES}>
              <EmailTemplatesLazy />
            </Route>
          )}
          {getPermission("pages.access_settings_sms_templates") && (
            <Route path={ERoutes.SETTINGS_SMS_TEMPLATES}>
              <SmsTemplatesLazy />
            </Route>
          )}
          {getPermission("pages.access_settings_return_reasons") && (
            <Route path={ERoutes.SETTINGS_RETURN_REASONS}>
              <ReturnReasonsSettingsLazy
                setErrorNotification={setErrorNotification}
              />
            </Route>
          )}
          {getPermission("pages.access_settings_api_keys") && (
            <Route path={ERoutes.SETTINGS_API_KEYS}>
              <ApiKeysSettingsLazy />
            </Route>
          )}
          {getPermission("pages.access_settings_automation") && (
            <Route exact path={ERoutes.SETTINGS_AUTOMATION}>
              <AutomationSettingsLazy />
            </Route>
          )}
          {getPermission("pages.access_settings_refund_rules") && (
            <Route exact path={ERoutes.SETTINGS_REFUND_RULES}>
              <RefundRulesSettingsLazy />
            </Route>
          )}
          {getPermission("pages.access_settings_segments") && (
            <Route exact path={ERoutes.SETTINGS_SEGMENTS}>
              <Segments />
            </Route>
          )}
          {getPermission("pages.access_settings_segments") && (
            <Route exact path={ERoutes.SETTINGS_SEGMENTS__id}>
              <SegmentSettings />
            </Route>
          )}
          {getPermission("pages.access_settings_stores_and_warehouses") && (
            <Route path={ERoutes.SETTINGS_STORES_AND_WAREHOUSES}>
              <StoresAndWarehousesLazy />
            </Route>
          )}
          {getPermission("pages.access_settings_exchange") && (
            <Route path={ERoutes.SETTINGS_EXCHANGE}>
              <ExchangeLazy />
            </Route>
          )}
          {getPermission("pages.access_settings_categories") && (
            <Route path={ERoutes.SETTINGS_CATEGORIES}>
              <CategoriesLazy />
            </Route>
          )}
          {getPermission("pages.access_settings_blocked_returns") && (
            <Route path={ERoutes.SETTINGS_BLOCKED_RETURNS}>
              <BlockReturnsLazy />
            </Route>
          )}
          {getPermission("pages.access_settings_tms_configuration") && (
            <Route path={ERoutes.SETTINGS_TMS_CONFIGURATION}>
              <TmsConfigurationContextStore selectedRetailer={selectedRetailer}>
                <TmsConfigurationLazy />
              </TmsConfigurationContextStore>
            </Route>
          )}
          {getPermission("pages.access_settings_translations") && (
            <Route path={ERoutes.SETTINGS_TRANSLATIONS}>
              <TranslationSettingsLazy />
            </Route>
          )}
          {getPermission("pages.access_settings_imports") && (
            <Route path={ERoutes.SETTINGS_IMPORTS}>
              <ImportsSettingsLazy />
            </Route>
          )}
          {getPermission("pages.access_settings_pricing_plan") && (
            <Route path={ERoutes.SETTINGS_PRICING_PLAN}>
              <PricingPlanSettingsLazy />
            </Route>
          )}
          {getPermission("pages.access_settings_reasons") && (
            <Route path={ERoutes.SETTINGS_RETURNS}>
              <ReturnsSettingsLazy
                setErrorNotification={setErrorNotification}
              />
            </Route>
          )}
          {getPermission("pages.access_settings_shipping_rules") && (
            <Route path={ERoutes.SETTINGS_SHIPPING_RULES}>
              <ShippingRulesSettingsLazy />
            </Route>
          )}
          {getPermission("pages.access_settings_integrations") && (
            <Route path={ERoutes.SETTINGS_INTEGRATIONS}>
              <IntegrationSettingsLazy />
            </Route>
          )}
          {getPermission("pages.access_settings_brand") && (
            <Route path={ERoutes.SETTINGS_BRAND}>
              <BrandSettingsLazy />
            </Route>
          )}
          {getPermission("pages.instore") && (
            <Route path={ERoutes.IN_STORE_RETURN}>
              <InStoreReturnFormLazy />
            </Route>
          )}
          {getPermission("pages.access_proactive_restocking") && (
            <Route path={ERoutes.RESTOCKING}>Restocking</Route>
          )}
          {getPermission("pages.access_admin_insights") && (
            <Route path={ERoutes.ADMIN_INSIGHTS}>
              <AdminInsightsLazy />
            </Route>
          )}
          {getPermission("pages.access_permissions") && (
            <Route path={ERoutes.PERMISSIONS}>
              <PermissionsLazy />
            </Route>
          )}
          {getPermission("pages.access_features") && (
            <Route path={ERoutes.FEATURES}>
              <FeaturesConfigLazy />
            </Route>
          )}
          {getPermission("pages.access_user_access") && (
            <Route path={ERoutes.USER_ACCESS}>
              <UserAccessLazy
                onError={(error) => setErrorNotification(error.message)}
              />
            </Route>
          )}
          {getPermission("pages.access_logs") && (
            <Route path={ERoutes.LOGS}>
              <ReleaseLogsLazy />
            </Route>
          )}
          {getPermission("pages.access_feedback") && (
            <Route path={ERoutes.FEEDBACK}>
              <FeedbackLazy />
            </Route>
          )}
          <Route path={ERoutes.FORBIDDEN}>
            <ErrorPageLazy
              code="403"
              description="You dont have enough permissions"
            />
          </Route>
          <Route>
            <ErrorPageLazy code="404" description="Page not found">
              <Link to={ERoutes.HOMEPAGE}>Back to the main page</Link>
            </ErrorPageLazy>
          </Route>
        </Switch>
      </Suspense>
    );
  }
);
