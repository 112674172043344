import { Modal } from "antd";
import React from "react";
import logo from "../../images/logo.svg";

import "./BlockerPopup.scss";

interface IBlockerPopupProps {
  isOpen: boolean;
  title: string;
  message: string;
}

export const BlockerPopup: React.FC<IBlockerPopupProps> = ({
  isOpen,
  title,
  message,
}) => {
  return (
    <Modal
      className="blocker-popup"
      footer={null}
      closable={false}
      open={isOpen}
      centered
    >
      <img className="blocker-popup__logo" src={logo} alt="yayloh-logo" />

      <h3 className="blocker-popup__title">Welcome {title}!</h3>

      <p className="blocker-popup__message">
        {message.split("\\n").map((x, i) => (
          <div key={`${x}-${i}`}>{x}</div>
        ))}
      </p>
    </Modal>
  );
};
