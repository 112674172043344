import { getHeaders } from "./getHeaders";
import { BASE_URL, EParamType } from "../constants";
import {
  IStatisticBarMeter,
  IReturnReasonPieStatistic,
  IReturnReasonEvolutionStatistic,
  IReturnRateStatistic,
  IReturnProductFromBackend,
  ICompareReturnReason,
  IReturnRequestData,
  IOrderByDateInfo,
  ICustomerHistory,
  ICustomerSnapshot,
  IReturnValueStatus,
  ICategoryReturn,
  IBrandReturn,
  IOrderLookupDetailsStatistic,
  INotFoundLookupStatistic,
  IOrphanOrdersImportedStatistic,
  IProductStat,
  IOrderStat,
  ITransactionsOrder,
  IReturnValueOrder,
  IReturnRateInNumbers,
  IReturnReasonTypeEvolutionStatistic,
  IOrderPayload,
  IDatasetsResponse,
  EDatasetType,
  IDataset,
  IDatasetDataResponse,
  IReasonTagStatistic,
  IChannelsParams,
  IAnalyticsChannel,
  IRetentionSplitStatistic,
} from "../models";
import { handleApiResponse } from "../helper/handleApiResponse";
import { getSearchChannelsUrlParams } from "../utils";
import { IDateRangeParams } from "../models";

export function fetchStatisticBarMeter({
  type,
  start,
  end,
}: {
  type: string;
} & IDateRangeParams): Promise<IStatisticBarMeter[]> {
  return fetch(
    `${BASE_URL}/statistic/return_status/processed/${type}/start/${start}/end/${end}/`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  ).then((res) => res.json());
}

export function fetchReturnReasons({
  start,
  end,
  channels = [],
}: Partial<IChannelsParams> & IDateRangeParams): Promise<
  IReturnReasonPieStatistic[]
> {
  return fetch(
    `${BASE_URL}/statistic/return_reasons/start/${start}/end/${end}/?${getSearchChannelsUrlParams(
      channels
    ).toString()}`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  ).then((res) => res.json());
}

export function fetchProductReturnReasons({
  start,
  end,
  productDetailId,
  channels,
}: {
  productDetailId: string | undefined;
} & IChannelsParams &
  IDateRangeParams): Promise<IReturnReasonPieStatistic[]> {
  return fetch(
    `${BASE_URL}/statistic/return_reasons_by_product/${productDetailId}/start/${start}/end/${end}/?${getSearchChannelsUrlParams(
      channels
    ).toString()}`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  ).then((res) => res.json());
}

// NOTE: not in use
// export function fetchReturnReasonsEvolution({
//   start,
//   end,
// }: IDateRangeParams): Promise<IReturnReasonEvolutionStatistic[]> {
//   return fetch(
//     `${BASE_URL}/statistic/reason_evolution/start/${start}/end/${end}/`,
//     {
//       method: "GET",
//       headers: getHeaders(),
//     }
//   ).then((res) => res.json());
// }

export function fetchReturnTypeEvolution({
  start,
  end,
  channels,
}: IChannelsParams & IDateRangeParams): Promise<
  IReturnReasonTypeEvolutionStatistic[]
> {
  return fetch(
    `${BASE_URL}/statistic/return_type_evolution/start/${start}/end/${end}/?${getSearchChannelsUrlParams(
      channels
    ).toString()}`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  ).then((res) => res.json());
}

export function fetchReturnRate({
  start,
  end,
  channels,
}: IChannelsParams & IDateRangeParams): Promise<IReturnRateStatistic[]> {
  return fetch(
    `${BASE_URL}/statistic/return_rate/start/${start}/end/${end}/?${getSearchChannelsUrlParams(
      channels
    ).toString()}`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  ).then((res) => res.json());
}

export function fetchTopReturnedProducts(
  { start, end, channels }: IDateRangeParams & Partial<IChannelsParams>,
  retailerUuid?: string
): Promise<IReturnProductFromBackend[]> {
  return fetch(
    `${BASE_URL}/statistic/top_returned_product/start/${start}/end/${end}/?${
      channels ? getSearchChannelsUrlParams(channels).toString() : ""
    }`,
    {
      method: "GET",
      headers: getHeaders(retailerUuid),
    }
  ).then((res) => res.json());
}

export function fetchMoreReturnedProducts({
  start,
  end,
  itemNumber,
  channels,
}: {
  itemNumber: number;
} & IChannelsParams &
  IDateRangeParams): Promise<IReturnProductFromBackend[]> {
  return fetch(
    `${BASE_URL}/statistic/more_returned_product/from/${itemNumber}/start/${start}/end/${end}/?${getSearchChannelsUrlParams(
      channels
    ).toString()}`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  ).then((res) => res.json());
}

export function fetchCompareReturnReasons({
  start,
  end,
  productDetailId,
  channels,
}: {
  productDetailId: string;
} & IChannelsParams &
  IDateRangeParams): Promise<ICompareReturnReason[]> {
  return fetch(
    `${BASE_URL}/statistic/compare_return_reasons/product/${productDetailId}/start/${start}/end/${end}/?${getSearchChannelsUrlParams(
      channels
    ).toString()}`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  ).then((res) => res.json());
}

export function fetchReasonRevolutionForProduct({
  start,
  end,
  productDetailId,
}: {
  productDetailId: string;
} & IDateRangeParams): Promise<IReturnReasonEvolutionStatistic[]> {
  return fetch(
    `${BASE_URL}/statistic/reason_evolution/product/${productDetailId}/start/${start}/end/${end}/`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  ).then((res) => res.json());
}

export function fetchSimilarTopReturnedProduct({
  start,
  end,
  productDetailId,
  channels,
}: {
  productDetailId: string;
} & IChannelsParams &
  IDateRangeParams): Promise<IReturnProductFromBackend[]> {
  return fetch(
    `${BASE_URL}/statistic/similar_top_returned_product/product/${productDetailId}/start/${start}/end/${end}/?${getSearchChannelsUrlParams(
      channels
    ).toString()}`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  ).then((res) => res.json());
}

export function getReturnRequestByDate({
  start,
  end,
}: IDateRangeParams): Promise<IReturnRequestData[]> {
  return fetch(
    `${BASE_URL}/statistic/return_request_by_date/start/${start}/end/${end}/`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  ).then((res) => res.json());
}

export function getOrdersByDate({
  start,
  end,
}: IDateRangeParams): Promise<IOrderByDateInfo[]> {
  return fetch(
    `${BASE_URL}/statistic/orders_by_date/start/${start}/end/${end}/`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  ).then((res) => res.json());
}

export function getCustomerHistory(
  email: string,
  referenceOrderId: number
): Promise<ICustomerHistory> {
  return fetch(
    `${BASE_URL}/statistic/customer_history/email/${email}/last_order/${referenceOrderId}/`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  ).then((res) => res.json());
}

export function getCustomerSnapshot(email: string): Promise<ICustomerSnapshot> {
  return fetch(`${BASE_URL}/statistic/customer_snapshot/email/${email}/`, {
    method: "GET",
    headers: getHeaders(),
  }).then((res) => res.json());
}

export function getReturnValueStatus(
  currencyCode: string
): Promise<IReturnValueStatus> {
  return fetch(`${BASE_URL}/statistic/return_value_status/${currencyCode}/`, {
    method: "GET",
    headers: getHeaders(),
  }).then((res) => res.json());
}

export function getAllCategoryReturns({
  start,
  end,
  channels,
}: IDateRangeParams & IChannelsParams): Promise<ICategoryReturn[]> {
  return fetch(
    `${BASE_URL}/statistic/all_category_returns/start/${start}/end/${end}/?${getSearchChannelsUrlParams(
      channels
    ).toString()}`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  ).then((res) => res.json());
}

export function getAllCategoryReturnsByBrand({
  brand,
  start,
  end,
  channels,
}: {
  brand: string;
} & IChannelsParams &
  IDateRangeParams): Promise<ICategoryReturn[]> {
  return fetch(
    `${BASE_URL}/statistic/all_category_returns_by_brand/${rfc3986EncodeURIComponent(
      brand
    )}/start/${start}/end/${end}/?${getSearchChannelsUrlParams(
      channels
    ).toString()}`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  ).then((res) => res.json());
}

export function getAllBrandReturns({
  start,
  end,
  channels,
}: IChannelsParams & IDateRangeParams): Promise<IBrandReturn[]> {
  return fetch(
    `${BASE_URL}/statistic/all_brand_returns/start/${start}/end/${end}/?${getSearchChannelsUrlParams(
      channels
    ).toString()}`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  ).then((res) => res.json());
}

export function getAllBrandReturnsByCategory({
  category,
  start,
  end,
  channels,
}: {
  category: string;
} & IChannelsParams &
  IDateRangeParams): Promise<IBrandReturn[]> {
  return fetch(
    `${BASE_URL}/statistic/all_brand_returns_by_category/${rfc3986EncodeURIComponent(
      category
    )}/start/${start}/end/${end}/?${getSearchChannelsUrlParams(
      channels
    ).toString()}`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  ).then((res) => res.json());
}

// NOTE: not used anywhere
export function getTopReturnProductByCategoryAndBrand({
  brand,
  category,
  start,
  end,
  channels,
}: {
  brand: string;
  category: string;
} & IChannelsParams &
  IDateRangeParams): Promise<IReturnProductFromBackend[]> {
  return fetch(
    `${BASE_URL}/statistic/top_returned_product/category/${rfc3986EncodeURIComponent(
      category
    )}/brand/${rfc3986EncodeURIComponent(
      brand
    )}/start/${start}/end/${end}/?${getSearchChannelsUrlParams(
      channels
    ).toString()}`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  ).then((res) => res.json());
}

export function getTopReturnProductByCategory({
  category,
  start,
  end,
  channels,
}: {
  category: string;
} & IChannelsParams &
  IDateRangeParams): Promise<IReturnProductFromBackend[]> {
  return fetch(
    `${BASE_URL}/statistic/top_returned_product_by_category/${rfc3986EncodeURIComponent(
      category
    )}/start/${start}/end/${end}/?${getSearchChannelsUrlParams(
      channels
    ).toString()}`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  ).then((res) => res.json());
}

export function getTopReturnProductByBrand({
  brand,
  start,
  end,
  channels,
}: {
  brand: string;
} & IChannelsParams &
  IDateRangeParams): Promise<IReturnProductFromBackend[]> {
  return fetch(
    `${BASE_URL}/statistic/top_returned_product_by_brand/${rfc3986EncodeURIComponent(
      brand
    )}/start/${start}/end/${end}/?${getSearchChannelsUrlParams(
      channels
    ).toString()}`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  ).then((res) => res.json());
}

export function getReturnReasonsByCategory({
  category,
  start,
  end,
  channels,
}: {
  category: string;
} & IChannelsParams &
  IDateRangeParams): Promise<IReturnReasonPieStatistic[]> {
  return fetch(
    `${BASE_URL}/statistic/return_reasons_by_category/${rfc3986EncodeURIComponent(
      category
    )}/start/${start}/end/${end}/?${getSearchChannelsUrlParams(
      channels
    ).toString()}`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  ).then((res) => res.json());
}

export function getReturnReasonsByBrand({
  brand,
  start,
  end,
  channels,
}: {
  brand: string;
} & IChannelsParams &
  IDateRangeParams): Promise<IReturnReasonPieStatistic[]> {
  return fetch(
    `${BASE_URL}/statistic/return_reasons_by_brand/${rfc3986EncodeURIComponent(
      brand
    )}/start/${start}/end/${end}/?${getSearchChannelsUrlParams(
      channels
    ).toString()}`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  ).then((res) => res.json());
}

export function getReturnReasonsByCategoryAndBrand({
  brand,
  category,
  start,
  end,
  channels,
}: {
  brand: string;
  category: string;
} & IChannelsParams &
  IDateRangeParams): Promise<IReturnReasonPieStatistic[]> {
  return fetch(
    `${BASE_URL}/statistic/return_reasons/category/${rfc3986EncodeURIComponent(
      category
    )}/brand/${rfc3986EncodeURIComponent(
      brand
    )}/start/${start}/end/${end}/?${getSearchChannelsUrlParams(
      channels
    ).toString()}`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  ).then((res) => res.json());
}

function rfc3986EncodeURIComponent(str: string) {
  return encodeURIComponent(str.replace("'", "\\'"));
}

export function fetchOrderLookupDetails({
  start,
  end,
}: IDateRangeParams): Promise<IOrderLookupDetailsStatistic[]> {
  return fetch(
    `${BASE_URL}/statistic/order_lookup_details/start_date/${start}/end_date/${end}/`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  ).then((res) => res.json());
}

export function getProductStatistic({
  productName,
  start,
  end,
  channels,
}: {
  productName: string;
} & IChannelsParams &
  IDateRangeParams): Promise<IProductStat> {
  return fetch(
    `${BASE_URL}/statistic/product/${productName}/start/${start}/end/${end}/?${getSearchChannelsUrlParams(
      channels
    ).toString()}`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  ).then((res) => res.json());
}

export function fetchNotFoundLookupByDate({
  start,
  end,
}: IDateRangeParams): Promise<INotFoundLookupStatistic[]> {
  return fetch(
    `${BASE_URL}/statistic/not_found_lookups/start_date/${start}/end_date/${end}/`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  ).then((res) => res.json());
}

export function fetchOrphanOrdersImported({
  start,
  end,
}: IDateRangeParams): Promise<IOrphanOrdersImportedStatistic[]> {
  return fetch(
    `${BASE_URL}/statistic/orphan_orders_imported/start_date/${start}/end_date/${end}/`,
    { method: "GET", headers: getHeaders() }
  ).then((res) => res.json());
}

export function getOrderStatistic({
  extOrderId,
}: {
  extOrderId: string;
}): Promise<IOrderStat> {
  return fetch(`${BASE_URL}/statistic/order/by_ext_id/${extOrderId}/v1/`, {
    method: "GET",
    headers: getHeaders(),
  }).then((res) => res.json());
}

export function getReturnValueOrder(
  orderId: number
): Promise<IReturnValueOrder> {
  return fetch(`${BASE_URL}/order/return_value/order_id/${orderId}/`, {
    method: "GET",
    headers: getHeaders(),
  }).then((res) => res.json());
}

export function getTransactionsOrder(
  orderId: number
): Promise<ITransactionsOrder[]> {
  return fetch(`${BASE_URL}/transactions/logs_by/order_id/${orderId}/`, {
    method: "GET",
    headers: getHeaders(),
  }).then((res) => res.json());
}

export function fetchReturnRateInNumbers({
  start,
  end,
  channels,
}: IChannelsParams & IDateRangeParams): Promise<IReturnRateInNumbers> {
  return fetch(
    `${BASE_URL}/statistic/return_rate/total/per_time_period/start_date/${start}/end_date/${end}/?${getSearchChannelsUrlParams(
      channels
    ).toString()}`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  ).then((res) => res.json());
}

export function getTransactionsCustomer(
  customerEmail: string
): Promise<ITransactionsOrder[]> {
  return fetch(`${BASE_URL}/transactions/logs_by/email/${customerEmail}/`, {
    method: "GET",
    headers: getHeaders(),
  }).then((res) => res.json());
}

export function updateOrder(data: IOrderPayload) {
  return fetch(`${BASE_URL}/update/order/`, {
    method: "PUT",
    headers: getHeaders(),
    body: JSON.stringify(data),
  }).then((res) => res.json());
}

export function getAllDatasets(): Promise<IDatasetsResponse> {
  if (process.env.REACT_APP_IS_DEBUG === "true") {
    return Promise.resolve({
      datasets: [
        {
          id: 1,
          name: "Customers retained",
          description:
            "customers who bought without 30 days of returning a product",
          type: EDatasetType.SIMPLE,
          params: [],
          data_to_download: {
            csv: [
              {
                name: "Mar 2022",
                url: "https://content.yayloh.com/Retention_20220329.csv",
                params: [
                  { value: "2022-01-01", parameter: "from_date" },
                  { value: "2022-02-31", parameter: "end_date" },
                ],
                generated_on: "2022-03-14 15:00:00",
              },
            ],
          },
        },
        {
          id: 1,
          name: "Customers retained",
          description:
            "customers who bought without 30 days of returning a product",
          type: EDatasetType.PREPARED,
          params: [
            {
              label: "Start date",
              parameter: "start_date",
              type: EParamType.DATETIME,
            },
            {
              label: "End date",
              parameter: "end_date",
              type: EParamType.DATETIME,
            },
          ],
          data_to_download: {
            csv: [
              {
                name: "Mar 2022",
                url: "https://content.yayloh.com/Retention_20220329.csv",
                params: [
                  { value: "2022-01-01", parameter: "from_date" },
                  { value: "2022-02-31", parameter: "end_date" },
                ],
                generated_on: "2022-03-14 15:00:00",
              },
            ],
          },
        },
        {
          id: 1,
          name: "Customers retained",
          description:
            "customers who bought without 30 days of returning a product",
          type: EDatasetType.SCHEDULE,
          params: [],
          data_to_download: {
            csv: [
              {
                name: "Mar 2022",
                url: "https://content.yayloh.com/Retention_20220329.csv",
                params: [
                  { value: "2022-01-01", parameter: "from_date" },
                  { value: "2022-02-31", parameter: "end_date" },
                ],
                generated_on: "2022-03-14 15:00:00",
              },
            ],
          },
        },
      ],
    });
  }
  return fetch(`${BASE_URL}/statistic/datasets/all/`, {
    method: "GET",
    headers: getHeaders(),
  }).then(handleApiResponse);
}

export function getDataset(id: string): Promise<{
  dataset: IDataset;
}> {
  return fetch(`${BASE_URL}/statistic/datasets/${id}/`, {
    method: "GET",
    headers: getHeaders(),
  }).then(handleApiResponse);
}

export function getDatasetData(
  id: string,
  urlParams: URLSearchParams
): Promise<IDatasetDataResponse> {
  if (process.env.REACT_APP_IS_DEBUG === "true") {
    return Promise.resolve({
      data: [
        {
          order_count: 5,
          order_date: "2022-04-27 00:00:00",
        },
        {
          order_count: 3,
          order_date: "2022-04-28 00:00:00",
        },
        {
          order_count: 1,
          order_date: "2022-05-03 00:00:00",
        },
        {
          order_count: 2,
          order_date: "2022-05-17 00:00:00",
        },
      ],
      metadata: [
        {
          column_name: "order_date",
          label: "Order date",
          type: "datetime",
        },
        {
          column_name: "order_count",
          label: "Order count",
          type: "integer",
        },
      ],
    });
  }

  return fetch(
    `${BASE_URL}/statistic/datasets/${id}/data/?${urlParams.toString()}`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  ).then(handleApiResponse);
}

export const getReasonTagStatistic = ({
  productDetailId,
  startDate,
  endDate,
  reasonId,
  channels,
}: {
  productDetailId: number;
  startDate: string;
  endDate: string;
  reasonId: number;
} & IChannelsParams): Promise<IReasonTagStatistic[]> => {
  return fetch(
    `${BASE_URL}/statistic/returns_by_tag_by_size_by_reason_by_product/${productDetailId}/start/${startDate}/end/${endDate}/reason/${reasonId}/?${getSearchChannelsUrlParams(
      channels
    ).toString()}`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  ).then(handleApiResponse);
};

export const getAnalyticsChannels = (): Promise<IAnalyticsChannel[]> => {
  return fetch(`${BASE_URL}/statistic/all_source_channel/`, {
    method: "GET",
    headers: getHeaders(),
  }).then(handleApiResponse);
};

export const getRetentionSplitStatistic = ({
  start: startDate,
  end: endDate,
}: {
  start: string;
  end: string;
}): Promise<IRetentionSplitStatistic[]> => {
  if (process.env.REACT_APP_IS_DEBUG === "true") {
    return Promise.resolve([
      {
        count: 1,
        type: "refund",
        percent: 33,
      },
      {
        count: 1,
        type: "store-credit",
        percent: 33,
      },
      {
        count: 1,
        type: "exchange",
        percent: 33,
      },
    ]);
  }
  return fetch(
    `${BASE_URL}/statistic/retention_split/start/${startDate}/end/${endDate}/`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  ).then(handleApiResponse);
};
