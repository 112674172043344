import React from "react";
import classNames from "classnames";

import "./ProgressBar.scss";

interface IProgressBarProps {
  progress: number;
  className?: string;
}

export const ProgressBar = ({ progress = 0, className }: IProgressBarProps) => {
  return (
    <div className={classNames("progress-bar", className)}>
      <div
        className="progress-bar__inner"
        style={{
          width: `${progress}%`,
        }}
      />
    </div>
  );
};
