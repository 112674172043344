import React from "react";

import "./RatingSmiley.scss";

interface IRatingSmileyProps {
  rating: number;
}

const renderRatingSmiley = (rating: number) => {
  // NOTE: Uncomment when needed, currently rating less that 4 gets a bad rating smiley
  switch (rating) {
    case 1: // return <i className="demo-icon icon-bad_rating">&#xe816;</i>;
    case 2: // return <i className="demo-icon icon-ok_rating">&#xe817;</i>;
    case 3:
      // return <i className="demo-icon icon-good_rating">&#xe814;</i>;
      return <i className="demo-icon icon-bad_rating">&#xe816;</i>;
    case 4:
    case 5: // return <i className="demo-icon icon-very_good_rating">&#xe815;</i>;
    default:
      return null;
  }
};

export const RatingSmiley = ({ rating }: IRatingSmileyProps) => {
  return <div className="rating-smiley">{renderRatingSmiley(rating)}</div>;
};
