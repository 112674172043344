import * as React from "react";

interface IFormattedPriceProps {
  price: number;
  currency_code: string;
}

export function FormattedPrice({ price, currency_code }: IFormattedPriceProps) {
  return (
    <span>
      {new Intl.NumberFormat("en", {
        style: "currency",
        currency: currency_code,
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      }).format(price)}
    </span>
  );
}
