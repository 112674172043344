import "./SettingsLayout.scss";

import React from "react";
import { SettingsNavigation } from "../SettingsNavigation/SettingsNavigation";
import classNames from "classnames";

interface ISettingsLayout {
  className?: string;
}

export class SettingsLayout extends React.Component<ISettingsLayout> {
  render() {
    return (
      <div
        className={classNames("settings-layout", this.props.className || "")}
      >
        <div className="settings-layout__sidebar">
          <SettingsNavigation />
        </div>
        <div className="settings-layout__content">{this.props.children}</div>
      </div>
    );
  }
}
