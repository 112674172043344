import React from "react";

import "./FeatureDescription.scss";

interface IFeatureDescriptionProps {
  summary: string;
  description: string;
}

export const FeatureDescription: React.FC<IFeatureDescriptionProps> = ({
  summary,
  description,
}) => {
  return (
    <p className="feature-description">
      <span className="feature-description__text">{summary}</span>{" "}
      <span className="feature-description__text feature-description__text_light">
        {description}
      </span>
    </p>
  );
};
