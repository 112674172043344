import { Select } from "antd";
import classNames from "classnames";
import React, { useCallback } from "react";

import "./SelectOption.scss";

export interface IOption {
  value: string | number;
  title: string;
}
export type TSelectionChangeFunctionParams =
  | string
  | string[]
  | number
  | number[];
interface ISelectOptionProps {
  options: IOption[];
  onSelectionChange?: (
    selectedValue: TSelectionChangeFunctionParams,
    name?: any
  ) => void;
  selectClassName?: string;
  label?: string;
  value?: string | string[] | number | number[];
  placeholder?: string;
  name?: string;
  isMultipleSelect?: boolean;
}

export const SelectOption: React.FC<ISelectOptionProps> = ({
  options,
  onSelectionChange,
  selectClassName,
  label,
  value,
  placeholder,
  name,
  isMultipleSelect,
}) => {
  const handleSelectionChange = useCallback(
    (value: TSelectionChangeFunctionParams) => {
      if (!onSelectionChange) {
        return;
      }

      onSelectionChange(value, name);
    },
    [onSelectionChange, name]
  );

  return (
    <div className={classNames("select-option", selectClassName)}>
      {label && <p className="select-option__label">{label}</p>}

      <Select
        showSearch
        value={value}
        placeholder={placeholder || "Select"}
        className="select-option__select"
        onChange={handleSelectionChange}
        mode={isMultipleSelect ? "multiple" : undefined}
        filterOption={(input, option) => {
          if (!option?.children) {
            return false;
          }

          return String(option.children)
            .toLowerCase()
            .includes(input.toLowerCase());
        }}
      >
        {options &&
          options.map((option) => {
            return (
              <Select.Option
                key={`${option.value}-${option.title}`}
                value={option.value}
              >
                {option.title}
              </Select.Option>
            );
          })}
      </Select>
    </div>
  );
};
