import "./SideNavigation.scss";

import React from "react";
import { NavLink } from "react-router-dom";

import { Heading } from "../Heading/Heading";
import { uniqueId } from "lodash";

interface IRoute {
  to: string;
  label: string;
  exact?: boolean;
}

interface ISideNavigationProps {
  heading: string;
  routes: IRoute[];
}

export const SideNavigation: React.FC<ISideNavigationProps> = (props) => {
  return (
    <div className="side-navigation">
      <Heading text={props.heading} />
      <div className="side-navigation__navigation" data-cy="navLink">
        {props.routes.map((route) => {
          return (
            <NavLink
              key={uniqueId(route.to)}
              className="side-navigation__link"
              activeClassName="side-navigation__link_active"
              exact={route.exact}
              to={route.to}
            >
              {route.label}
            </NavLink>
          );
        })}
      </div>
    </div>
  );
};
