import { ArrowLeftOutlined } from "@ant-design/icons";
import dayjs, { Dayjs } from "dayjs";
import { isEmpty, isEqual } from "lodash";
import React, { useState, useEffect, useCallback, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import {
  getSegmentSettingsById,
  getSegmentSettingsHistoryById,
  updateSegmentSettingsById,
} from "../../../../../../../api/settings";
import {
  API_ERROR,
  EVIPSettingsParam,
  UTC_API_FORMAT,
} from "../../../../../../../constants";
import { NotificationContext } from "../../../../../../../context/NotificationContext";
import { UserContext } from "../../../../../../../context/UserContext";
import {
  ISegmentEditHistory,
  ISegmentWithParams,
} from "../../../../../../../models";
import { ERoutes } from "../../../../../../../routes";
import { Loader } from "../../../../../../Loader/Loader";
import { TSelectionChangeFunctionParams } from "../../../../../../SelectOption/SelectOption";
import { SettingsLayout } from "../../../../SettingsLayout/SettingsLayout";
import { CurrentSettings } from "./components/CurrentSettings/CurrentSettings";
import { EditHistory } from "./components/EditHistory/EditHistory";

import "./SegmentSettings.scss";

interface ISegmentSettingsProps {}

export const SegmentSettings: React.FC<ISegmentSettingsProps> = () => {
  const { setErrorNotification } = useContext(NotificationContext);
  const { userProfile, genericUserId } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [segmentSettings, setSegmentSettings] = useState<ISegmentWithParams>();
  const [editHistory, setEditHistory] = useState<ISegmentEditHistory[]>([]);
  const [isUpdateDisabled, setIsUpdateDisabled] = useState(true);
  const [originalSegmentSettings, setOriginalSegmentSettings] =
    useState<ISegmentWithParams>();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    setIsLoading(true);

    Promise.all([getSegmentSettingsById(id), getSegmentSettingsHistoryById(id)])
      .then(([segmentSettings, editHistory]) => {
        setSegmentSettings(segmentSettings.details.segment);
        setOriginalSegmentSettings(segmentSettings.details.segment);

        setEditHistory(editHistory.details.versions);
      })
      .catch((err) => {
        console.error(err);
        setErrorNotification(API_ERROR);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [
    setSegmentSettings,
    setIsLoading,
    setEditHistory,
    setOriginalSegmentSettings,
    setErrorNotification,
    id,
  ]);

  useEffect(() => {
    setIsUpdateDisabled(
      isEqual(segmentSettings?.params, originalSegmentSettings?.params) ||
        isEmpty(segmentSettings)
    );
  }, [segmentSettings, originalSegmentSettings]);

  const handleSegmentSettingsUpdate = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();

      if (!segmentSettings || !originalSegmentSettings) {
        return;
      }

      setIsLoading(true);

      updateSegmentSettingsById(id, {
        enabled: segmentSettings.enabled,
        params: Object.entries(segmentSettings.params).reduce(
          (acc, [paramName, paramValue]) => {
            return {
              ...acc,
              [paramName]: paramValue.value,
            };
          },
          {}
        ),
      })
        .then(() => {
          setEditHistory((editHistory) => [
            {
              enabled: originalSegmentSettings?.enabled,
              params: originalSegmentSettings?.params,
              update_date: dayjs().format("YYYY-MM-DD HH:mm:ss"),
              updated_by: {
                email: userProfile?.email || "",
                user_id: genericUserId,
              },
            },
            ...editHistory,
          ]);

          setOriginalSegmentSettings(segmentSettings);
        })
        .catch((err) => {
          console.error(err);
          setErrorNotification(API_ERROR);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [
      segmentSettings,
      originalSegmentSettings,
      setIsLoading,
      setOriginalSegmentSettings,
      setErrorNotification,
      id,
      genericUserId,
      userProfile,
      setEditHistory,
    ]
  );

  const handleSegmentSettingsChange = useCallback(
    (value: TSelectionChangeFunctionParams, name?: EVIPSettingsParam) => {
      if (!name) {
        return;
      }

      setSegmentSettings((segmentSettings) =>
        segmentSettings
          ? {
              ...segmentSettings,
              params: {
                ...segmentSettings.params,
                [name]: {
                  ...segmentSettings.params[name],
                  value,
                },
              },
            }
          : undefined
      );
    },
    [setSegmentSettings]
  );

  const handleSegmentSettingsDateChange = useCallback(
    (date: Dayjs | null) => {
      if (!date) {
        return;
      }

      setSegmentSettings((segmentSettings) =>
        segmentSettings
          ? {
              ...segmentSettings,
              params: {
                ...segmentSettings.params,
                [EVIPSettingsParam.ACTIVE_SINCE]: {
                  ...segmentSettings.params.active_since,
                  value: date.format(UTC_API_FORMAT),
                },
              },
            }
          : undefined
      );
    },
    [setSegmentSettings]
  );

  return (
    <SettingsLayout className="vip-settings">
      <div className="vip-settings__header">
        <Link to={ERoutes.SETTINGS_SEGMENTS} className="vip-settings__link">
          <ArrowLeftOutlined /> VIP Settings
        </Link>
      </div>

      <Loader isLoading={isLoading}>
        <div className="vip-settings__content">
          <CurrentSettings
            segmentSettings={segmentSettings}
            originalSegmentSettings={originalSegmentSettings}
            handleSegmentSettingsChange={handleSegmentSettingsChange}
            handleSegmentSettingsUpdate={handleSegmentSettingsUpdate}
            handleSegmentSettingsDateChange={handleSegmentSettingsDateChange}
            isUpdateDisabled={isUpdateDisabled}
          />

          <EditHistory editHistory={editHistory} />
        </div>
      </Loader>
    </SettingsLayout>
  );
};
