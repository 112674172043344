import React from "react";
import { IPermissions, IPermissionContext } from "../models";
import get from "lodash-es/get";

export const DEFAULT_PERMISSIONS = {
  pages: {
    access_sign_up: true,
    access_return_form: true,
    access_homepage: true,
    access_homepage_old: false,
    actions: false,
    access_insights_product: false,
    access_insights_overview: false,
    access_insights_brand: false,
    access_insights_category: false,
    access_proactive_restocking: false,
    access_returns_forecast: false,
    access_permissions: false,
    access_features: false,
    access_user_access: false,
    access_logs: false,
    access_admin_insights: false,
    insights: false,
    instore: true,
    access_features_alert: false,
    access_settings_brand: false,
    access_settings_stores_and_warehouses: false,
    access_settings_email_templates: false,
    access_settings_sms_templates: false,
    access_settings_return_reasons: false,
    access_settings_translations: false,
    access_settings_self_translation: false,
    access_settings_imports: false,
    access_settings_pricing_plan: false,
    access_settings_returns: false,
    access_settings_integrations: false,
    access_settings_tms_configuration: false,
    access_settings_automation: false,
    access_settings_refund_rules: false,
    access_settings_segments: false,
    access_settings_exchange: false,
    access_feedback: true,
    access_gorgias_integration_page: true,
  },
  features: {
    alter_feature_access: true,
    alter_page_access: true,
    approver: false,
    shipping_label: false,
    auto_refund: false,
    restocking: false,
  },
  roles: {
    isRetailerAdmin: false,
    isShopifyRetailer: false,
  },
};

export function createGetPermission(
  permissions: IPermissions,
  isSuperadmin: boolean
) {
  return (permission: string) => {
    const [, permissionName] = permission.split(".");
    const originalPermission = get(permissions, permission, false);

    if (permissionName.indexOf("access_insights") === 0) {
      return isSuperadmin || permissions.pages.insights;
    }

    if (
      permissionName.indexOf("access_settings_automation") === 0 ||
      permissionName.indexOf("access_settings_refund_rules") === 0 ||
      permissionName.indexOf("access_settings_segments") === 0 ||
      permissionName.indexOf("access_homepage_old") === 0
    ) {
      return isSuperadmin;
    }

    if (permissionName.indexOf("access_settings_pricing_plan") === 0) {
      return (
        permissions.roles.isShopifyRetailer &&
        (isSuperadmin || permissions.roles.isRetailerAdmin)
      );
    }

    if (permissionName.indexOf("access_settings_self_translation_v2") === 0) {
      return isSuperadmin;
    }

    if (
      permissionName.indexOf("access_user_access") === 0 ||
      permissionName.indexOf("access_settings_tms_configuration") === 0 ||
      permissionName.indexOf("access_settings_brand") === 0 ||
      permissionName.indexOf("access_settings_exchange") === 0 ||
      permissionName.indexOf("access_settings_stores_and_warehouses") === 0 ||
      permissionName.indexOf("access_settings_email_templates") === 0 ||
      permissionName.indexOf("access_settings_reasons") === 0 ||
      permissionName.indexOf("access_settings_integrations") === 0 ||
      permissionName.indexOf("access_settings_self_translation") === 0 ||
      permissionName.indexOf("access_settings_api_keys") === 0 ||
      permissionName.indexOf("access_settings_categories") === 0 ||
      permissionName.indexOf("access_settings_blocked_returns") === 0 ||
      permissionName.indexOf("access_settings_shipping_rules") === 0
    ) {
      return isSuperadmin || permissions.roles.isRetailerAdmin;
    }

    return isSuperadmin || originalPermission;
  };
}

export const PermissionsContext = React.createContext<IPermissionContext>({
  getPermission: createGetPermission(DEFAULT_PERMISSIONS, false),
  isSuperadmin: false,
  isRetailerAdmin: false,
});
