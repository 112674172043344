import React from "react";
import dayjs from "dayjs";
import { IRetailerContent } from "../../../../drf-fe/src/models";
import halfCircleBrandSrc from "../../../../images/half-circle-brand.svg";
import triangleBrandSrc from "../../../../images/triangle-brand.svg";

import "./PDFHeroSection.scss";

interface IPDFHeroSectionProps {
  retailerContent: IRetailerContent;
}

export const PDFHeroSection: React.FC<IPDFHeroSectionProps> = ({
  retailerContent,
}) => {
  return (
    <div className="pdf-hero-section">
      <div className="pdf-hero-section__text">
        <div className="pdf-hero-section__tag">
          {dayjs(new Date()).format("MMMM YYYY")}
        </div>
        <div className="pdf-hero-section__title">
          Monthly return data report
        </div>

        <img
          className="pdf-hero-section__yayloh-brand pdf-hero-section__yayloh-brand_circle"
          src={halfCircleBrandSrc}
          alt=""
        />

        <img
          className="pdf-hero-section__yayloh-brand pdf-hero-section__yayloh-brand_triangle"
          src={triangleBrandSrc}
          alt=""
        />
      </div>

      <div
        className="pdf-hero-section__brand"
        style={{
          backgroundSize: "contain",
          backgroundImage: `url(${retailerContent?.large_banner_url || ""})`,
        }}
      >
        <div className="pdf-hero-section__brand-background-filter" />
        <img
          className="pdf-hero-section__logo"
          src={retailerContent?.brand_logo_url}
          alt="Logo"
        />
      </div>
    </div>
  );
};
