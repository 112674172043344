import React from "react";
import { ISegmentWithParams } from "../../../../../../../../../../../models";
import { PreviewSetting } from "./PreviewSetting/PreviewSetting";

import "./Preview.scss";
import { EVIPSettingsParam } from "../../../../../../../../../../../constants";

interface IPreviewProps {
  segmentSettings: ISegmentWithParams | undefined;
}

export const Preview = ({ segmentSettings }: IPreviewProps) => {
  return (
    <div className="preview">
      <h3 className="preview__title">Current VIP Settings</h3>

      <div className="preview__content">
        <PreviewSetting
          title={
            segmentSettings?.params[EVIPSettingsParam.NUMBER_OF_ORDERS].label ||
            ""
          }
          value={
            segmentSettings?.params[EVIPSettingsParam.NUMBER_OF_ORDERS].value
          }
        />
        <PreviewSetting
          title={segmentSettings?.params[EVIPSettingsParam.REVENUE].label || ""}
          value={segmentSettings?.params[EVIPSettingsParam.REVENUE].value}
        />
        <PreviewSetting
          title={
            segmentSettings?.params[EVIPSettingsParam.ACTIVE_SINCE].label || ""
          }
          value={segmentSettings?.params[EVIPSettingsParam.ACTIVE_SINCE].value}
        />
        <PreviewSetting
          title={
            segmentSettings?.params[EVIPSettingsParam.LAST_ORDER_SIZE].label ||
            ""
          }
          value={
            segmentSettings?.params[EVIPSettingsParam.LAST_ORDER_SIZE].value
          }
        />
        <PreviewSetting
          title={
            segmentSettings?.params[EVIPSettingsParam.TOTAL_RETURNS].label || ""
          }
          value={segmentSettings?.params[EVIPSettingsParam.TOTAL_RETURNS].value}
        />
      </div>

      <div className="preview__last-edited">
        Last edited by:
        <div className="preview__edited-by">
          <strong>{segmentSettings?.updated_by.email}</strong>{" "}
          {segmentSettings?.update_date}
        </div>
      </div>
    </div>
  );
};
