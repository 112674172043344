import React, { useMemo } from "react";
import { TooltipProps } from "recharts";

import "./RetentionTooltip.scss";

interface IRetentionTooltipProps {
  tooltipProps: TooltipProps<any, any>;
}

export const RetentionTooltip: React.FC<IRetentionTooltipProps> = ({
  tooltipProps,
}) => {
  const payload = useMemo(
    () => (tooltipProps.payload ? tooltipProps.payload[0] : null),
    [tooltipProps]
  );

  return (
    <div className="retention-tooltip">
      <div>{tooltipProps.label}</div>

      <p className="retention-tooltip__amount">
        Exchange amount: {payload?.payload.exchange_amount}
      </p>

      <p className="retention-tooltip__amount">
        Store credit amount: {payload?.payload.store_credit_amount}
      </p>
    </div>
  );
};
