import { ITmsAddress } from "./components/TmsCredentials/TmsAddress/TmsAddress";
import {
  EParamType,
  EFeedbackFilterKeys,
  EVIPSettingsParam,
  EFeatureType,
  EImpactAreaType,
  EFeatureTypeName,
} from "./constants";
import { EDateFilterOptions } from "./context/ReturnManagementContext";
import { EBillingPlan } from "./models/retailer";

export interface IExchangeProductParameter {
  name: string;
  value: string;
}

export type TExchangeProduct = {
  product_name: string;
  content_url: string;
  product_parameters: IExchangeProductParameter[];
};

export interface IItem {
  color: string | null;
  content_url: string;
  currency_code: string;
  customer_comment: null | string;
  delivery_date: null | string;
  ext_order_id: string;
  has_in_store_return: boolean | null;
  order_detail_id: number;
  brand?: null | string;
  order_date: string;
  order_id: number;
  original_price: number;
  product_page_url: string;
  product_name: string;
  payment_details?: IPaymentDetails;
  quantity?: number;
  selling_price: number;
  ship_date: string | null;
  size: string | null;
  sku_number: string | null;
  approved_by: null | string;
  approved_date: null | string;
  is_approved: null | boolean;
  is_discount: boolean;
  ordered_quantity: number;
  reason_code: string | null;
  // NOTE: null if not returned
  reason_id: null | number;
  reason_text: null | string;
  requested_refund_method_id: null | number;
  return_type: "return" | "claim" | "exchange" | null;
  returned_quantity: null | number;
  refusal_comment: null | string;
  store_name: string | null;
  has_internal_comment: boolean;
  store_label: string | null;
  image_url: string[] | null;
  chosen_refund_method_id: null | number;
  order_shipment_id?: number | null;
  shipping_company?: string | null;
  tracking_status?: string;
  tracking_url?: string | null;
  tracking_id?: string | null;
  country_code?: null | string;
  tariff_number?: number | null;
  return_reason_tags?: string[] | null;
  exchange_product?: TExchangeProduct | null;
}

export interface IOrderLink {
  order: {
    type: "exchange" | "original" | "draft_order";
    id: number;
    ext_order_id?: string;
    order_lines: IOrderLines[];
  };
}

export interface IOrderLines {
  original_order_line_id: number;
  product: {
    variant: {
      id: number;
      options: {
        name: string;
        value: string;
      }[];
      inventory_level: {
        low_inventory_alert: boolean;
        available_quantity: number;
      };
    };
  };
}

export interface IPaymentDetails {
  id: number;
  method_name: string;
  description: string;
}

export interface IOrderLinks {
  id: number;
  rating: number;
  feedback_comment: string;
  payment_details: IPaymentDetails;
}

export interface IOrderDetailsLookupResponse {
  order: IOrderLinks;
  order_details: IItem[];
  shipping_cost: number;
  tax: number;
}

export interface IFormData {
  brand_color: string;
  customer_css: string;
  brand_logo_url: string;
  welcome_text: string;
  banners: IBanner[];
  customer_support_email: string;
  retailer_website_url: string;
  feedback: boolean;
  tracking_page_wide_banner_url: string | null;
  tracking_page_square_banner_url: string | null;
  tracking_page_banner_overlay: string | null;
  welcome_page_large_banner_url: string | null;
  tracking_page_banner_redirect_url: string | null;
  return_policy_url: string | null;
  terms_and_conditions_url: string | null;
  drf_welcome_screen_content: string | null;
  drf_thank_you_screen_claim_text: string | null;
  drf_thank_you_screen_exchange_text: string | null;
  drf_thank_you_screen_return_text: string | null;
  drf_items_screen_title_text: string | null;
  drf_items_screen_content: string | null;
  translation: string;
  valid_return_threshold_in_days: string | null;
}

export interface IOrder {
  data: IOrderItem[];
  total_count: number;
}
export interface IOrderPage {
  email: string;
  order_id: number;
}

export interface IOrderItem {
  country_code: null | string;
  currency_code: string;
  email: string;
  name: string;
  ext_order_id: string;
  new_ext_order_id: string;
  ext_order_url: string;
  has_user_comment: boolean;
  market: null | string;
  order_date: string;
  order_id: number;
  order_price: number;
  order_shipment_id: number | null;
  order_shipping_label_id: null | number;
  has_in_store_return: boolean;
  return_price: number;
  return_item_count: number;
  return_item_price: number;
  total_quantity: null | number;
  payment_details: IPaymentDetails | null;
  return_type_summary: string;
  request_date: string;
  rating: number;
  feedback_comment: string;
  shipping_company: string | null;
  tracking_status: string;
  tracking_url: string;
  tracking_id: string;
  assigned_to: number | null;
  picture: string;
  user_name: string;
  shipping_label_s3_url: string | null;
  label_error_message: string | null;
  has_exchange_order: boolean;
  invoice_url: string;
}

export interface IOrderDetail {
  order_detail_id: number;
  quantity: number;
  reason_id: number;
}

export interface IReturnRequest {
  order_id: string;
  order_details: IOrderDetail[];
}

export interface IDictionary<T> {
  [key: string]: T;
}

export interface IBanner {
  banner_url: string;
}

export interface IQueryParams {
  page: number;
  pagesize: number;
  start: string;
  end: string;
  keywords: string;
  direction: string;
  sortby: string;
  processed: "0" | "1" | "2";
  filterby: string;
  order: string[];
  tracking: string[];
}

export interface IFilterQueryParams {
  start: string;
  end: string;
  keywords: string;
  filterby: EDateFilterOptions;
  filtercategory: string;
  filteritem: string;
  processed: "0" | "1" | "2";
}

export interface IFilterActionManageParams {
  start: string;
  end: string;
  keywords: string;
  filtercategory: string;
  filteritem: string;
}

export interface IReturnProduct {
  content_url: string;
  product_name: string;
  return_rate: number;
  return_reasons: {
    return_text: string;
    return_rate: number;
  }[];
}

export interface IReturnReasonPieStatistic {
  count: number;
  percent: number;
  reason_code: string;
  reason_text: string;
}

export interface IReturnReasonEvolutionStatistic {
  count: number;
  order_date: string;
  percent: number;
  reason_code: string;
  reason_text: string;
}

export interface IReturnReasonTypeEvolutionStatistic {
  count: number;
  percent: number;
  return_type: string;
}

export interface IReturnRateStatistic {
  order_date: string;
  ordered_qty: number;
  rate: number;
  returned_qty: number;
}

export interface IBaseReturnProduct {
  content_url: string;
  ordered_qty: number;
  product_detail_id: number;
  product_name: string;
  product_page_url: string;
  returned_qty: number;
  top_reasons?: {
    count: number;
    percent: number;
    reason_code: string;
    reason_text: string;
  }[];
}

export interface IReturnProductFromBackend extends IBaseReturnProduct {}

export interface ICompareReturnReason {
  count: number;
  dimension: string;
  percent: number;
  reason_code: string;
  reason_text: string;
}

export interface ICustomerAddress {
  address: string | undefined;
  city: string | undefined;
  country_code: string | undefined;
  zipcode: string | undefined;
  name?: string;
  phone_number?: null | string;
  province_code?: string;
}

export interface ICustomerInfo {
  billing_customer: ICustomerAddress;
  shipping_customer: ICustomerAddress;
  order: {
    delivery_date: null | string;
    email: string;
    ext_order_id: string;
    order_date: string;
    order_id: number;
    ship_date: string;
  };
}

export interface IProcessOrderItem {
  order_detail_id: number;
  is_approved: 1 | 0;
  refusal_comment: string;
  requested_refund_method_id?: null | number;
  chosen_refund_method_id?: null | number;
}

export interface IProcessOrderRequest {
  order_id: number;
  order_details: IProcessOrderItem[];
  refund_amount?: number | null;
  store_credit_amount?: number | null;
}

export interface IRetailer {
  name: string;
  retailer_id: number;
  retailer_uuid: string;
  is_active: boolean;
}

export interface IRetailerUuidResponse extends IRetailer {
  domain: string;
}

export interface ITokenSignInResponse {
  generic_user_id: number;
}

export interface ICustomerComment {
  color: string;
  creation_date: string;
  customer_comment: string;
  order_detail_id: number;
  return_type: string;
  size: string;
}

export interface IInternalComment {
  comment: string;
  creation_date: string;
  generic_user_id: number;
  name: string;
  order_id: number;
  order_internal_comment_id: number;
  picture: string;
}

export interface IUser {
  email: string;
  generic_user_id: number;
  name: string;
  picture: string;
  retailer_admin_user_id: null | number;
  last_login_date: string;
}

export interface IUserWithPermissions extends IUser {
  alter_feature_access: boolean;
  alter_page_access: boolean;
  id: number;
  retailer_id: number;
}

export interface IFeature {
  code: string;
  description: string;
  mapping_id: number | null;
  name: string;
  permission_id: number;
}

export interface IImagePayload {
  image_name: string;
  image_in_base64: string;
}

export interface IGlobalStyles {
  brand_color: string;
  brand_logo_url: string;
  customer_support_email: string;
  order_mgmt_system_order_url: string | null;
  retailer_website_url: string;
  return_policy_url: string;
  thank_you_page_banner_url_1: string | null;
  thank_you_page_banner_url_2: string | null;
  welcome_page_large_banner_url: string;
  welcome_text: string;
  terms_and_conditions_url: string;
  global_styles_images: {
    brand_logo_url: string | IImagePayload;
    welcome_page_large_banner_url: string | IImagePayload;
  };
}

export interface IRetailerSetting {
  tracking_page_banner_redirect_url: string | null;
  valid_return_threshold_in_days: number | null;
  tracking_page_banner_overlay: string;
  tracking_page_images: {
    tracking_page_square_banner_url: string | IImagePayload;
    tracking_page_wide_banner_url: string | IImagePayload;
  };
}

export interface IRetailerSettingPayload {
  tracking_page_banner_redirect_url: string | null;
  valid_return_threshold_in_days: number | null;
}

export interface IStore {
  is_active: boolean;
  retailer_store_id: number;
  store_label: string;
  store_name: string;
}

export interface IWarehouse {
  name: string;
  address: string;
  country_code: string;
  email: string;
  phone_number: string;
  zipcode: string;
  city: string;
  province_code?: string;
}

export interface IExchange {
  custom_setting_type: number;
  exchange_enum: number;
  id: number;
  last_updated_by: number;
  params: IExchangeParams;
  retailer_id: number;
  update_date: string;
}

interface IExchangeParams
  extends Record<string, { value: string | boolean | number }> {}

export type TExchangeParamConfig =
  | {
      type: "string";
      label: string;
      order: number;
    }
  | {
      type: "bool";
      label: string;
      order: number;
    }
  | {
      type: "integer";
      label: string;
      order: number;
    };

export interface IExchangeParamsResponse
  extends Record<string, TExchangeParamConfig> {}

export interface IExchangeOrderConfig {
  id: number;
  name: string;
  description: string;
  explain_img_url: string;
  config_params: {
    configs: IConfigParams[];
  };
}

export interface IConfigParams {
  config_description: string;
  config_name: string;
  exchange_enum: number;
  params: {};
}

// export interface IRetailerExchangeFeature {
//   code: string;
//   description: string;
//   mapping_id: number;
//   name: string;
//   permission_id: number;
// }

export interface IReason {
  is_active: boolean;
  has_exchange_enabled: boolean;
  ordering?: number;
  reason_code: string;
  reason_id?: number | string;
  reason_text: string;
}

export interface IEmailTemplatesResponse {
  body_dict: string;
  html_dict: string;
  sender_email: string;
  sender_name: string;
  subject_dict: string;
  test_receiver_email: string;
  reply_to: string;
}

export interface IPermissions {
  pages: Record<string, boolean>;
  features: Record<string, boolean>;
  roles: {
    isRetailerAdmin: boolean;
    isShopifyRetailer: boolean;
  };
}

export interface IPermissionContext {
  getPermission: (permissionName: string) => boolean | undefined;
  isSuperadmin: boolean;
  isRetailerAdmin: boolean;
}

export interface IPrevOrdersData {
  previous_order_count: number;
  min_order_date: string;
  max_order_date: string;
}

export interface IOrderImportCount {
  order_import_count: number;
}

export interface IOrderByDateInfo {
  order_count: number;
  order_date: string;
}

export interface IReturnRequestData extends IOrderByDateInfo {
  is_processed: number;
}

export interface IImportInfo {
  currency_code: string;
  customer_name: string;
  email: string;
  ext_order_id: string;
  import_date: string;
  order_date: string;
  order_price: number;
}

export interface ISpending {
  currency_code: string;
  total_revenue: string;
  net_revenue: string;
}

export interface ILastPurchase {
  currency_code: string;
  last_order_size: string;
}

export interface ICustomerHistory {
  purchase_stat: {
    order_count: string;
    active_since: string;
    total_returns: string;
  };
  spending: ISpending[];
  last_purchase: ILastPurchase | null;
}

export interface ICustomerSnapshot {
  purchase_snapshot: {
    total_return_cost: string;
    total_revenue: string;
    currency_code: string;
  };
  refusal_snapshot: {
    refused_count: string;
  };
}

export interface IExportReturnsData {
  category_1: string | null;
  category_2: string | null;
  category_3: string | null;
  brand: string;
  order_date: string;
  product_name: string;
  return_reason_text: string;
  total_count: number;
}

export interface IComment {
  color: string;
  content_url: string;
  customer_comment: string;
  ext_product_id: string;
  brand: string;
  order_detail_id: number;
  order_id: number;
  product_name: string;
  reason_id: number;
  size: string;
  request_date: string;
  ext_order_id: string;
}

export interface IEmailTemplateTypes {
  email_template_type_id: number;
  code: string;
  label: string;
  creation_date: string;
}

export interface ISmsTemplateTypes {
  sms_template_type_id: number;
  topic: string;
  sms_body: string;
  signature: string;
  label: string;
  // code: string
  // creation_date: string
}
export interface IRetailerSmsTemplate {
  topic: string;
  sms_body: string;
  signature: string;
  label: string;
}

export interface IRetailerEmail {
  retailer_id: number;
  sender_name: string;
  sender_email: string;
  test_receiver_email: string;
  creation_date: string;
  reply_to_email: string;
  reply_to_name: string;
}

export interface IRetailerEmailTemplate {
  retailer_email_template_id: number;
  retailer_id: number;
  email_template_type_id: number;
  subject: string;
  body: string;
  html_body: string;
  creation_date: string;
}

export interface IStatisticBarMeter {
  return_status: string;
  total_count: number;
}

export interface IReturnValueStatus {
  currency_code: string;
  total_in_progress: number;
  total_open: number;
  total_processed: number;
  total_return_value: number;
}

export interface ICategoryReturn {
  category_name: string;
  ordered_qty: number;
  returned_qty: number;
}

export interface IBrandReturn {
  brand_name: string;
  ordered_qty: number;
  returned_qty: number;
}

export interface IProductSearchResult {
  product_name: string;
}

export interface IOrderSearchResult {
  current_status: string;
  ext_order_id: string;
  prev_ext_order_id: string;
  new_ext_order_id: string;
  order_id: number;
  is_processed: 0 | 1 | 2;
  has_in_store_return: boolean;
  is_return_submitted: boolean;
}

export interface ICustomerSearchResult {
  email: string;
  name: string;
}

export interface IOrderFilterItem {
  filter_category: string;
  filter_items: string[];
}

export interface ICustomerContactInfo {
  phone_number: string | null;
  email: string;
  name: string;
  address: string;
  city: string;
  country_code: string;
}

export interface IFilterActionManageResponse {
  data: IOrderItem[];
  total_count: number;
}

export interface ICustomerSummary extends ICustomerHistory {
  customer_data: ICustomerContactInfo[];
}

export interface ICustomerReturn {
  ext_order_id: number;
  order_date: string;
  order_price: number;
  return_price: number;
  return_item_count: number;
  return_type: string;
  total_quantity: number;
  currency_code: string;
  has_in_store_return: boolean;
  has_user_comment: boolean;
}

export interface IOrderDetailSearchResult {
  approved_by: number;
  approved_date: string;
  color: string;
  content_url: string;
  currency_code: string;
  customer_comment: string;
  delivery_date: string;
  email: string;
  ext_order_id: string;
  has_in_store_return: boolean;
  has_internal_comment: boolean;
  is_approved: boolean;
  is_discount: boolean;
  brand: string;
  order_date: string;
  order_detail_id: number;
  order_id: number;
  ordered_quantity: number;
  original_price: number;
  product_name: string;
  product_page_url: string;
  reason_code: string;
  reason_id: number;
  reason_text: string;
  refusal_comment: string;
  return_type: string;
  returned_quantity: number;
  selling_price: number;
  ship_date: string;
  size: string;
  sku_number: string;
  store_label: string;
  store_name: string;
}

export interface IFilterResult {
  data: {
    order_id: number;
  }[];
  total_count: number;
}

export interface IOrderLookupDetailsStatistic {
  ext_order_id: number;
  email: string | null;
  message: string;
  found: 1 | 0;
  lookup_date: string;
  order_date: string;
  import_date: string;
}

export interface INotFoundLookupStatistic {
  date: string;
  unsuccessfull_count: number;
}

export interface IOrphanOrdersImportedStatistic {
  count: number;
}

export interface ILookupResponse<T> {
  data: T[];
  search_keyword: string;
}
export interface IUserProfile {
  firstName: string;
  lastName: string;
  email: string;
  profileImage: string;
}

export interface IProductStat extends IBaseReturnProduct {
  retailer_id: number;
}

export interface IOrderStat {
  billing_customer: ICustomerAddress;
  shipping_customer: ICustomerAddress;
  order: {
    delivery_date: null | string;
    email: string;
    ext_order_id: string;
    ext_order_url: string;
    order_date: string;
    order_id: number;
    ship_date: string;
    creation_date: string;
    current_status: string | null;
    order_shipping_label_id: null | number;
    request_date: string;
    label_error_message: string | null;
  };
}

export interface IAutoCompleteSuggestion {
  type: string;
  value: string;
  label: string;
  status?: string;
}

export interface IResponseStatus {
  status: string;
  message: string;
  confirmation_url: string | null;
}

export interface IBillingCheckResponse extends IResponseStatus {
  billing_plan_id: EBillingPlan;
}

export interface IReturnValue {
  refunded: number;
  store_credited: number;
  totally_approved: number;
  order_value: number;
  currency_code: string;
}

export interface IItemToRefund {
  sku_number: string | null;
  order_detail_id: number;
  refund_method_id: number;
  restock_location_id: number | null;
}

interface ISuggestedRefundOrderDetailItem {
  order_detail_id: number;
  restock_location_id: number | null;
}

export interface ISuggestedRefundBody {
  order_id: number;
  refund_method_id: number;
  order_details: ISuggestedRefundOrderDetailItem[];
}

export interface ITriggerRefundPayload {
  refund_method: string;
}

export interface IRefundThreshold {
  max_refund_threshold: number;
}

export interface IRefundMethod {
  code: string;
  refund_method_id: number;
  name: string;
  tooltip: string;
}

export interface IGetRefundMethodsResponse {
  refund_methods: IRefundMethod[];
}

export interface INewFeature {
  id: number;
  title: string;
  body: string;
  creation_date: string;
}

export interface INewFeatureUpdate {
  id: number | null;
  title: string;
  body: string;
}

export interface IIdLog {
  id: number;
}

export interface IGetWhatIsNewResponse {
  new_features: INewFeature[];
}

export interface IUpdateWhatIsNewResponse {
  new_features: INewFeatureUpdate[];
}

export interface IReturnValueOrder {
  currency_code: string;
  refunded: number;
  store_credited: number;
  totally_approved: number;
  order_value: number;
}

export interface ITransactionsOrder {
  transaction: {
    amount: number;
    currency_code: string;
    date_time_of_action: string;
    is_manually_overwritten: boolean;
    original_amount: number;
    type: number;
  };
  user: {
    generic_user_id: number;
    name: string | null;
    picture: string | null;
  };
}

export interface IReturnRateInNumbers {
  return_rate: number;
  return_rate_change: number;
  total_orders: number;
  total_returns: number;
}
export interface ITmsProvidersTypes {
  name: string;
  tms_id: number;
}
export interface ITmsRetailerApiTypes {
  country_blacklist: string;
  country_whitelist: string;
  credentials_json: Record<string, string>;
  is_enabled: boolean | number;
  tms_id: number;
}
export interface ITmsRetailerUpdate {
  tms_id: number;
  country_blacklist: string | null;
  country_whitelist: string | null;
  credentials_json: Record<string, string>;
  is_enabled: number | boolean | null;
}

export interface ITmsAddresses {
  sender: ITmsAddress;
  receiver: ITmsAddress;
}

export interface IPricingPlan {
  monthly_return_count: number;
}

export interface IShippingCarrier {
  // NOTE: id is not present on the backend, but added on the front-end for the correct rendering of the list
  id?: string;
  name: string;
  logo_url: string;
}

export interface IShippingCarriersResponse {
  carriers: IShippingCarrier[];
}

export enum EResponseStatus {
  OK = "ok",
  FAILED = "failed",
  FAIL = "fail",
}

export interface ITestShippingLabelResponse {
  details: {
    error_details: {
      description?: string;
      details?: string;
    };
    link: string;
  };
  message: string;
  status: EResponseStatus;
}

export interface ISuggestedMoneyAmount extends IResponseStatus {
  refund_items: IItemToRefund[];
  refund_method_id: number;
  ext_order_id: string;
  suggested_refund_amount: number;
}

export interface ICredentialsRow {
  id: string;
  key: string;
  value: string;
}
export interface IImageFile {
  base64_data: string;
  image_name: string;
}

export interface IUploadFilesResponse {
  images: {
    logo: IImageFile;
    banner: IImageFile;
  };
}

export interface IGetDrfContent {
  image_urls: {
    brand_logo_url: string | null;
    welcome_page_large_banner_url: string | null;
  };
}

export interface ISignUpGlobalStyles {
  customer_support_email: string;
  return_policy_url: string;
}

export interface ISignUpFormFields {
  [key: string]: string;
}

export interface ISignUpFormSelect {
  website_protocol: string;
  shipping_carrier: string;
  country_code: string;
}

export interface ISignUpFormFiles {
  logo: File[];
  banner: File[];
}

export interface IWarehouseDetails {
  comment?: string | null;
  creation_date?: string;
  is_approved?: 1 | 0;
}

export interface IGetOrderDetail {
  order_detail_id: number;
  barcode_number: string;
  color: string;
  model: string;
  original_price: number;
  product_name: string;
  quantity: number;
  selling_price: number;
  size: string;
  sku_number: string;
  warehouse_details: IWarehouseDetails;
}

export interface IOrderPayload {
  order_id?: number;
  customer: ICustomerEdit;
}

export interface IVerifyResponse {
  shopify_log_in_details: {
    email: string;
    first_name: string;
    generic_user_id: number;
    hasSetupOpened: "true" | string;
    last_name: string;
    retailer_domain: string;
    retailer_uuid: string;
    retailer_name: string;
    profile_image: string;
    store_name: string;
  };
}

export type TTranslationDictionary = Record<
  string,
  { translation: Record<string, string> }
>;

export enum EStep {
  INTRO = "INTRO",
  PRICING_PLAN = "PRICING_PLAN",
  SETUP_PAGE = "SETUP_PAGE",
  DRF_TEMPLATE = "DRF_TEMPLATE",
  EMAIL_TEMPLATE = "EMAIL_TEMPLATE",
  RETURN_REASONS = "RETURN_REASONS",
  WAREHOUSE = "WAREHOUSE",
  TMS = "TMS",
  SHIPPING_CARRIERS = "SHIPPING_CARRIERS",
  WELCOME = "WELCOME",
  ERROR = "ERROR",
}

export interface IOnboardingProgress {
  completed_steps: EStep[];
  is_complete: boolean;
  last_visited_step: EStep | "";
  carriers: string[];
}

export interface IRetailerAdminResponse {
  user: {
    generic_user_id: number;
    is_retailer_admin: boolean;
  };
}

export interface IRestockingLocation {
  id: number;
  name: string;
  is_primary: boolean;
}

export interface IRestockingResponse {
  locations: IRestockingLocation[];
}

export interface ISuggestedRefundResponse extends IResponseStatus {
  ext_order_id: string;
  oms_suggested_refund: IOmsSuggestedRefund;
  oms_suggested_refund_response: {
    refund: IOmsSuggestedRefundResponse;
  };
  refund_method_id: number;
  retailer_id: number;
  rule_engine_evaluation: IRuleEngineEvaluation;
  total_suggested_refund: IOmsSuggestedRefund;
}

export interface IOmsSuggestedRefund {
  amount: number;
  currency_code: string;
}

export interface IOmsSuggestedRefundResponse {
  currency: string;
  duties: any[];
  note: string;
  notify: boolean;
  refund_line_items: IRefundLineItem[];
  shipping: {
    full_refund: boolean;
  };
  transactions: ITransaction[];
}

export interface IRefundLineItem {
  discounted_price: string;
  discounted_total_price: string;
  line_item_id: number;
  location_id: number | null;
  price: string;
  quantity: number;
  restock_type: string;
  subtotal: string;
  total_cart_discount_amount: string;
  total_tax: string;
}

export interface ITransaction {
  amount: string;
  currency: string;
  gateway: string;
  kind: string;
  maximum_refundable: string;
  order_id: number;
  parent_id: number;
}

export interface IRuleEngineEvaluation {
  refund_allowed: boolean;
  rules_applied: string[];
  suggested_refund_amount: number;
}

export interface IBulkApproveOrders {
  orders: number[];
  refund_method_id?: number | null;
  is_approved?: number;
}

export interface IBulkApprove {
  creation_date: string;
  generic_user_id: number;
  name: string | null;
  order_id: number;
  picture: string | null;
  status: string;
  ext_order_id: number;
  refund_method_id: number | null;
  is_approved: number;
}

export interface IQueuedRequests {
  bulk_approve_queue: IBulkApprove[];
  total_queued_requests: number;
}

export interface IIntegration {
  integration_id: number;
  name: string;
}

export interface IIntegrationAuthorizationRequest {
  redirect_url: string;
}

export interface ICustomerAddressEdit {
  address: string | undefined;
  city: string | undefined;
  country_code: string | undefined;
  zipcode: string | undefined;
  province_code?: string;
}

export interface ICustomerEdit {
  email?: string | undefined;
  shipping_address?: ICustomerAddressEdit | null;
  billing_address?: ICustomerAddressEdit | null;
}

export interface ITrackingResponseStatus extends IResponseStatus {
  sample_tracking_url?: string;
}

export interface IUrl {
  url: string;
}

export interface IWarehouseReconciliationUrl {
  response: IUrl;
  status: string;
}
export interface IFeedbackFilter {
  name: EFeedbackFilterKeys;
  filterBackendParam: number;
}

export interface IRatingResponse extends IResponseStatus {
  average_rating: number;
  rating_count: number;
}

export interface IReview {
  comment: string;
  creation_date: string;
  ext_order_id: number;
  rating: number;
}

export interface IRatingCount {
  [EFeedbackFilterKeys.FIVE_STAR_REVIEWS]: number;
  [EFeedbackFilterKeys.FOUR_STAR_REVIEWS]: number;
  [EFeedbackFilterKeys.THREE_STAR_REVIEWS]: number;
  [EFeedbackFilterKeys.TWO_STAR_REVIEWS]: number;
  [EFeedbackFilterKeys.ONE_STAR_REVIEWS]: number;
}

export interface IFeedback {
  comments: IReview[];
  rating_count: IRatingCount;
}

export interface IFeedbackParams {
  page: number;
  per_page: number;
  start_date: string;
  end_date: string;
  rating: string;
}

export interface IBillingFeature {
  currency: string;
  name: string;
  price: number;
  quantity: number | string;
  id: number;
}

export interface IRecurringFeatures {
  addons: IBillingFeature[];
  plan: IBillingFeature | null;
  sum_price: number;
}

export interface IBillingCharges {
  approval_date: string;
  approved_by: string;
  currency: string;
  description: string;
  price: string;
  summary: string;
}

export interface IAvailableFeature {
  currency: string;
  has_quantity: boolean;
  name: string;
  price: number;
  id: number;
  feature_type_name: EFeatureTypeName;
}

export interface ICharge {
  feature_id: number;
  price: number;
  currency: string;
  is_recurring: boolean;
  created_by: number;
  quantity: number | string;
  is_annualy: boolean;
}

export interface IPendingChargeResponse extends ICharge {
  id: number;
  summary: string;
  description: string;
}

export interface IPendingCharges extends IResponseStatus {
  details: {
    results: IPendingCharge[];
  };
}
export interface IPendingCharge {
  charge: IPendingChargeResponse;
  feature: IChargeFeature;
}

export interface IChargeFeature {
  is_payment_mandatory: number;
  quantity: number;
}

export interface IChargeResponse extends IResponseStatus {
  details: {
    confirmation_url: string;
  };
}

export interface IAddFeatureResponse extends IResponseStatus {
  details: {
    charge_id: number;
  };
}

export interface IFeatureIdAndName {
  id: number;
  name: string;
}

export interface IFeatureDetails {
  feature_type: string;
  features: IFeatureIdAndName[];
  has_access: boolean;
  has_quantity: boolean;
  total_quantity: number;
}

export interface IFeatureAccessResponse extends IResponseStatus {
  details: {
    feature?: IFeatureDetails;
  };
}

export interface IAddFeature {
  feature_id: number;
  created_by: number;
  quantity: number;
  is_annually: boolean;
}

export interface ISettingsIntegration {
  id: number;
  name: string;
  description: string;
  logo: string;
  isIntegrationActive: boolean;
  is_auth_redirect: boolean;
  helpdesk_url: string;
  parameter: ISettingsIntegrationParam[];
  settings: ISettingsIntegrationSetting[];
}

export interface ISettingsIntegrationParam {
  label: string;
  parameter: string;
}

export interface ISettingsIntegrationSetting {
  id: number;
  name: string;
  isActive: boolean;
}

export interface IIntegrationParamsModalArgs {
  integrationName: string;
  integrationId: number;
  integrationParams: ISettingsIntegrationParam[];
  isAuthRedirect: boolean;
}

export interface IReturnTypeEditPayload {
  order_id: number;
  order_details: {
    order_detail_id: number;
    return_type: string;
  }[];
}

export enum EDatasetType {
  SIMPLE = "SIMPLE",
  PREPARED = "PREPARED",
  SCHEDULE = "SCHEDULE",
  LINEGRAPH = "LINEGRAPH",
  FORECAST = "FORECAST",
}

export interface IDataset {
  id: number;
  name: string;
  description: string;
  type: EDatasetType;
  params: IDatasetParams[];
  impact_area_id: EImpactAreaType;
  data_to_download: {
    csv: [
      {
        name: string;
        url: string;
        params: [
          {
            value: string;
            parameter: string;
          },
          {
            value: string;
            parameter: string;
          }
        ];
        generated_on: string;
      }
    ];
  };
}

export interface IDatasetsResponse {
  datasets: IDataset[];
}

export interface ISimpleDatasetMetadataItem {
  column_name: string;
  label: string;
  type: "datetime" | "integer" | "percentage" | "currency" | "string";
  currency_column_name?: string;
}

export type TDatasetData = Record<string, string | number | null>;

export interface IDatasetDataResponse {
  data: TDatasetData[];
  metadata: ISimpleDatasetMetadataItem[];
}

export interface IDatasetParams {
  label: string;
  parameter: string;
  type: EParamType;
}

export interface ICollection {
  blocked: boolean;
  id: number;
  name: string;
  updated_by: number;
}

export interface IBlockedReturns {
  details: {
    collections: ICollection[];
  };
  message: string;
  status: EResponseStatus;
}

export interface IBlockedProduct {
  barcode: string;
  brand: string;
  name: string;
  sku: string;
}

export interface IBlockedCollection {
  details: {
    collection: {
      id: number;
      name: string;
      products: IBlockedProduct[];
    };
  };
  message: string;
  status: string;
}

export interface IAutoRefundReport {
  id: string;
  url: string;
  name: string;
  creation_date: string;
}

export interface IAutoRefundReportResponse extends IResponseStatus {
  details: {
    reports: IAutoRefundReport[];
  };
}

export interface ISegmentParams {
  label: string;
  parameter: string;
  type: EParamType;
  value: string | number;
}

export interface IUpdatedBy {
  user_id: number;
  email: string;
}

export interface ISegment {
  id: number;
  name: string;
  description: string;
  enabled: boolean;
  href: string;
}

export interface ISegmentWithParams extends ISegment {
  params: {
    [EVIPSettingsParam.NUMBER_OF_ORDERS]: ISegmentParams;
    [EVIPSettingsParam.REVENUE]: ISegmentParams;
    [EVIPSettingsParam.ACTIVE_SINCE]: ISegmentParams;
    [EVIPSettingsParam.LAST_ORDER_SIZE]: ISegmentParams;
    [EVIPSettingsParam.TOTAL_RETURNS]: ISegmentParams;
  };
  update_date: string;
  updated_by: IUpdatedBy;
}

export interface ISegmentEditHistory {
  params: {
    [EVIPSettingsParam.NUMBER_OF_ORDERS]: ISegmentParams;
    [EVIPSettingsParam.REVENUE]: ISegmentParams;
    [EVIPSettingsParam.ACTIVE_SINCE]: ISegmentParams;
    [EVIPSettingsParam.LAST_ORDER_SIZE]: ISegmentParams;
    [EVIPSettingsParam.TOTAL_RETURNS]: ISegmentParams;
  };
  enabled: boolean;
  update_date: string;
  updated_by: IUpdatedBy;
}

export interface ISegmentUpdatePayload {
  enabled: boolean;
  params?: Record<string, string | number>;
}

export interface ISegmentByIdResponse extends IResponseStatus {
  details: {
    segment: ISegmentWithParams;
  };
}

export interface ISegmentsResponse extends IResponseStatus {
  details: {
    segments: ISegment[];
  };
}

export interface ISegmentsHistoryResponse extends IResponseStatus {
  details: {
    versions: ISegmentEditHistory[];
  };
}

export interface IFeatureConsent {
  name: EFeatureType;
  consent_approved: boolean;
  approved_by: number | null;
  approval_date: string | null;
}

export interface IFeatureConsentResponse extends IResponseStatus {
  details: IFeatureConsent;
}

export interface IShippingRule {
  shipping_platform: string;
  carrier: string;
  accepted_territories: string[];
  banlist: string[];
  priority: number;
}

export interface IShippingRuleResponse extends IResponseStatus {
  details: {
    rules: IShippingRule[];
  };
}

export interface IDatasetHeaderParams extends Record<string, string | null> {}

export interface ICategory {
  id: number;
  name: string;
  updated_at: string;
  created_at: string;
  created_by: number;
  updated_by: number | null;
}

export interface ICategoryProduct {
  category: string;
  id: number;
  product_name: string;
  "sku/variant": number;
}

export interface ICustomCategoryResponse extends IResponseStatus {
  details: {
    categories: ICategory[];
  };
}

export interface ICustomCategoryProductsResponse extends IResponseStatus {
  details: {
    products: ICategoryProduct[];
  };
}

export interface ICustomCategoryPayload {
  name: string;
  product_list: number[];
}

export interface IReasonTagStatistic {
  reason_tag: string;
  size: string;
  count: number | null;
}

export interface IRetentionSplitStatistic {
  type: string;
  percent: number;
  count: number | null;
}

export interface IImpactArea {
  id: number;
  name: string;
  description: string;
}

export interface IImpactAreaData {
  dataset_name: string;
  dataset_type: EImpactAreaType;
  date: string;
  approved_by: number;
}
export interface ILabelValuePair {
  label: string;
  value: string | string[];
}
export interface IAutomationParameters {
  refund_methods: ILabelValuePair[];
  return_types: ILabelValuePair[];
  shipment_statuses: ILabelValuePair[];
}

export interface IAutomationParametersResponse extends IResponseStatus {
  details: {
    parameters: IAutomationParameters;
  };
}

export interface INewAutomationRule {
  return_types: string[];
  refund_method: string;
  allowed_countries: string;
  shipment_statuses: string[];
}

export interface IToggleAutomationRule {
  ruleId: number;
  isEnabled: boolean;
}

export interface IAutomationRule {
  description: string;
  is_enabled: boolean;
  name: string;
  parameters: {
    country_whitelist: ILabelValuePair;
    refund_method_id: ILabelValuePair;
    return_types: ILabelValuePair;
    shipment_statuses: ILabelValuePair;
  };
  rule_id: number;
  rule_type_id: number;
  update_date: string;
  updated_by: number;
}

export interface IAutomationRuleResponse extends IResponseStatus {
  details: {
    rules: IAutomationRule[];
  };
}

export interface IAutoRefundRuleCreateResponse extends IResponseStatus {
  details: {
    rule: IAutomationRule;
  };
}

export interface ITotalRefunds {
  last_total_refunds: string;
  total_refunds: string;
}

export interface ITotalSales {
  total_sales: string;
  last_total_sales: string;
}

export interface IRevenuRetained {
  last_total_exchange_amount_by_retailer: number;
  last_total_revenue_retained: number;
  last_total_store_credit_by_retailer: number;
  total_exchange_amount_by_retailer: string;
  total_revenue_retained: string;
  total_store_credit_by_retailer: number;
}

export interface ICategoryRate {
  name: string;
  order_count: number;
  return_count: number;
  return_percent: number;
}

export interface ILabelsSaved {
  label_generation_coverage_percent: number;
  last_label_generation_coverage_percent: number;
  last_return_label_generated_by_yayloh: number;
  last_total_returns: number;
  return_label_generated_by_yayloh: number;
  total_returns: number;
}

export interface ICountryRate {
  country_code: string;
  order_count: number;
  return_cost_in_euro: number;
  return_count: number;
  return_percent: number;
}

export interface ITopReturnedCategories {
  categories: ICategoryRate[];
}

export interface ITopReturnedCountries {
  countries: ICountryRate[];
}

export interface IWeeklyRefund {
  refund_percent: number;
  week_number: number;
}

export interface IWeeklyRefundsResponse extends IResponseStatus {
  refunds_by_week: IWeeklyRefund[];
}

export interface IWeeklyRevenueRetained {
  exchange_amount: number;
  exchange_percent: number;
  retained_revenue_amount: number;
  retained_revenue_rate: number;
  store_credit_amount: number;
  store_credit_percent: number;
  week_number: number;
}
export interface IDateRangeParams {
  start: string;
  end: string;
}

export interface IChannelsParams {
  channels: string[];
}

export interface IAnalyticsChannel {
  id: number;
  source_channel: string;
}
export interface IApiDataType {
  key: string;
  friendly_name: string;
  creation_date: Date;
  created_by: number | string | undefined;
  api_token_id: number;
}

export interface IExchangeFeatureResponse {
  exchange_switch: boolean;
}

export interface IReturnValidityExceptionDate {
  id: number;
  start_date: string;
  end_date: string;
  expires_at?: string;
}

export const REFUND_TYPE = {
  REFUND: 1,
  STORE_CREDIT: 2,
  GIFT_CARD: 3,
  DISCOUNT_CODE: 4,
} as const;

export type TRefundType = (typeof REFUND_TYPE)[keyof typeof REFUND_TYPE];
