import { BASE_URL } from "../constants";
import { IRetailerContent } from "../drf-fe/src/models";
import { handleApiResponse } from "../helper/handleApiResponse";
import {
  ILabelsSaved,
  IRevenuRetained,
  ITopReturnedCategories,
  ITopReturnedCountries,
  ITotalRefunds,
  ITotalSales,
  IWeeklyRefundsResponse,
  IWeeklyRevenueRetained,
} from "../models";
import { getHeaders } from "./getHeaders";

export const getTotalSales = (
  retailerUuid?: string,
  pastNumberOfDays = 30
): Promise<ITotalSales> => {
  return fetch(`${BASE_URL}/home/total-sales/?days=${pastNumberOfDays}`, {
    method: "GET",
    headers: getHeaders(retailerUuid),
  }).then(handleApiResponse);
};

export const getTotalRefunds = (
  retailerUuid?: string,
  pastNumberOfDays = 30
): Promise<ITotalRefunds> => {
  return fetch(`${BASE_URL}/home/total-refunds/?days=${pastNumberOfDays}`, {
    method: "GET",
    headers: getHeaders(retailerUuid),
  }).then(handleApiResponse);
};

export const getRevenueRetained = (
  retailerUuid?: string,
  pastNumberOfDays = 30
): Promise<IRevenuRetained> => {
  return fetch(
    `${BASE_URL}/home/total-revenue-retained/?days=${pastNumberOfDays}`,
    {
      method: "GET",
      headers: getHeaders(retailerUuid),
    }
  ).then(handleApiResponse);
};

export const getTop5ReturnedCategories = (
  retailerUuid?: string
): Promise<ITopReturnedCategories> => {
  return fetch(`${BASE_URL}/home/top-5-returned-categories/`, {
    method: "GET",
    headers: getHeaders(retailerUuid),
  }).then(handleApiResponse);
};

export const getLabelsSaved = (
  retailerUuid?: string
): Promise<ILabelsSaved> => {
  return fetch(`${BASE_URL}/home/labels-saved/`, {
    method: "GET",
    headers: getHeaders(retailerUuid),
  }).then(handleApiResponse);
};

export const getTop3ReturnedCountries = (
  retailerUuid?: string
): Promise<ITopReturnedCountries> => {
  return fetch(`${BASE_URL}/home/top-3-returned-countries/`, {
    method: "GET",
    headers: getHeaders(retailerUuid),
  }).then(handleApiResponse);
};

export const getWeeklyRefunds = (
  retailerUuid?: string
): Promise<IWeeklyRefundsResponse> => {
  return fetch(`${BASE_URL}/home/weekly-refunds/`, {
    method: "GET",
    headers: getHeaders(retailerUuid),
  }).then(handleApiResponse);
};

export const getWeeklyRevenueRetained = (
  retailerUuid?: string
): Promise<IWeeklyRevenueRetained[]> => {
  return fetch(`${BASE_URL}/home/weekly-retained-revenue/`, {
    method: "GET",
    headers: getHeaders(retailerUuid),
  }).then(handleApiResponse);
};

export const getRetailerContent = (
  retailerUuid?: string
): Promise<IRetailerContent> => {
  return fetch(`${BASE_URL}/retailer_content/`, {
    method: "GET",
    headers: getHeaders(retailerUuid),
  }).then(handleApiResponse);
};
