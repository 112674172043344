import React from "react";
import classNames from "classnames";

import "./Heading.scss";

interface IHeadingProps {
  text: string;
  className?: string;
  component?: keyof JSX.IntrinsicElements;
  variant?: string;
  cypressDataCy?: string;
}

export function Heading({
  text,
  className,
  component: Component = "span",
  variant = "h1",
  cypressDataCy,
}: IHeadingProps) {
  return (
    <Component
      data-cy={cypressDataCy}
      className={classNames("heading", className, `heading_${variant}`)}
    >
      {text}
    </Component>
  );
}
