import React from "react";

import "./PreviewSetting.scss";

interface IPreviewSettingProps {
  title: string;
  value: string | number | React.ReactNode;
}

export const PreviewSetting: React.FC<IPreviewSettingProps> = ({
  title,
  value,
}) => {
  return (
    <div className="preview-setting">
      <div className="preview-setting__title">{title}</div>
      <div className="preview-setting__value">{value}</div>
    </div>
  );
};
