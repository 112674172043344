import React from "react";
import "./Loader.scss";
import classNames from "classnames";
import { LoadingSpinner } from "../LoadingSpinner/LoadingSpinner";

interface ILoaderProps {
  isLoading: boolean;
  className?: string;
  removeBackground?: boolean;
}

export const Loader: React.FC<ILoaderProps> = (props) => {
  return (
    <div
      className={classNames("loader", props.className, {
        "loader_no-children": !props.children,
      })}
    >
      {props.children}
      {props.isLoading && (
        <LoadingSpinner
          className={classNames("loader__animation", {
            "loader__animation_no-background": props.removeBackground,
          })}
        />
      )}
    </div>
  );
};
