import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  getSegments,
  updateSegmentSettingsById,
} from "../../../../../api/settings";
import { API_ERROR } from "../../../../../constants";
import { NotificationContext } from "../../../../../context/NotificationContext";
import { ISegment } from "../../../../../models";
import { Loader } from "../../../../Loader/Loader";
import { SettingsLayout } from "../../SettingsLayout/SettingsLayout";
import { SegmentCard } from "./components/SegmentCard/SegmentCard";

import "./Segments.scss";

interface ISegmentsProps {}

export const Segments: React.FC<ISegmentsProps> = () => {
  const { setErrorNotification } = useContext(NotificationContext);
  const [isLoading, setIsLoading] = useState(false);
  const [segments, setSegments] = useState<ISegment[]>([]);

  useEffect(() => {
    setIsLoading(true);

    getSegments()
      .then(({ details }) => {
        setSegments(details.segments);
      })
      .catch((err) => {
        console.error(err);
        setErrorNotification(API_ERROR);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [setIsLoading, setSegments, setErrorNotification]);

  const handleIsVIPSegmentActiveChange = useCallback(
    (enabled: boolean, e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const targetId = parseInt(e.currentTarget.id);

      updateSegmentSettingsById(e.currentTarget.id, { enabled }).then(() => {
        setSegments((segments) =>
          segments.map((segment) => {
            return segment.id === targetId
              ? {
                  ...segment,
                  enabled,
                }
              : segment;
          })
        );
      });
    },
    [setSegments]
  );

  return (
    <SettingsLayout className="segments">
      <div className="segments__header">Segments</div>

      <Loader isLoading={isLoading}>
        <div className="segments__content">
          {segments.map((segment) => (
            <SegmentCard
              key={segment.id}
              title={segment.name}
              description={segment.description}
              isActive={segment.enabled}
              id={segment.id}
              onActiveChange={handleIsVIPSegmentActiveChange}
            />
          ))}
        </div>
      </Loader>
    </SettingsLayout>
  );
};
