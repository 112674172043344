import React, { useMemo } from "react";
import { IWeeklyRefund } from "../../models";
import { sortBy } from "lodash";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";

import "./RefundChart.scss";

interface IRefundChartProps {
  weeklyRefunds: IWeeklyRefund[];
}

export const RefundChart: React.FC<IRefundChartProps> = ({ weeklyRefunds }) => {
  const refundChartData = useMemo(() => {
    return sortBy(weeklyRefunds, (x) => x.week_number).map((x, i) => {
      return {
        name: `Week ${i + 1}`,
        percent: x.refund_percent,
      };
    });
  }, [weeklyRefunds]);

  return (
    <div className="refund-chart">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={refundChartData} barSize={32}>
          <CartesianGrid strokeDasharray="2 2" vertical={false} />

          <XAxis dataKey="name" fontSize="10px" interval={0} />
          <YAxis
            width={30}
            fontSize="10px"
            tickFormatter={(value) => {
              return `${value}%`;
            }}
          />

          <Bar dataKey="percent" fill="#34D1B6" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
