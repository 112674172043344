import * as React from "react";
import classes from "./Button.module.scss";
import classNames from "classnames";

export interface IButtonProps {
  iconSource?: string | React.ReactNode;
  dataAttributes?: Record<string, string | number | boolean>;
  label: React.ReactNode;
  onClick?:
    | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | undefined;
  disabled?: boolean;
  className?: string;
  name?: string;
  variant?: "default" | "ghost" | "text";
  type?: "button" | "submit" | "reset" | undefined;
  size?: "small" | "medium";
  cypressDataCy?: string;
}

export function Button({
  cypressDataCy,
  dataAttributes,
  label,
  onClick,
  disabled,
  className,
  name,
  size = "medium",
  type,
  variant = "default",
  iconSource,
}: IButtonProps) {
  return (
    <button
      data-cy={cypressDataCy}
      {...dataAttributes}
      className={classNames(
        "button",
        classes.button,
        `button_${variant}`,
        classes[`button_${variant}`],
        className,
        {
          [`button_${size}`]: size,
          [classes[`button_${size}`]]: size,
        }
      )}
      type={type}
      onClick={onClick}
      disabled={disabled}
      name={name}
    >
      {iconSource && typeof iconSource === "string" ? (
        <img
          className={classNames("button__icon", classes.button__icon)}
          src={iconSource}
          alt=""
        />
      ) : (
        iconSource
      )}
      {label}
    </button>
  );
}
