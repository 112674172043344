import React, { useCallback, useState, useContext, useEffect } from "react";
import { ExclamationCircleOutlined, WarningFilled } from "@ant-design/icons";
import { retryShippingLabel } from "../../../../api/returnManagement";
import { Loader } from "../../../Loader/Loader";
import { NotificationContext } from "../../../../context/NotificationContext";
import { API_ERROR } from "../../../../constants";
import { IResponseStatus } from "../../../../models";
import { Button } from "../../../../components/Button/Button";
import { Tooltip } from "antd";

import "./ShippingLabelError.scss";

interface IShippingLabelErrorProps {
  errorMessage: string;
  handleShippingLabelRetrySuccess: (response: IResponseStatus) => void;
  orderId: number;
}

const MAX_RETRIES = 3;
const MAX_RETIRES_ERROR_MESSAGE =
  "Maximum attempts to generate a shipping label reached. Please contact Yayloh tech support!";

const HELPDESK_LINK =
  "https://intercom.help/yaylohelpdesk/en/articles/6161165-shipping-label-error";

export const ShippingLabelError: React.FC<IShippingLabelErrorProps> = ({
  errorMessage,
  orderId,
  handleShippingLabelRetrySuccess,
}) => {
  const { setErrorNotification } = useContext(NotificationContext);
  const [isLoading, setIsLoading] = useState(false);
  const [numberOfRetries, setNumberOfRetries] = useState(0);

  useEffect(() => {
    if (numberOfRetries >= MAX_RETRIES) {
      return setErrorNotification(MAX_RETIRES_ERROR_MESSAGE);
    }
  }, [numberOfRetries, setErrorNotification]);

  const handleShippingLabelRetry = useCallback(() => {
    window.dataLayer.push({
      event: "shipping_label_retry_click",
    });
    setIsLoading(true);

    retryShippingLabel(orderId)
      .then(handleShippingLabelRetrySuccess)
      .catch((err) => {
        console.error(err);
        setErrorNotification(API_ERROR);
      })
      .finally(() => {
        setNumberOfRetries((retries) => retries + 1);
        setIsLoading(false);
      });
  }, [
    orderId,
    setIsLoading,
    setErrorNotification,
    setNumberOfRetries,
    handleShippingLabelRetrySuccess,
  ]);

  const openHelpdesk = useCallback(() => {
    window.open(HELPDESK_LINK, "_blank");
  }, []);

  const onHover = useCallback(() => {
    window.dataLayer.push({
      event: "shipping_label_error_hover_over",
    });
  }, []);

  return (
    <div className="shipping-label-error" onMouseOver={onHover}>
      {isLoading && (
        <Loader isLoading className="shipping-label-error__loading" />
      )}
      <div className="shipping-label-error__error">
        <WarningFilled className="shipping-label-error__warning" />
        Error
        <Tooltip placement="bottom" title={errorMessage}>
          <ExclamationCircleOutlined className="shipping-label-error__info" />
        </Tooltip>
      </div>
      <div>
        <Button
          type="button"
          label="Retry"
          className="shipping-label-error__button"
          disabled={numberOfRetries >= MAX_RETRIES}
          onClick={handleShippingLabelRetry}
        />{" "}
        |{" "}
        <Button
          type="button"
          label="Helpdesk"
          className="shipping-label-error__button"
          onClick={openHelpdesk}
        />
      </div>
    </div>
  );
};
