import "./Avatar.scss";

import React from "react";
import classNames from "classnames";

interface IAvatarProps {
  size?: number;
  src: string;
  initials: string;
  alt?: string;
  className?: string;
  imgProps?: any;
}

export const Avatar: React.FC<IAvatarProps> = ({
  alt,
  initials,
  imgProps,
  size,
  src,
  children,
  className,
}) => {
  if (!src) {
    return (
      <div className={classNames("avatar", className)}>
        <div
          className="avatar__placeholder"
          style={{
            height: size,
            width: size,
          }}
        >
          {initials}
        </div>
        {children}
      </div>
    );
  }
  return (
    <div className={classNames("avatar", className)}>
      <img
        className="avatar__img"
        alt={alt}
        src={src}
        style={{
          width: size,
          height: size,
        }}
        {...imgProps}
        {...(size ? { width: size } : {})}
        {...(size ? { height: size } : {})}
      />
      {children}
    </div>
  );
};
