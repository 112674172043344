import { Modal } from "antd";
import React, { useState, useCallback } from "react";
import classNames from "classnames";
import { Button } from "../../Button/Button";
import { PricingPlan } from "../../PricingPlan/PricingPlan";

import logo from "../../../images/logo-optimized.png";

import "./BillingBlocker.scss";

interface IBillingBlockerProps {
  summary: string;
  isVisible: boolean;
  isLoading: boolean;
}

export const BillingBlocker: React.FC<IBillingBlockerProps> = ({
  summary,
  isVisible,
  isLoading,
}) => {
  const [isRenewSubscriptionInProgress, setIsRenewSubscriptionInProgress] =
    useState(false);

  const handleRenewSubscriptionToggle = useCallback(() => {
    setIsRenewSubscriptionInProgress((isInProgress) => !isInProgress);
  }, [setIsRenewSubscriptionInProgress]);

  return (
    <Modal
      className={classNames(
        "billing-blocker",
        isRenewSubscriptionInProgress && "billing-blocker_renew-subscription"
      )}
      open={isVisible}
      footer={null}
      closable={false}
      width="none"
    >
      {!isRenewSubscriptionInProgress && (
        <>
          <div className="billing-blocker__image">
            <img src={logo} alt="yayloh" />
          </div>

          <h3 className="billing-blocker__summary">{summary}</h3>

          <div className="billing-blocker__actions">
            <Button
              className="billing-blocker__button billing-blocker__button_approve"
              onClick={handleRenewSubscriptionToggle}
              label="Renew Subscription"
              disabled={isLoading}
            />
          </div>
        </>
      )}

      {isRenewSubscriptionInProgress && <PricingPlan />}
    </Modal>
  );
};
