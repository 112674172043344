import React from "react";
import { PdfLink } from "../../../PdfLink/PdfLink";

import "./ShippingLabelActions.scss";

interface IShippingLabelActionsProps {
  shippingLabelId: number;
  orderId: number;
  label: string;
  getLinkProp: string;
  icon: string;
}

export const ShippingLabelActions: React.FC<IShippingLabelActionsProps> = ({
  shippingLabelId,
  orderId,
  label,
  getLinkProp,
  icon,
}) => {
  return (
    <div className="shipping-label-actions">
      <PdfLink getLink={getLinkProp} icon={icon} label={label} />
    </div>
  );
};
