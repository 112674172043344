import { BASE_URL, RATING_PARAM_NAME } from "../constants";
import { handleApiResponse } from "../helper/handleApiResponse";
import { IRatingResponse, IFeedback, IFeedbackParams } from "../models";
import { getHeaders } from "./getHeaders";

const getFeedbackUrlParams = (params: IFeedbackParams) => {
  const feedbackUrlParams = new URLSearchParams();

  Object.entries(params).forEach(([paramName, value]) => {
    if (paramName === RATING_PARAM_NAME && !value) {
      // NOTE: rating query value must not be a falsy value,
      // if it is falsy it should not be appended to URLSearchParams
      return;
    }

    feedbackUrlParams.append(paramName, value);
  });

  return feedbackUrlParams;
};

export function getRating(retailerUuid?: string): Promise<IRatingResponse> {
  return fetch(`${BASE_URL}/feedback/rating/`, {
    method: "GET",
    headers: getHeaders(retailerUuid),
  }).then(handleApiResponse);
}

export function getFeedback(params: IFeedbackParams): Promise<IFeedback> {
  const feedbackUrlParams = getFeedbackUrlParams(params);

  return fetch(
    `${BASE_URL}/feedback/comments_and_stats/?${feedbackUrlParams.toString()}`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  ).then(handleApiResponse);
}

export const getFeedbackShowcase = (
  params: IFeedbackParams
): Promise<IFeedback> => {
  const feedbackUrlParams = getFeedbackUrlParams(params);

  return fetch(
    `${BASE_URL}/feedback/showcase/comments/?${feedbackUrlParams.toString()}`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  ).then(handleApiResponse);
};
