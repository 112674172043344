import React from "react";
import yaylohLogo from "../../../../images/logo.svg";

import "./PDFHeader.scss";

interface IPDFHeaderProps {}

export const PDFHeader: React.FC<IPDFHeaderProps> = () => {
  return (
    <div className="pdf-header">
      <img src={yaylohLogo} alt="" />
    </div>
  );
};
