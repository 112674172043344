import React from "react";
import "./Notification.scss";
import cross from "./images/cross.svg";
import classNames from "classnames";
import { ENotificationType } from "./types";

interface INotification {
  type: ENotificationType;
  text?: string;
  onClose: () => void;
}

function getDefaultNotificationText(type: ENotificationType) {
  switch (type) {
    case "error": {
      return "Oooops! API error. Try again or call a tech support";
    }
    case "success": {
      return "Success";
    }
  }
}

export function Notification({ onClose, type, text }: INotification) {
  return (
    <div
      className={classNames("notification text-small", {
        [`notification_${type}`]: type,
      })}
    >
      <span>{text || getDefaultNotificationText(type)}</span>
      <img
        className="notification__close"
        onClick={onClose}
        src={cross}
        alt="close trigger"
      />
    </div>
  );
}
