import { useCallback } from "react";
import classNames from "classnames";
import React from "react";
import { useHistory } from "react-router";
import { Button } from "../Button/Button";

import "./Navigate.scss";

interface INavigateProps {
  to: string;
  className?: string;
}

export const Navigate: React.FC<INavigateProps> = ({
  children,
  to,
  className,
}) => {
  const history = useHistory();

  const handleNavigate = useCallback(() => {
    history.push(to);
  }, [history, to]);

  return (
    <Button
      label={children}
      className={classNames("navigate", className)}
      onClick={handleNavigate}
    />
  );
};
