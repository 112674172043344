import React, { useContext } from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import { App } from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router } from "react-router-dom";
import {
  NotificationContextStore,
  NotificationContext,
} from "./context/NotificationContext";
import { UserContext, UserContextStore } from "./context/UserContext";
import GoogleTagManager from "./components/GoogleTagManager";
import { IntegrationErrorContextStore } from "./context/IntegrationErrorContext";
import {
  Chart as ChartJS,
  CategoryScale,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  BarElement,
  ArcElement,
  Filler,
  Legend,
  Tooltip,
  TimeScale,
  TimeSeriesScale,
  ScatterController,
} from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import {
  SetupOpenContext,
  SetupOpenContextStore,
} from "./context/SetupOpenContext";

ChartJS.register(
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
  BarElement,
  ArcElement,
  Filler,
  Legend,
  Tooltip,
  TimeScale,
  TimeSeriesScale,
  ScatterController,
  zoomPlugin
);

declare global {
  interface Window {
    Smallchat: {
      set: (key: string, value: string | undefined) => void;
    };
  }
}

const ContextProvider = () => {
  const {
    userProfile,
    setUserProfile,
    setIsSuperadmin,
    setUserPermissions,
    changeAvailableRetailers,
    changeCurrentRetailerUuid,
    setGenericUserId,
    retailerAdminResponse,
  } = useContext(UserContext);

  const {
    errorNotification,
    setErrorNotification,
    successNotification,
    setSuccessNotification,
    warningNotification,
    setWarningNotification,
  } = useContext(NotificationContext);

  const { setHasSetupOpened } = useContext(SetupOpenContext);

  return (
    <App
      userProfile={userProfile}
      setUserProfile={setUserProfile}
      retailerAdminResponse={retailerAdminResponse}
      setIsSuperadmin={setIsSuperadmin}
      setUserPermissions={setUserPermissions}
      changeAvailableRetailers={changeAvailableRetailers}
      changeCurrentRetailerUuid={changeCurrentRetailerUuid}
      setGenericUserId={setGenericUserId}
      setErrorNotification={setErrorNotification}
      errorNotification={errorNotification}
      setSuccessNotification={setSuccessNotification}
      successNotification={successNotification}
      setWarningNotification={setWarningNotification}
      setHasSetupOpened={setHasSetupOpened}
      warningNotification={warningNotification}
    />
  );
};

const app = (
  <IntegrationErrorContextStore>
    <UserContextStore>
      <NotificationContextStore>
        <>
          <GoogleTagManager gtag="GTM-KZH5F2F" />
          <Router>
            <SetupOpenContextStore>
              <ContextProvider />
            </SetupOpenContextStore>
          </Router>
        </>
      </NotificationContextStore>
    </UserContextStore>
  </IntegrationErrorContextStore>
);

const rootElement = document.getElementById("root");
if (rootElement?.hasChildNodes()) {
  ReactDOM.hydrate(app, rootElement);
} else {
  ReactDOM.render(app, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
