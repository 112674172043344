import "./PdfLink.scss";

import React from "react";

import { TextWithAction } from "../TextWithAction/TextWithAction";

interface IPdfLinkProps {
  getLink: string;
  icon: string;
  label: string;
}

export const PdfLink: React.FC<IPdfLinkProps> = ({ getLink, icon, label }) => {
  return (
    <span className="pdf-link">
      <TextWithAction
        onClick={async () => {
          const a = document.createElement("a");
          a.target = "black";
          a.href = getLink;
          a.click();
        }}
      >
        <img src={icon} alt="Icon" />
        <div className="pdf-link_label">
          <strong>{label}</strong>
        </div>
      </TextWithAction>
    </span>
  );
};
