import React from "react";
import { IRatingResponse } from "../../../../models";
import { Rate } from "antd";
import { RevenueSummary } from "../../../RevenueSummary/RevenueSummary";

import "./CustomerSatisfaction.scss";

interface ICustomerSatisfactionProps {
  rating: IRatingResponse;
}

export const CustomerSatisfaction: React.FC<ICustomerSatisfactionProps> = ({
  rating,
}) => {
  return (
    <div className="customer-satisfaction">
      <div className="customer-satisfaction__icon">
        <i className="demo-icon icon-thumbs-up">&#xe823;</i>
      </div>

      <div className="customer-satisfaction__text">
        <div className="customer-satisfaction__title">
          Customer satisfaction
        </div>

        <div className="customer-satisfaction__summary">
          Based on {rating?.rating_count.toLocaleString()} reviews.
        </div>
      </div>

      <Rate
        className="customer-satisfaction__rate"
        allowHalf
        style={{ fontSize: "32px" }}
        value={rating?.average_rating}
        disabled
      />

      <RevenueSummary
        className="customer-satisfaction__rate-number"
        revenue={rating.average_rating}
        hidePastRevenue
      />
    </div>
  );
};
