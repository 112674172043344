import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  approvePendingCharge,
  getPendingCharges,
  rejectPendingCharge,
} from "../../api/billing";
import { API_ERROR } from "../../constants";
import { NotificationContext } from "../../context/NotificationContext";
import { UserContext } from "../../context/UserContext";
import { IPendingCharge } from "../../models";
import { BillingBlocker } from "./BillingBlocker/BillingBlocker";
import { BillingPopup } from "./BillingPopup/BillingPopup";
import { groupBy } from "lodash";
import { useLocation } from "react-router";
import { ERoutes } from "../../routes";
import { PermissionsContext } from "../../context/PermissionsContext";

import dollarSign from "../../images/dollar.png";

import "./FeatureBillingCheck.scss";

interface IFeatureBillingCheckProps {}

const RENDER_INDEX_LIMIT = 1;

export const FeatureBillingCheck: React.FC<IFeatureBillingCheckProps> = () => {
  const { isSuperadmin } = useContext(PermissionsContext);
  const [pendingCharges, setPendingCharges] = useState<IPendingCharge[]>([]);
  const [priorityCharges, setPriorityCharges] = useState<IPendingCharge[]>([]);
  const [isBillingPopupOpen, setIsBillingPopupOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { setErrorNotification } = useContext(NotificationContext);
  const { hasActivePayment, onboardingProgress } = useContext(UserContext);
  const location = useLocation();

  useEffect(() => {
    setIsLoading(true);

    getPendingCharges()
      .then((pendingCharges) => {
        const groupedChargesByPriority = groupBy(
          pendingCharges.details.results,
          (x) => (x.feature.is_payment_mandatory ? "mandatory" : "notMandatory")
        );

        setPriorityCharges(groupedChargesByPriority.mandatory);
        setPendingCharges(groupedChargesByPriority.notMandatory);
      })
      .catch((err) => {
        console.error(err);
        setErrorNotification(API_ERROR);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [setPendingCharges, setPriorityCharges, setErrorNotification]);

  useEffect(() => {
    setIsBillingPopupOpen(Boolean(pendingCharges || priorityCharges));
  }, [pendingCharges, setIsBillingPopupOpen, priorityCharges]);

  const handleBillingPopupClose = useCallback(
    (chargeId: number) => {
      const isPriorityChargeChange = priorityCharges?.some(
        (priorityCharge) => priorityCharge.charge.id === chargeId
      );

      setIsBillingPopupOpen(false);

      if (isPriorityChargeChange) {
        setPriorityCharges((priorityCharges) =>
          priorityCharges?.filter(
            (priorityCharge) => priorityCharge.charge.id !== chargeId
          )
        );

        return;
      }

      setPendingCharges((pendingCharges) =>
        pendingCharges?.filter(
          (pendingCharge) => pendingCharge.charge.id !== chargeId
        )
      );
    },
    [
      setIsBillingPopupOpen,
      priorityCharges,
      setPriorityCharges,
      setPendingCharges,
    ]
  );

  const handleChargeApprove = useCallback(
    (chargeId: number) => {
      setIsLoading(true);

      approvePendingCharge(chargeId)
        .then((chargeResponse) => {
          handleBillingPopupClose(chargeId);
          window.location.href = chargeResponse.details.confirmation_url;
        })
        .catch((err) => {
          console.error(err);
          setErrorNotification(API_ERROR);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [handleBillingPopupClose, setIsLoading, setErrorNotification]
  );

  const handleChargeReject = useCallback(
    (chargeId: number) => {
      setIsLoading(true);

      rejectPendingCharge(chargeId)
        .then(() => {
          handleBillingPopupClose(chargeId);
        })
        .catch((err) => {
          console.error(err);
          setErrorNotification(API_ERROR);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [handleBillingPopupClose, setIsLoading, setErrorNotification]
  );

  if (
    (location.pathname === ERoutes.HOMEPAGE &&
      !onboardingProgress?.is_complete) ||
    isLoading
  ) {
    return null;
  }
  return (
    <div className="feature-billing-check">
      {!priorityCharges?.length && !pendingCharges?.length && (
        <BillingBlocker
          isLoading={isLoading}
          isVisible={!isSuperadmin && !hasActivePayment}
          summary="Your subscription plan isn't active"
        />
      )}

      {!isSuperadmin &&
        priorityCharges
          ?.slice(0, RENDER_INDEX_LIMIT)
          .map((priorityCharge) => (
            <BillingPopup
              key={priorityCharge.charge.id}
              isVisible={isBillingPopupOpen}
              isLoading={isLoading}
              popupIcon={dollarSign}
              charge={priorityCharge.charge}
              feature={priorityCharge.feature}
              handleChargeApprove={handleChargeApprove}
              handleChargeReject={handleChargeReject}
            />
          ))}

      {!isSuperadmin &&
        !priorityCharges?.length &&
        pendingCharges
          ?.slice(0, RENDER_INDEX_LIMIT)
          .map((pendingCharge) => (
            <BillingPopup
              key={pendingCharge.charge.id}
              isVisible={isBillingPopupOpen}
              isLoading={isLoading}
              popupIcon={dollarSign}
              charge={pendingCharge.charge}
              feature={pendingCharge.feature}
              handleChargeApprove={handleChargeApprove}
              handleChargeReject={handleChargeReject}
            />
          ))}
    </div>
  );
};
