import React, { useContext } from "react";
import { NotificationContext } from "../context/NotificationContext";
import { Notification } from "./Notification/Notification";
import { ENotificationType } from "./Notification/types";

export const Notifications = () => {
  const {
    errorNotification,
    setErrorNotification,
    successNotification,
    setSuccessNotification,
    warningNotification,
    setWarningNotification,
  } = useContext(NotificationContext);
  return (
    <>
      {errorNotification && (
        <Notification
          type={ENotificationType.ERROR}
          text={errorNotification}
          onClose={() => setErrorNotification("")}
        />
      )}
      {successNotification && (
        <Notification
          type={ENotificationType.SUCCESS}
          text={successNotification}
          onClose={() => setSuccessNotification("")}
        />
      )}
      {warningNotification && (
        <Notification
          type={ENotificationType.WARNING}
          text={warningNotification}
          onClose={() => setWarningNotification("")}
        />
      )}
    </>
  );
};
