import React, { useState, useEffect, useCallback, useMemo } from "react";
import "./AssignTicket.scss";
import { Avatar } from "../Avatar/Avatar";
import { IOrderItem, IUser } from "../../models";
import { assign } from "../../api/returnManagement";
import { getGenericUserId } from "../../entities/GenericUserId";
import { getInitials } from "../../utils";
import Select from "antd/es/select";
import classNames from "classnames";

interface IAssignTicketProps {
  users: IUser[];
  order: IOrderItem;
  refetch: () => Promise<unknown>;
}

const UNASSIGN_OPTION_ID = -1;

export const AssignTicket: React.FC<IAssignTicketProps> = ({
  users,
  order,
  refetch,
}) => {
  const [assignUser, setAssignUser] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (order.assigned_to !== null) {
      setAssignUser(order.assigned_to);
    }
  }, [order]);

  const handleAssignTicket = useCallback(
    (assignUser) => {
      setAssignUser(assignUser);
      assign(
        assignUser !== UNASSIGN_OPTION_ID ? "1" : "0",
        order.order_id,
        assignUser !== UNASSIGN_OPTION_ID ? assignUser : undefined
      ).then(() => refetch());
    },
    [order, refetch]
  );

  const userOptions = useMemo(
    () =>
      [
        {
          value: UNASSIGN_OPTION_ID,
          label: "Unassign",
          avatar: null,
        },
        ...(users?.map((x) => {
          return {
            value: x.generic_user_id,
            label:
              x.generic_user_id === getGenericUserId()
                ? "Assign to me"
                : x.name,
            avatar:
              x.generic_user_id !== getGenericUserId() ? (
                <Avatar
                  src={order.picture}
                  size={24}
                  initials={getInitials(order.user_name)}
                />
              ) : null,
          };
        }) || []),
      ].filter((x) => x.label !== null),
    [users, order]
  );

  const getLabel = useCallback(
    (value: number, label: string) => {
      if (value === assignUser && value === getGenericUserId()) {
        return "Assigned to me";
      }
      if (value === assignUser && value !== UNASSIGN_OPTION_ID) {
        return `Assigned to ${label}`;
      }
      return label;
    },
    [assignUser]
  );

  return (
    <Select
      className={classNames("assign-ticket-select", {
        "assign-ticket-select__assigned":
          assignUser !== UNASSIGN_OPTION_ID && assignUser !== undefined,
        "assign-ticket-select__assigned-to-me":
          assignUser === getGenericUserId(),
      })}
      popupClassName="assign-ticket-users"
      value={assignUser}
      onChange={handleAssignTicket}
      optionLabelProp="avatar"
      showArrow={false}
    >
      {userOptions.map((x) => (
        <Select.Option value={x.value} avatar={x.avatar}>
          {getLabel(x.value, x.label)}
        </Select.Option>
      ))}
    </Select>
  );
};
